import { Edit, useRecordContext, useTranslate } from 'react-admin';
import ProductForm from './ProductForm';

const ProductTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.products.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const ProductEdit = () => (
  <Edit title={<ProductTitle />}>
    <ProductForm />
  </Edit>
);

export default ProductEdit;
