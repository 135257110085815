import { z } from 'zod';

export const NonBlankString = z
  .string()
  .nonempty({ message: 'schema.not_blank' })
  .refine(s => s.trim().length > 0, { message: 'schema.not_blank' });

export const NonBlankEmail = z
  .string()
  .nonempty({ message: 'schema.not_blank' })
  .email({ message: 'schema.email' })
  .refine(s => s.trim().length > 0, { message: 'schema.not_blank' });

export const NonNegative = z
    .number()
    .min(0, { message: 'schema.not_negative' });

export const NonEmptyArray = schema => z.array(schema).nonempty({ message: 'schema.not_empty' });

export const Image = z
  .instanceof(File, { message: 'schema.image' })
  .refine(f => f.type.includes('image'), { message: 'schema.image' })

export const RaImage = z.object({
  rawFile: Image.optional(),
  src: z.string().url({ message: 'schema.image' }),
}, { invalid_type_error: 'schema.image', required_error: 'schema.image' })