import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Field = styled(({ name, children, ...props }) => (
  <Grid container alignItems='center' {...props}>
    <Grid item xs={6}>
      <Typography variant='p'>{name}</Typography>
    </Grid>
    <Grid item xs={6}>
      {children}
    </Grid>
  </Grid>
))`
  &:not(:last-child) {
    border-bottom: 1px solid #e6ebf1;
  }
`;

export default Field;
