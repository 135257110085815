import { get, put } from './utils';

const provider = {

  updateTeacherApplicationStatus: (id, data) => put(`teacher_applications/${id}/status`, data),

  getTeacherApplicationStatuses: () => get(`teacher_applications/statuses`),

};

export default provider;
