const isFloat = (x) => Math.floor(x) !== x;

export const int = () => (value) =>
  isFloat(value) ? 'resources.validation.int' : undefined;

const countDecimals = (x) => String(x).split('.')[1]?.length ?? 0;

export const decimals = (max) => (value) =>
  countDecimals(value) > max
    ? { message: 'resources.validation.decimals', args: { max } }
    : undefined;
