/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useUser } from 'hooks';
import { useDataProvider } from 'react-admin';

const statusTypes = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CANCELLED: 'CANCELLED',
};

const activated = () => ({
  status: statusTypes.ACTIVE,
  cancelledBy: null,
  cancelledAt: null,
});

const cancelled = (admin) => ({
  status: statusTypes.CANCELLED,
  cancelledBy: admin,
  cancelledAt: new Date().toISOString(),
});

export const useStatus = (initial) => {
  const [status, setStatus] = useState();
  const admin = useUser();
  const dataProvider = useDataProvider();

  const withUser = (fn) =>
    dataProvider
      .getUserById(initial.cancelledBy)
      .then(({ data: { id, completeName } }) =>
        fn({ id, fullName: completeName })
      );

  useEffect(() => {
    if (initial?.cancelledBy) withUser((user) => setStatus(cancelled(user)));
    else setStatus(initial);
  }, [initial]);

  const switchStatus = () => {
    setStatus((prev) =>
      prev.status === statusTypes.CANCELLED ? activated() : cancelled(admin)
    );
  };

  return {
    status,
    setStatus,
    switchStatus,
  };
};
