const { useTranslation } = require('hooks');

const i18nPath = 'resources.platform_users.tabs.teacherData.video';

const Iframe = ({ videoId }) => (
  <iframe
    width='100%'
    src={`https://www.youtube.com/embed/${videoId}`}
    title={useTranslation(i18nPath, 'title')}
  ></iframe>
);

export default Iframe;
