import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetPlatformUserStatus = (id) => {
  const dataProvider = useDataProvider();

  const query = useQuery(['getPlatformUserStatus', id], () => {
    if (id)
      return dataProvider.getPlatformUserStatus(id).then(({ data }) => data);
  });

  return query;
};

export default useGetPlatformUserStatus;
