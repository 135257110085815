import { List, Datagrid, TextField } from 'react-admin';

const ResponseKindList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

export default ResponseKindList;
