import { ReferenceInput, AutocompleteArrayInput } from 'react-admin';

const asc = (field = 'id') => ({ field, order: 'ASC' });

const languageFilter = (
  <ReferenceInput
    source="id"
    reference="offered_languages"
    sort={asc()}
    alwaysOn
  >
    <AutocompleteArrayInput label="resources.offered_languages.filters.languages" />
  </ReferenceInput>
);

const complementaryFilter = (
  <ReferenceInput
    source="complementary"
    reference="languages"
    sort={asc()}
    alwaysOn
  >
    <AutocompleteArrayInput label="resources.offered_languages.filters.complementaries" />
  </ReferenceInput>
);

const filters = [languageFilter, complementaryFilter];

export default filters;
