import {
    Edit,
    email,
    required,
    SimpleForm,
    TextInput,
    useRecordContext,
    useTranslate
} from "react-admin";
import { Grid } from '@mui/material';

const UserTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate("resources.users.name", { smart_count: 1 });

  return `${label}: ${record.firstName}`;
};

export const UserEdit = () => (
  <Edit title={<UserTitle />}>
    <SimpleForm>
        <Grid container xs={8}>
            <Grid item xs={6} padding={1}>
                <TextInput source='email' type='email' validate={[required(), email()]} fullWidth/>
            </Grid>
            <Grid item xs={6} padding={1}>
                <TextInput source='firstName' validate={[required()]} fullWidth/>
            </Grid>
            <Grid item xs={6} padding={1}>
                <TextInput source='lastName' validate={[required()]} fullWidth/>
            </Grid>
        </Grid>
    </SimpleForm>
  </Edit>
);
