/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { required, SelectInput, useDataProvider, useRecordContext } from 'react-admin';
import { useForm } from 'react-hook-form';
import FormCard from '../FormCard';

const i18nPath = 'resources.platform_users.tabs.teacherApplications';

const PlatformUserTeacherApplicationsList = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const options = [
    { _id: 'ACTIVATED', label: useTranslation(i18nPath, 'status.activated') },
    { _id: 'ACTIVATION_PENDING', label: useTranslation(i18nPath, 'status.activation_pending') },
    { _id: 'APPROVED', label: useTranslation(i18nPath, 'status.approved') },
    { _id: 'IN_PROGRESS', label: useTranslation(i18nPath, 'status.in_progress') },
    { _id: 'INTERVIEW_CONFIRMED', label: useTranslation(i18nPath, 'status.interview_confirmed') },
    { _id: 'INTERVIEW_NO_CONFIRMED', label: useTranslation(i18nPath, 'status.interview_no_confirmed') },
    { _id: 'PENDING', label: useTranslation(i18nPath, 'status.pending') },
    { _id: 'PRE_APPROVED', label: useTranslation(i18nPath, 'status.pre_approved') },
    { _id: 'REJECTED', label: useTranslation(i18nPath, 'status.rejected') },
    { _id: 'VALIDATED', label: useTranslation(i18nPath, 'status.validated') },
  ];

  const [applications, setApplications] = useState([]);
  useEffect(() => {
    const teacherApplications = async () => {
      const { data } = await dataProvider.getTeacherApplications(record?.id);
      setApplications(data[data.length - 1]);
    };
    teacherApplications();
  }, []);

  const data = applications;

  const methods = useForm({
    defaultValues: {
      status: '',
    }
  })

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    const teacherApplicationBody =
    {
      "status": `${data?.status}`
    }
    await dataProvider.updateTeacherApplicationStatus(data?.id, teacherApplicationBody);
  };

  return (
    <FormCard
      title={useTranslation(i18nPath, 'title')}
      methods={methods}
      onSubmit={handleSubmit}
    >
      <SelectInput
        label={false}
        source='status'
        choices={options}
        optionText="label"
        optionValue="_id"
        validate={[required()]}
      />
    </FormCard>

  );
};

export default PlatformUserTeacherApplicationsList;
