import defaultMessages from 'ra-language-english';

const messages = {
  ...defaultMessages,
  menu: {
    parameters: {
      header: 'Parameters',
      class: 'Classes',
      account: 'Accounts',
      credit: 'Credits',
      cashing: 'Cashing',
      teacherCharacteristics: 'Teacher characteristics',
    },
    campaigns: 'Campaign management',
    products: 'Product management',
    accounts: 'Teacher and student account management',
    teacher_applications: 'Teacher application management',
    teacher_balance_summary: 'Teacher balance summary',
    languages: {
      header: 'Languages',
      offeredLanguagesAndComplementaries:
        'Offered languages and complementaries management',
      levels: 'Language levels',
    },
    accounting: {
      header: 'Accounting and finance',
      sectors: 'Groups and types of VAT',
      accounts: 'Accounting accounts',
      clientTypes: 'Client types',
    },
    incidents: {
      header: 'Incidents',
      incidentsAndCompensations: 'Incidents and compensations management',
      incidentTypes: 'Incident types',
      incidentResponseTypes: 'Response types',
    },
    usersAndRoles: {
      header: 'Users and roles',
      users: 'Users',
      roles: 'Roles',
    },
    movements: 'Movements',
  },
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  resources: {
    incident_types: {
      list: {
        name: 'Name'
      },
      name: 'Incident Type |||| Incident Types',
      redirect: [
        "If you don't find a response you can ",
        'first add a new response type ',
        'and then continue creating the incident type.',
      ],
      duplicated: 'There is already an incident type with this name',
    },
    parameters: {
      save: 'Save',
      units: {
        price: '$',
        days: 'days',
        percentage: '%',
        applicationDate: 'application date',
      },
    },
    class_parameters: {
      name: 'Class parameters',
    },
    account_parameters: {
      name: 'Account parameters',
    },
    credit_parameters: {
      name: 'Credit parameters',
    },
    cashing_parameters: {
      name: 'Cashing parameters',
    },
    teacher_applications: {
      name: 'Teacher application management',
      fields: {
        firstName: 'Name',
        lastName: 'Lastname',
        country: 'Country',
        id: 'Application ID',
        identityDocument: 'Identity document',
        introductoryVideoId: 'Presentation video',
        birthDate: 'Birth date',
        email: 'Email',
        phoneNumber: 'Phone number',
        teachingYears: 'Teaching years',
        pricePerHour: 'Price per hour',
        weeklyTimeHours: 'Weekly time hours',
        teacherTrainingDescription: 'Teacher training description',
        createdAt: "Application date",
        updatedAt: "Last modification",
        status: "Status",
        nativeLanguages: "Native language",
        spokenLanguages: "Spoken languages",
        downloadSpeed: "Download speed",
        ping: "Ping",
        presentation: "Presentation",
        accent: "Accent",
        teacherCharacteristics: "Teaching specialities",
        teacherPicture: "Profile picture",
        uploadSpeed: "Upload speed",
      },
      status: {
        activated: "Activated",
        activation_pending: "Pending activation",
        approved: "Approved",
        in_progress: "In progress",
        interview_confirmed: "Interview confirmed",
        interview_no_confirmed: "Interview not confirmed",
        pending: "Pending",
        pre_approved: "Preapproved, waiting for data",
        rejected: "Rejected",
        validated: "Validated",
      },
    },
    teacher_balance_summary: {
      name: 'Teacher balance summary',
      confirmMarkAsSentTitle: 'Mark as sent',
      confirmMarkAsSentContent: '',
      fields: {
        transactionCode: 'Transaction code',
        date: 'Date',
        downloaded: 'Downloaded',
        sent: 'Sent',
      },
      buttons: {
        download: 'Download',
        markAsSent: 'Mark as sent',
      },
    },
    platform_users: {
      fields: {
        completeName: 'Name',
        nativeLanguage: 'Native Language',
        studentPendingLessons: 'Student pending lessons',
        teacherPendingLessons: 'Teacher pending lessons',
        studentCount: 'Students',
        status: 'Status',
        type: 'Customer Type',
        email: 'Email',
      },
      name: 'Teacher/Student Account |||| Teacher and student accounts',
      tabs: {
        generalData: {
          title: 'General data',
          generalData: {
            title: 'General data',
            firstName: 'Name',
            lastName: 'Last name',
            gender: 'Gender',
            email: 'Email',
            country: 'Country',
            timezone: 'Timezone',
            location: 'Location',
            nativeLanguage: 'Native Language',
          },
          pendingLessons: {
            createdAt: 'Created at',
            date: 'Date',
            lessonsAsStudent: {
              title: 'Lessons as student',
            },
            lessonsAsTeacher: {
              title: 'Lessons as teacher',
            },
            reference: 'Reference',
            status: 'Status',
            student: 'Student',
            teacher: 'Teacher',
          },
        },
        teacherData: {
          title: 'Teacher data',
          status: {
            title: 'Status',
            active:'Active',
            cancelled: 'Cancelled',
            cancelledBy: 'Cancelled by',
          },
          lessons: {
            title: 'Lessons',
            minAdvanceNotice: 'Min. advance',
            allowTrialLessons: 'Allow trial lessons',
            languages: 'Languages',
          },
          rates: {
            title: 'Rates',
            newStudentRate: 'New student rate',
            studentRates: 'Student rates',
          },
          languages: {
            title: 'Languages',
            language: 'Language',
            level: 'Level',
            document: 'Document',
          },
          video: {
            title: 'Video',
            url: 'Video url',
          },
          availability: {
            title: 'Availability',
            week: 'Week',
          },
          presentation: {
            title: 'Presentation',
          },
          curriculum: {
            title: 'Curriculum',
          },
          characteristics: {
            title: 'Characteristics',
          },
          aboutLessons: {
            title: 'About lessons',
          },
        },
        teacherApplications: {
          title: 'Teacher applications',
          status: {
            activated: 'Activated',
            activation_pending: 'Pending activation',
            approved: 'Approved',
            in_progress: 'In progress',
            interview_confirmed: 'Interview confirmed',
            interview_no_confirmed: 'Interview not confirmed',
            pending: 'Pending',
            pre_approved: 'Preapproved, waiting for data',
            rejected: 'Rejected',
            validated: 'Validated',
          }
        },
      },
    },
    campaigns: {
      name: 'Campaign |||| Campaigns',
      list: {
        name: 'Name',
        couponCode: 'Coupon',
        discount: 'Discount',
        startDate: 'Start date (UTC)',
        endDate: 'End date (UTC)',
        maxCoupons: 'Max coupons',
        usedCoupons: 'Used coupons',
        oncePerUser: 'Limit to once per user',
        allowTrialLesson: 'Allow trial lesson',
        allowWithPreviousPurchase: 'Allow with previous purchases',
        useUserTimezone: 'Use user timezone'
      },
      fields: {
        couponCode: 'Coupon',
        discount: 'Discount (Percentage)',
        startDate: 'Start date (dd/mm/YYYY)',
        startTime: 'Start hour (hh:mm)',
        endDate: 'End date (dd/mm/YYYY)',
        endTime: 'End hour (hh:mm)',
        maxCoupons: 'Max coupons',
        usedCoupons: 'Used coupons',
        oncePerUser: 'Limit to once per user',
        allowTrialLesson: 'Allow trial lesson',
        allowWithPreviousPurchase: 'Allow with previous purchases',
        useUserTimezone: 'Use user timezone',
        showBannerPopup: 'Configure and show banner and popup'
      },
      tabs: {
        headerBanner: 'Header Banner',
        popup: 'Popup'
      },
      datesUTCMessage: 'Dates are in UTC timezone',
      duplicated: 'There is already a campaign with this coupon code',
      wrongFormat: 'Wrong format'
    },
    products: {
      name: 'Product |||| Products',
      list: {
        duration: 'Duration',
        name: 'Name',
        price: 'Depending on teacher price',
      },
      fields: {
        name: 'Name',
        lessons: 'Lessons',
        duration: 'Duration (minutes)',
        isFree: 'Free class',
        isTrial: 'Trial class',
        discount: 'Discount',
        isTeacherPrice: 'Teacher price',
        price: 'Price',
        commission: 'Student commission',
        credits: 'Credits',
      },
    },
    language_levels: {
      name: 'Language level |||| Language levels',
      duplicated:
        'There is already a language level with this language and level',
      fields: {
        language: 'Language',
        level: 'Level',
        description: 'Description',
      },
    },
    offered_languages: {
      name: 'Offered languages management',
      fields: {
        description: 'Description',
        activeTeachers: 'Active teachers total',
        activeStudents: 'Active students total',
      },
      buttons: {
        addNewLanguage: 'Add new language',
        addComplementary: 'Add complementary language',
      },
      form: {
        language: 'Language',
        code: 'Code',
        shortDescription: 'Short description',
        longDescription: 'Long description',
        min: 'Minimum number of teachers required',
        max: 'Maximum number of teachers required',
      },
      filters: {
        languages: 'Languages',
        complementaries: 'Complementaries',
      },
      duplcated: 'There is already an offered language with this code',
    },
    language_exams: {
      name: 'Language exams management',
      fields: {
        name: 'Name',
        description: 'Description',
        institution: 'Institution',
        abbreviation: 'Abbreviation',
        tag: 'Tag',
        landing: 'Landing',
      },
      form: {
        name: 'Name',
        description: 'Description',
        institution: 'Institution',
        abbreviation: 'Abbreviation',
        tag: 'Tag',
        landing: 'Landing',
      },
    },
    accounting_accounts: {
      name: 'Accounting account |||| Accounting accounts',
      fields: {
        number: 'Number',
        name: 'Name',
      },
      duplicated: 'There is already an account with this number',
    },
    client_types: {
      name: 'Client type |||| Client types',
      fields: {
        number: 'Nº',
        name: 'Name',
        sectors: 'Groups',
      },
      duplicated: 'There is already a client type with this name',
    },
    complementaries: {
      name: 'Complementary languages',
      teacherCount: 'Teachers',
      minTeachers: 'Min. needed',
      maxTeachers: 'Max. needed',
      locked: 'Locked',
      confirmTitle: 'Remove complementary language',
    },
    incidents: {
      name: 'Incident |||| Incident management',
      fields: {
        classDate: 'Class date',
        classTime: 'Class time',
        date: 'Incident date',
        givenSolution: 'Solution',
        incident: 'Incident',
        lesson: 'Class',
        reason: 'Reason',
        reference: 'Class #',
        reporter: 'Reporter',
        sentText: 'User comment',
        status: {
          name: 'Status',
          resolved: 'Resolved',
          unresolved: 'Not resolved',
        },
        student: 'Student',
        teacher: 'Teacher',
        time: 'Time',
        unresolved: 'The incident has not been resolved yet',
      },
      form: {
        heading: 'Resolve incident',
        text: 'Text',
      },
      buttons: {
        resolve: 'Resolve',
        cancel: 'Cancel',
      },
    },
    movements: {
      name: 'Movement |||| Movements',
      fields: {
        createdAt: 'Created At',
        type: 'Type',
        actor: 'Actor',
        amountCollectedByTeacher: 'Ammount collected by Teacher',
        lesson: 'Lesson',
        lessonAmount: 'Lesson Amount',
        lessonDateTime: 'Date/hour lesson',
        lessonPrice: 'Lesson price',
        lessonStudentJoinedAt: 'Student joined at',
        lessonTeacherJoinedAt: 'Teacher joined at',
        productName: 'Product',
        purchaseCommissionApplied: 'Service fee paid by student',
        purchasePrice: 'Amount paid by student',
        purchaseCampaignDiscount: 'Special discount',
        student: 'Student',
        teacher: 'Teacher',
        stripe: 'Stripe',
        teacherServiceFee: 'Teacher service fee',
        generatedCredits: 'Generated credits',
        usedCredits: 'Used credits',
        availableCredits: 'Available credits',
        withdrawnBalance: 'Withdrawn balance',
        totalBalance: 'Total balance',
        retainedBalance: 'Retained balance',
        studentAdjustment: 'Student Adjustment',
        teacherAdjustment: 'Teacher Adjustment'
      },
      form: {
        adjustment: {
          heading: 'Adjust session',
          amountCollectedByTeacher: 'Ammount collected by Teacher',
          purchasePrice: 'Amount paid by student',
          amountToAdjustToStudent: 'Amount to adjust to student',
          amountToAdjustToTeacher: 'Amount to adjust to teacher'
        },
        refund: {
          heading: 'Refund session',
          amountToRefundToStudent: 'Amount to refund to student',
          amountToSubtractToTeacher: 'Amount to subtract to teacher'
        }
      },
      buttons: {
        adjustment: 'Adjustment',
        cancel: 'Cancel',
        refund: 'Refund'
      },
    },
    compensations: {
      name: 'Compensation |||| Compensations',
      fields: {
        type: {
          name: 'Type',
          compensation: 'Compensation',
          penalization: 'Penalization',
        },
        user: 'User',
        role: 'Role',
        quantity: 'Quantity',
        date: 'Date',
        text: 'Text',
      },
    },
    incident_response_types: {
      name: 'Response Type |||| Response Types',
      duplicated: 'There is already a response type with this name',
    },
    roles: {
      name: 'Role |||| Roles',
      table: {
        available: 'Available permissions',
        show: 'Show',
        edit: 'Edit',
        hide: 'Hide',
      },
      duplicated: 'There is already a role with this name',
    },
    sectors: {
      fields: {
        commentary: 'Text',
        countries: 'Countries',
        name: 'Group name',
        vat: 'Type',
      },
      name: 'Group |||| Groups and types of VAT',
    },
    teacher_characteristics: {
      fields: {
        name: 'Name',
      },
      name: 'Teacher characteristic |||| Teacher characteristics',
    },
    users: {
      name: 'User |||| Users',
    },
    validation: {
      int: 'Must be an integer',
      decimals: 'Cannot contain more than %{max} decimals',
    },
  },

  schema: {
    not_empty: 'Cannot be empty',
    not_blank: 'Cannot be blank',
    email: 'Invalid email',
    not_negative: 'Must be a non-negative number',
    url: 'Invalid URL',
    image: 'Invalid image',
  }
};

export default messages;
