/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import GeneralData from './generalData/GeneralData';
import PlatformUserTeacherApplicationsList from './teacherApplication/PlatformUserTeacherApplicationsList';
import TeacherData from './teacherData/TeacherData';

// const PlatformUserFormToolbar = () => {
//   return (
//     <Toolbar>
//       <SaveButton />
//     </Toolbar>
//   );
// };

const i18nPath = 'resources.platform_users.tabs';

const TabPanel = ({ children, value, index, ...props }) => (
  <div hidden={value !== index} {...props}>
    {children}
  </div>
);

const PlatformUserForm = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [tab, setTab] = useState(0);
  const [applications, setApplications] = useState(undefined);
  useEffect(() => {
    const teacherApplications = async () => {
      const { data } = await dataProvider.getTeacherApplications(record?.id);
      setApplications(data[data.length - 1]);
    };
    teacherApplications();
  }, []);
  const handleTabChange = (_, newTab) => setTab(newTab);

  const teacherApplicationsTitle = useTranslation(i18nPath, 'teacherApplications.title');
  const generalDataTitle = useTranslation(i18nPath, 'generalData.title');
  const teacherDataTitle = useTranslation(i18nPath, 'teacherData.title');

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label={generalDataTitle} />
        {applications !== undefined && (
          <Tab label={teacherApplicationsTitle} />
        )}
        {record?.teacherId && (
          <Tab label={teacherDataTitle} />
        )}
      </Tabs>
      <TabPanel value={tab} index={0}>
        <GeneralData />
      </TabPanel>
      {applications !== undefined && (
        <TabPanel value={tab} index={1}>
          <PlatformUserTeacherApplicationsList />
        </TabPanel>
      )}
      {record?.teacherId && (
        <TabPanel value={tab} index={(applications !== undefined) ? 2 : 1}>
          <TeacherData />
        </TabPanel>
      )}
    </>
  );
};

export default PlatformUserForm;
