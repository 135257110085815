import { useTranslate } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import { Grid, FormControlLabel } from '@mui/material';
import StyledCheckbox from './StyledCheckbox';

const CampaignConditions = () => {
  const translate = useTranslate();

  const { control } = useFormContext();

  // Limit to once per user
  const {
    field: { value: oncePerUser, onChange: onOncePerUserChange },
  } = useController({ name: 'oncePerUser', control });

  const setOncePerUser = () => {
    onOncePerUserChange(!oncePerUser);
  };
  const initializeOncePerUser = () => {
    onOncePerUserChange(false);
  };

  // Allow trial lesson
  const {
    field: { value: allowTrialLesson, onChange: onAllowTrialLessonChange },
  } = useController({ name: 'allowTrialLesson', control });

  const setAllowTrialLesson = () => {
    onAllowTrialLessonChange(!allowTrialLesson);
  };
  const initializeAllowTrialLesson = () => {
    onAllowTrialLessonChange(false);
  };

  // Allow with previous purchase
  const {
    field: { value: allowWithPreviousPurchase, onChange: onAllowWithPreviousPurchaseChange },
  } = useController({ name: 'allowWithPreviousPurchase', control });

  const setAllowWithPreviousPurchase = () => {
    onAllowWithPreviousPurchaseChange(!allowWithPreviousPurchase);
  };
  const initializeAllowWithPreviousPurchase = () => {
    onAllowWithPreviousPurchaseChange(false);
  };

  // Initialize variables
  if (oncePerUser === undefined) {
    initializeOncePerUser();
  }

  if (allowTrialLesson === undefined) {
    initializeAllowTrialLesson();
  }

  if (allowWithPreviousPurchase === undefined) {
    initializeAllowWithPreviousPurchase();
  }

  return (
    <>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <StyledCheckbox checked={oncePerUser} onChange={setOncePerUser} />
          }
          label={translate('resources.campaigns.fields.oncePerUser')}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <StyledCheckbox checked={allowTrialLesson} onChange={setAllowTrialLesson} />
          }
          label={translate('resources.campaigns.fields.allowTrialLesson')}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <StyledCheckbox checked={allowWithPreviousPurchase} onChange={setAllowWithPreviousPurchase} />
          }
          label={translate('resources.campaigns.fields.allowWithPreviousPurchase')}
        />
      </Grid>
    </>
  );
};

export default CampaignConditions;
