import { makeControlledCheckbox } from '../../../components/checkbox';
import { EDIT, HIDE, SHOW } from '../constants';

export const ShowCheckbox = makeControlledCheckbox(({ value, onChange }) => ({
  checked: value === SHOW || value === EDIT,
  onChange: () => onChange(SHOW),
  disabled: value === EDIT,
}));

export const EditCheckbox = makeControlledCheckbox(({ value, onChange }) => ({
  checked: value === EDIT,
  onChange: () => onChange(EDIT),
}));

export const HideCheckbox = makeControlledCheckbox(({ value, onChange }) => ({
  checked: value === HIDE,
  onChange: () => onChange(HIDE),
}));
