import {Create, email, PasswordInput, required, SimpleForm, TextInput} from "react-admin";
import { Grid } from '@mui/material';

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <Grid container xs={8}>
                <Grid item xs={6} padding={1}>
                    <TextInput source='email' type='email' validate={[required(), email()]} fullWidth/>
                </Grid>
                <Grid item xs={6} padding={1}>
                    <TextInput source='firstName' validate={required()} fullWidth/>
                </Grid>
                <Grid item xs={6} padding={1}>
                    <TextInput source='lastName' validate={required()} fullWidth/>
                </Grid>
                <Grid item xs={6} padding={1}>
                    <PasswordInput source='password' validate={required()} fullWidth/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
