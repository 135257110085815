export const partition = (list, predicate) => {
  const [left, right] = [[], []];

  for (const item of list) {
    if (predicate(item)) left.push(item);
    else right.push(item);
  }

  return [left, right];
};

export const equal = (a, b) => {
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};
