import { useTranslate } from 'react-admin';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import GridItem from './GridItem';

const Hr = styled((props) => <hr {...props} />)`
  max-width: 700px;
  margin: 10px 0;
`;

const Header = () => {
  const translate = useTranslate();

  return (
    <>
      <Grid container spacing={2} sx={{ maxWidth: '700px' }}>
        <Grid item xs={6}>
          {translate('resources.roles.table.available')}
        </Grid>
        <GridItem xs={2}>{translate('resources.roles.table.show')}</GridItem>
        <GridItem xs={2}>{translate('resources.roles.table.edit')}</GridItem>
        <GridItem xs={2}>{translate('resources.roles.table.hide')}</GridItem>
      </Grid>
      <Hr />
    </>
  );
};

export default Header;
