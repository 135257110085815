import { Button, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslate } from 'react-admin';

const CustomToolbar = styled(Toolbar)`
  justify-content: end;
`;

const FormToolbar = () => {
  const translate = useTranslate();
  return (
    <CustomToolbar>
      <Button variant="contained" disableElevation type="submit">
        {translate('resources.parameters.save')}
      </Button>
    </CustomToolbar>
  );
};

export default FormToolbar;
