import { Switch } from '@mui/material';
import { useLoading, useTranslation } from 'hooks';
import useGetTeacherStatus from 'hooks/api/teacherStatus/useGetTeacherStatus';
import useUpdateTeacherStatus from 'hooks/api/teacherStatus/useUpdateTeacherStatus';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import Card from '../Card';
import Field from '../Field';
import SaveButton from '../SaveButton';

const i18nPath = 'resources.platform_users.tabs.teacherData.status';

function TeacherStatus() {
  const record = useRecordContext();

  const [loading, withLoad] = useLoading();
  const query = useGetTeacherStatus(record?.teacherId);

  const [status, setStatus] = useState(query?.data?.status);

  if (status === undefined && query?.data?.status !== undefined) {
    setStatus(query?.data?.status);
  }
  
  const updateTeacherStatus = useUpdateTeacherStatus();

  const switchStatus = (e) => {
    setStatus(!status);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    withLoad(async () => {
      updateTeacherStatus(record?.teacherId, status)
    });
  };

  return (
    <form
      title={useTranslation(i18nPath, 'title')}
      onSubmit={handleSubmit}
    >
      <Card
        title={useTranslation(i18nPath, 'title')}
        icon={<SaveButton loading={loading} />}
      >
        <Field name={`${useTranslation(i18nPath, 'active')} `}>
          <Switch checked={status} onClick={switchStatus} />
        </Field>
      </Card>
    </form>
  )
}

export default TeacherStatus
