import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const GridItem = styled((props) => (
  <Grid item {...props}>
    {props.children}
  </Grid>
))`
  text-align: center;
`;

export default GridItem;
