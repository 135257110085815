import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { Stack } from '@mui/material';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';

const ClientTypeCreate = () => {
  const notify = useDuplicateNotify();

  return (
    <Create mutationOptions={{ onError: notify }}>
      <SimpleForm>
        <Stack direction='row' spacing={2} alignItems='center'>
          <TextInput source='name' validate={[required()]} />
          <ReferenceArrayInput
            source='sectors'
            reference='sectors'
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectArrayInput optionText='name' />
          </ReferenceArrayInput>
        </Stack>
      </SimpleForm>
    </Create>
  );
};

export default ClientTypeCreate;
