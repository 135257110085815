import GeneralDataForm from './GeneralDataForm';
import Status from './Status';
import PendingLessons from './pendingLessons/';

const GeneralData = () => (
  <>
    <GeneralDataForm />
    <Status />
    <PendingLessons.Student />
    <PendingLessons.Teacher />
  </>
);

// // Prevent update when active tab changes
// const propsAreEqual = (prevProps, nextProps) => true;

export default GeneralData;
