import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const CenteredBox = styled(Box)`
  min-width: 500px;
  padding: 30px 70px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default CenteredBox;
