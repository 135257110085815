import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'hooks';

const SaveButton = ({ loading }) => (
  <LoadingButton loading={loading} variant='contained' type='submit'>
    {useTranslation('ra.action', 'save')}
  </LoadingButton>
);

export default SaveButton;
