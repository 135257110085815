/* eslint-disable react-hooks/exhaustive-deps */
import {
  required,
  useDataProvider,
  useRecordContext,
  useGetList,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormCard from '../FormCard';
import { useGetTeacherSpokenLanguages, useTranslation } from 'hooks';

import useGetLanguageLevels from 'hooks/api/useGetLanguageLevels';

const i18nPath = 'resources.platform_users.tabs.teacherData.languages';

const Languages = () => {

  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherSpokenLanguages(record?.teacherId);

  const methods = useForm({
    defaultValues: {
      spokenLanguages: [],
    },
  });

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updateTeacherLanguages(record.teacherId, data);
  };

  return (
    <FormCard
      methods={methods}
      title={useTranslation(i18nPath, 'title')}
      onSubmit={handleSubmit}
    >
      <TeacherLanguages />
    </FormCard>
  );
};

const TeacherLanguageItem = ({
  scopedFormData,
  getSource,
  ...rest
}) => {
  const [selectedLanguageHash, setSelectedLanguageHash] = useState('');
  const { data: choices } = useGetList(`languages`);
  const { data: choicesLvl } = useGetLanguageLevels();

  const onSelectLanguage = (languageHash) => {
    setSelectedLanguageHash(languageHash);
  };

  useEffect(() => {
    if (scopedFormData?.language && scopedFormData?.language !== selectedLanguageHash) {
      setSelectedLanguageHash(scopedFormData.language);
    }
  }, [selectedLanguageHash]);

  return (
    <>
      <SelectInput
        {...rest}
        source={getSource('language')}
        label={`${i18nPath}.language`}
        choices={choices ?? []}
        optionValue='id'
        validate={[required()]}
        onChange={(e) => onSelectLanguage(e.target.value)}
      />
      <SelectInput
        source={getSource('languageLevel')}
        label={`${i18nPath}.level`}
        choices={choicesLvl ?? []}
        optionValue='id'
        validate={[required()]}
      />
    </>
  );
};

const TeacherLanguages = () => {
  return (
    <ArrayInput label={false} source='spokenLanguages'>
      <SimpleFormIterator>
        <FormDataConsumer>
          {(item) => <TeacherLanguageItem {...item} />}
        </FormDataConsumer>
      </SimpleFormIterator >
    </ArrayInput >
  )
}

export default Languages;
