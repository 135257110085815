import { useState } from "react";
import { Button, useLogin, useNotify, useTranslate } from "react-admin";

export const CustomLoginPage = (props) => {
  const translate = useTranslate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    // will call authProvider.login({ email, password })
    login({ email, password }).catch(() => notify("Invalid email or password"));
  };

  return (
    <div className="RaLogin-root" id="page-login">
      <div className="RaLogin-card card">
       <div className="content">
         <img src="/assets/images/provisional-logo.svg" alt="" className="logo"/>
         <form onSubmit={handleSubmit}>
           <input
               name='email'
               type='email'
               value={email}
               onChange={(e) => setEmail(e.target.value)}
           />
           <input
               name='password'
               type='password'
               value={password}
               onChange={(e) => setPassword(e.target.value)}
           />
           <Button type="submit" className="button button-primary">
            {translate('ra.auth.sign_in')}
           </Button>
         </form>
       </div>
      </div>
    </div>
  );
};
