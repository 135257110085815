import { FunctionField, useTranslate } from 'react-admin';
import Detail from './Detail';

const SentText = () => {
  const translate = useTranslate();
  return (
    <Detail
      heading={translate('resources.incidents.fields.sentText')}
      rows={[
        <FunctionField
          label="resources.incidents.fields.sentText"
          render={({ sentText }) =>
            sentText ?? translate('resources.incidents.fields.unresolved')
          }
        />,
      ]}
    />
  );
};

export default SentText;
