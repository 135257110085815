import { useTranslate } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import { Grid, FormControlLabel } from '@mui/material';
import StyledCheckbox from './StyledCheckbox';

const LessonType = () => {
  const translate = useTranslate();
  const { control } = useFormContext();
  const {
    field: { value: isFree, onChange: onIsFreeChange },
  } = useController({ name: 'isFree', control });
  const {
    field: { value: isTrial, onChange: onIsTrialChange },
  } = useController({ name: 'isTrial', control });

  const setIsTrial = () => {
    onIsFreeChange(false);
    onIsTrialChange(true);
  };

  const setIsFree = () => {
    onIsFreeChange(true);
    onIsTrialChange(false);
  };

  return (
    <Grid container item spacing={2} xs={6}>
      <Grid item>
        <FormControlLabel
          control={
            <StyledCheckbox checked={isFree && !isTrial} onChange={setIsFree} />
          }
          label={translate('resources.products.fields.isFree')}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={isTrial && !isFree}
              onChange={setIsTrial}
            />
          }
          label={translate('resources.products.fields.isTrial')}
        />
      </Grid>
    </Grid>
  );
};

export default LessonType;
