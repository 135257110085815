import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';

const useUpdateTeacherApplicationStatus = () => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { mutate } = useMutation(({ id, status }) =>
    dataProvider.updateTeacherApplicationStatus(id, { status: status }), {
      onSuccess: (data) => {
        refresh();
        notify('ra.notification.updated', {
          undoable: false,
          messageArgs: { smart_count: 1 },
        });
      },
      onError: (error) => {
        notify('ra.notification.http_error', 'warning');
      },
    }
  );

  const updateStatus = (id, status) => {
    mutate({ id, status });
  };

  return updateStatus;
}

export default useUpdateTeacherApplicationStatus;


