import { useTranslate, required, minValue } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import { Grid, FormControlLabel } from '@mui/material';
import StyledCheckbox from './StyledCheckbox';
import StyledNumberInput from './StyledNumberInput';
import { decimals } from '../../../utils/validators';

const ProductPrice = () => {
  const translate = useTranslate();
  const { control } = useFormContext();
  const {
    field: { value: isTeacherPrice, onChange: onIsTeacherPriceChange },
  } = useController({ name: 'isTeacherPrice', control });

  return (
    <>
      <Grid item xs={6}>
        <FormControlLabel
          control={<StyledCheckbox source="isTeacherPrice" />}
          checked={isTeacherPrice}
          onChange={onIsTeacherPriceChange}
          label={translate('resources.products.fields.isTeacherPrice')}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledNumberInput
          source="price"
          disabled={isTeacherPrice}
          defaultValue={0}
          validate={[required(), minValue(0), decimals(2)]}
        />
      </Grid>
    </>
  );
};

export default ProductPrice;
