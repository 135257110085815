import {
  Edit,
  useRecordContext,
  useTranslate,
} from "react-admin";
import PlatformUserForm from "./form/PlatformUserForm";

const PlatformUserTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) {
    return null;
  }
  const label = translate('resources.platform_users.name', { smart_count: 1 });

  return `${label}: ${record.completeName}`;
};

const PlatformUserEdit = () => {
  return (
    <Edit title={<PlatformUserTitle />}>
      <PlatformUserForm />
    </Edit>
  )
};

export default PlatformUserEdit;
