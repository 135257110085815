import { Create, required, SimpleForm, TextInput } from "react-admin";

const TeacherCharacteristicCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source='name' validate={required()} />
    </SimpleForm>
  </Create>
);

export default TeacherCharacteristicCreate;
