import { ReferenceInput, SelectInput, DateInput } from 'react-admin';

const asc = (field = 'id') => ({ field, order: 'ASC' });

export const numberFilter = (
  <ReferenceInput source="hash" reference="compensations" sort={asc()} alwaysOn>
    <SelectInput label="Nº" source="number" optionText="number" />
  </ReferenceInput>
);

export const typeFilter = (
  <SelectInput
    label="resources.compensations.fields.type.name"
    source="penalization"
    choices={[
      { id: true, name: 'resources.compensations.fields.type.penalization' },
      { id: false, name: 'resources.compensations.fields.type.compensation' },
    ]}
    alwaysOn
  />
);

export const dateFilter = <DateInput source="date" alwaysOn />;

const filters = [numberFilter, typeFilter, dateFilter];

export default filters;
