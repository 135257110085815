import { get, post } from './utils';

const provider = {
  getOfferedLanguageComplementaries: (lang) =>
    get(`offered_language_complementaries/${lang}`),

  postLanguage: (lang) => post(`offered_languages`, lang),

  getComplementary: (complementary) =>
    get(`offered_language_complementaries/${complementary}`),
};

export default provider;
