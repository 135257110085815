import { Edit, useRecordContext, useTranslate } from 'react-admin';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';
import IncidentTypeForm from './IncidentTypeForm';

const IncidentTypeTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.incident_types.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const IncidentTypeEdit = () => {
  const notify = useDuplicateNotify();

  return (
    <Edit title={<IncidentTypeTitle />} mutationOptions={{ onError: notify }}>
      <IncidentTypeForm />
    </Edit>
  );
};

export default IncidentTypeEdit;
