import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherApplicationStatuses = () => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherApplicationStatuses'], () =>
    dataProvider.getTeacherApplicationStatuses().then(({ data }) => data)
  );

  return query;
};

export default useGetTeacherApplicationStatuses;