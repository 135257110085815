import { Modal, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ResolveAdjustmentForm from './ResolveAdjustmentForm';

const ResolveAdjustmentModal = styled((props) => (
  <Modal {...props}>
    <Box>
      <ResolveAdjustmentForm closeModal={props.onClose} />
    </Box>
  </Modal>
))`
  .MuiBox-root {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    padding: 20px 80px;
  }
`;

export default ResolveAdjustmentModal;
