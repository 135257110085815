import { TextField, useTranslate } from 'react-admin';
import Detail from './Detail';

const Reporter = () => {
  const translate = useTranslate();
  return (
    <Detail
      heading={translate('resources.incidents.fields.reporter')}
      rows={[<TextField source="reporter" />]}
    />
  );
};

export default Reporter;