/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  ReferenceArrayInput,
  SelectArrayInput,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import FormCard from '../FormCard';
import { useGetTeacherCharacteristics, useTranslation } from 'hooks';

const i18nPath = 'resources.platform_users.tabs.teacherData.characteristics';

const Characteristics = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherCharacteristics(record?.teacherId);

  const methods = useForm({
    defaultValues: {
      characteristics: [],
    },
  });

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updateTeacherCharacteristics(record.teacherId, data);
  };

  return (
    <FormCard
      title={useTranslation(i18nPath, 'title')}
      methods={methods}
      onSubmit={handleSubmit}
    >
      <ReferenceArrayInput
        source='characteristics'
        reference='teacher_characteristics'
      >
        <SelectArrayInput label={false} optionText='name' fullWidth />
      </ReferenceArrayInput>
    </FormCard>
  );
};

export default Characteristics;
