import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherLanguages = (teacherId) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherLanguages', teacherId], () => {
    if (teacherId)
      return dataProvider
        .getTeacherLanguages(teacherId)
        .then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherLanguages;
