export const between = ({ start, end }, value) => start <= value && value < end;

export const consecutiveTo = ({ start, end }, value) =>
  start === value + 1 || value === end;

export const remove = ({ start, end }, value) => {
  const helper = () => {
    if (start === value) return [{ start: value + 1, end }];
    if (end === value + 1) return [{ start, end: value }];

    return [
      { start, end: value },
      { start: value + 1, end },
    ];
  };

  return helper().filter(({ start, end }) => start !== end);
};

export const extend = ({ start, end }, value) => {
  if (start > value) return { start: value, end };
  if (end < value) return { start, end: value };
  return { start, end };
};

export const link = ({ start, end }, { start: start2, end: end2 }) => ({
  start: Math.min(start, start2),
  end: Math.max(end, end2),
});
