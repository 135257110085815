import { IconButton } from '@mui/material';
import { CloseSharp, DoneSharp } from '@mui/icons-material';

const Icons = ({ onClose, onDone }) => (
  <div>
    <IconButton onClick={onClose}>
      <CloseSharp />
    </IconButton>
    <IconButton onClick={onDone}>
      <DoneSharp />
    </IconButton>
  </div>
);

export default Icons;
