import { Typography, FormGroup, FormControlLabel } from '@mui/material';
import { useTranslate } from 'react-admin';
import { CompensationCheckbox, PenalizationCheckbox } from './checkbox';

const Type = ({ control }) => {
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h6">
        {translate('resources.compensations.fields.type.name')}
      </Typography>
      <FormGroup row required>
        <FormControlLabel
          control={<CompensationCheckbox name="type" control={control} />}
          label={translate('resources.compensations.fields.type.compensation')}
        />
        <FormControlLabel
          control={<PenalizationCheckbox name="type" control={control} />}
          label={translate('resources.compensations.fields.type.penalization')}
        />
      </FormGroup>
    </>
  );
};

export default Type;
