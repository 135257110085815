import { Grid } from '@mui/material';
import {
  useGetTeacherApplicationStatuses,
  useUpdateTeacherApplicationStatus,
} from 'hooks';
import {
  Form,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  SaveButton,
  SelectInput,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  UrlField,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import { formatAndLocalizeDateAndTime, formatAndLocalizeDateTime } from 'utils/format';

const i18nPath = 'resources.teacher_applications.fields';

const TeacherApplicationShow = () => {

  const ShowTitle = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    if (!record) return null;
    const label = translate('resources.teacher_applications.name');
    return <span>{`${label} - ${record.firstName} ${record.lastName}`}</span>;
  }

  const ShowActions = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    const updateStatus = useUpdateTeacherApplicationStatus();
    const { data: statuses } = useGetTeacherApplicationStatuses();


    const methods = useForm({
      defaultValues: {
        status: record?.status,
      },
    });

    const buildStatusChoices = () => {
      return statuses?.map(status => {
        return {
          id: status.id,
          name: translate(`resources.teacher_applications.status.${status.id.toLowerCase()}`)
        }
      });
    }

    const handleSubmit = (data) => {
      const { status } = data;
      updateStatus(record?.id, status);
    }

    const choices = buildStatusChoices();

    return (
      <TopToolbar>
        <Form onSubmit={handleSubmit} methods={methods}>
          <Grid container>
            <Grid item xs={12}>
              {(!choices)
                ? <></>
                : <SelectInput
                  source="status"
                  choices={choices || []}
                  validate={required()}
                />
              }
              <SaveButton />
            </Grid>
          </Grid>
        </Form>
      </TopToolbar>
    );
  }

  return (
    <Show title={<ShowTitle />} actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <FunctionField
          label={`${i18nPath}.createdAt`}
          source="createdAt"
          render={({ createdAt }) => formatAndLocalizeDateTime(createdAt)}
        />
        <FunctionField
          label={`${i18nPath}.updatedAt`}
          source="updatedAt"
          render={({ updatedAt }) => formatAndLocalizeDateTime(updatedAt)}
        />

        <ReferenceField source='nativeLanguageId' reference='languages'>
          <TextField source='name' />
        </ReferenceField>

        <ReferenceField source='accentId' reference='accents' link={false} >
          <TextField
            label={`${i18nPath}.accent`}
            source="name"
          />
        </ReferenceField>

        <ReferenceArrayField source='teacherCharacteristicIds' reference='teacher_characteristics'>
          <SingleFieldList linkType={false}>
            <TextField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>

        <TextField source="firstName" />
        <TextField source="lastName" />

        <ReferenceField source='countryId' reference='countries' link={false}>
          <TextField source='name' />
        </ReferenceField>

        <TextField source="identityDocument" />
        <FunctionField
          source="birthDate"
          render={({ birthDate }) => formatAndLocalizeDateAndTime(birthDate).date}
        />
        <TextField source="email" />
        <TextField source="phoneNumber" />

        <TextField source="teacherTrainingDescription" />
        <TextField source="teachingYears" />
        <TextField source="pricePerHour" />
        <TextField source="weeklyTimeHours" />
        <UrlField source="introductoryVideoId" target={'_blank'} />

        <ImageField source="teacherPicture.src" />
        <TextField source="presentation" />

        {/*
        <TextField source="ping" />
        <TextField source="downloadSpeed" />
        <TextField source="uploadSpeed" />
        */}
      </SimpleShowLayout>
    </Show>
  )
};

export default TeacherApplicationShow;
