import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Heading from './Heading';

const Detail = styled(({ heading, rows, ...props }) => (
  <Grid container item xs={props.xs ?? 12} {...props}>
    <Heading>{heading}</Heading>
    {rows}
  </Grid>
))``;

export default Detail;
