import { makeControlledCheckbox } from '../../components/checkbox';
import { COMPENSATION, PENALIZATION } from '../constants';

export const CompensationCheckbox = makeControlledCheckbox(
  ({ value, onChange }) => ({
    checked: value === COMPENSATION,
    onChange: () => onChange(COMPENSATION),
  })
);

export const PenalizationCheckbox = makeControlledCheckbox(
  ({ value, onChange }) => ({
    checked: value === PENALIZATION,
    onChange: () => onChange(PENALIZATION),
  })
);
