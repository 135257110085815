import { useState, useEffect } from 'react';
import { useGetTeacherVideo, useTranslation } from 'hooks';
import { useDataProvider, useRecordContext } from 'react-admin';
import Card from '../Card';
import Icons from './video/Icons';
import Iframe from './video/Iframe';
import UrlInput from './video/UrlInput';

const i18nPath = 'resources.platform_users.tabs.teacherData.video';

export const extractVideoId = (url) => {
  const regex = new RegExp(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/);
  if (regex.test(url)) {
    const groups = regex.exec(url);
    if (groups !== undefined && typeof groups[6] === 'string' && groups[6].length > 0) {
      return groups[6];
    }
  }
};

const Video = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherVideo(record?.teacherId);

  const [videoId, setVideoId] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    let video = data?.videoId;
    // FIXME: some videos are stored as URL instead of ID: this should be unified
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    if (urlRegex.test(video)) {
      video = extractVideoId(video);
    }
    setVideoId(video);
  }, [data]);

  const removeVideo = async () => {
    await dataProvider.updateTeacherVideo(record.teacherId, { videoId: null });
    setVideoId(null);
  };

  const saveVideo = async () => {
    const videoId = extractVideoId(videoUrl);
    if (videoId) {
      await dataProvider.updateTeacherVideo(record.teacherId, { videoId });
      setVideoId(videoId);
    }
  };

  return (
    <Card
      title={useTranslation(i18nPath, 'title')}
      icon={<Icons onClose={removeVideo} onDone={saveVideo} />}
    >
      {videoId ? (
        <Iframe videoId={videoId} />
      ) : (
        <UrlInput url={videoUrl} updateUrl={setVideoUrl} />
      )}
    </Card>
  );
};

export default Video;
