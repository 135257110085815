import * as utils from './utils';

const getOne = utils.getOne('teacher');
const putOne = utils.putOne('teacher');

const provider = {
  getTeacherLessonInfo: getOne('lesson_info'),

  updateTeacherLessonInfo: putOne('lesson_info'),

  getTeacherRates: getOne('rates'),

  updateTeacherRates: putOne('rates'),

  getTeacherLanguages: getOne('spoken_languages'),

  updateTeacherLanguages: putOne('spoken_languages'),

  getTeacherAvailability: getOne('availability'),

  updateTeacherAvailability: putOne('availability'),

  getTeacherPresentation: getOne('presentation'),

  updateTeacherPresentation: putOne('presentation'),

  getTeacherCurriculum: getOne('curriculum'),

  updateTeacherCurriculum: putOne('curriculum'),

  getTeacherLessonsIntro: getOne('lessons_intro'),

  updateTeacherLessonsIntro: putOne('lessons_intro'),

  getTeacherCharacteristics: getOne('characteristics'),

  updateTeacherCharacteristics: putOne('characteristics'),

  getTeacherVideo: getOne('video'),

  updateTeacherVideo: putOne('video'),

  getTeacherNativeLanguages: getOne('native_languages'),

  getTeacherSpokenLanguages: teacherId => utils.get(`${teacherId}/spoken_languages`),
  
  getTeacherStatus: getOne('status'),

  updateTeacherStatus: putOne('status'),
};

export default provider;
