/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { useForm } from 'react-hook-form';
import { WEEKDAYS } from 'core/constants';
import FormCard from '../FormCard';
import Week from './availability/Week';
import TimeTable from './availability/TimeTable';
import { useGetTeacherAvailability, useTranslation, useWeek } from 'hooks';
import { DateTime } from 'luxon';

const getWeekdayIndexStartingFromSunday = (weekday) => {
  return (WEEKDAYS.indexOf(weekday) + 1) % 7;
}

const i18nPath = 'resources.platform_users.tabs.teacherData.availability';

const pushTimeRange = ({ data, week, day, start, end }) => {
  data[week] ??= {};
  data[week][day] ??= [];
  data[week][day].push({ start, end });
};

const restructureAvailabilities = (availabilities) => {
  const weeksInYear = DateTime.local().weeksInWeekYear;
  const processedData = Array.from({ length: weeksInYear }, () => ({}));

  for (const { start, end } of availabilities) {
    const startDate = DateTime.fromMillis(start).setLocale('en-EN');
    const endDate = DateTime.fromMillis(end).setLocale('en-EN');

    const { weekdayLong: startWeekdayLong, hour: startHour } = startDate;
    const { weekdayLong: endWeekdayLong, hour: endHour } = endDate;

    const startWeekday = startWeekdayLong.toUpperCase();
    const endWeekday = endWeekdayLong.toUpperCase();
    const spansMultipleDays = startWeekday !== endWeekday;

    const { weekNumber: startWeekNumber } = startDate;
    pushTimeRange({
      data: processedData,
      week: startWeekNumber - 1,
      day: startWeekday,
      start: startHour,
      end: spansMultipleDays ? 24 : endHour,
    });

    if (spansMultipleDays) {
      const { weekNumber: endWeekNumber } = endDate;
      pushTimeRange({
        data: processedData,
        week: endWeekNumber - 1,
        day: endWeekday,
        start: 0,
        end: endHour,
      });
    }
  }

  return processedData;
};

const Availability = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherAvailability(record?.teacherId);
  const [week, nextWeek, prevWeek] = useWeek();

  const methods = useForm({
    defaultValues: {
      availability: [],
    },
  });

  useEffect(() => {
    const { reset } = methods;
    const availability = restructureAvailabilities(data?.availability ?? []);
    reset({ availability });
  }, [data]);

  const handleSubmit = async ({ availability }) => {
    const flattenedAvailabilities = availability.flatMap((week, weekOffset) => {
      return Object.entries(week).flatMap(([day, ranges]) => {
        return ranges.map(({ start, end }) => {
          const currentDay = DateTime.local()
            .startOf('year')
            .plus({ weeks: weekOffset, days: getWeekdayIndexStartingFromSunday(day) });

          return {
            start: currentDay.plus({ hours: start }).toUTC().toMillis(),
            end: currentDay.plus({ hours: end }).toUTC().toMillis(),
            week: weekOffset,
            day,
          };
        });
      });
    });

    console.log({ flattenedAvailabilities })

    await dataProvider.updateTeacherAvailability(record.teacherId, {
      availabilities: flattenedAvailabilities,
    });
  };

  return (
    <FormCard
      title={useTranslation(i18nPath, 'title')}
      methods={methods}
      onSubmit={handleSubmit}
    >
      <Week week={week} next={nextWeek} prev={prevWeek} />
      <TimeTable week={week} />
    </FormCard>
  );
};

export default Availability;
