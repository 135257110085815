import * as React from 'react';
import SvgIcon  from '@mui/material/SvgIcon';

export function HomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

export function UsersIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z"/>
        </SvgIcon>
    );
}

export function ProductIcon() {
    return (
        <SvgIcon>
            <path d="m23 14h-1v-9a5.006 5.006 0 0 0 -5-5h-10a5.006 5.006 0 0 0 -5 5v9h-1a1 1 0 0 0 0 2h10v4h-2a3 3 0 0 0 -3 3 1 1 0 0 0 2 0 1 1 0 0 1 1-1h6a1 1 0 0 1 1 1 1 1 0 0 0 2 0 3 3 0 0 0 -3-3h-2v-4h10a1 1 0 0 0 0-2zm-19-9a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v9h-16z"/>
        </SvgIcon>
    );
}

export function ListIcon() {
    return (
        <SvgIcon>
            <path d="M17,24H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h10c2.757,0,5,2.243,5,5v14c0,2.757-2.243,5-5,5ZM7,2c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3V5c0-1.654-1.346-3-3-3H7Zm11,4c0-.552-.448-1-1-1h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1Zm0,6c0-.552-.448-1-1-1h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1Zm0,6c0-.552-.448-1-1-1h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1ZM10,7v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Zm0,6v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Zm0,6v-2c0-.552-.448-1-1-1h-2c-.552,0-1,.448-1,1v2c0,.552,.448,1,1,1h2c.552,0,1-.448,1-1Z"/>
        </SvgIcon>
    );
}

export function ListCheckIcon() {
    return (
        <SvgIcon>
            <path d="m4 6a2.982 2.982 0 0 1 -2.122-.879l-1.544-1.374a1 1 0 0 1 1.332-1.494l1.585 1.414a1 1 0 0 0 1.456.04l3.604-3.431a1 1 0 0 1 1.378 1.448l-3.589 3.414a2.964 2.964 0 0 1 -2.1.862zm20-2a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-17.9 9.138 3.589-3.414a1 1 0 1 0 -1.378-1.448l-3.6 3.431a1.023 1.023 0 0 1 -1.414 0l-1.59-1.585a1 1 0 0 0 -1.414 1.414l1.585 1.585a3 3 0 0 0 4.226.017zm17.9-1.138a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1zm-17.9 9.138 3.585-3.414a1 1 0 1 0 -1.378-1.448l-3.6 3.431a1 1 0 0 1 -1.456-.04l-1.585-1.414a1 1 0 0 0 -1.332 1.494l1.544 1.374a3 3 0 0 0 4.226.017zm17.9-1.138a1 1 0 0 0 -1-1h-10a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1z"/>
        </SvgIcon>
    );
}

export function RoleIcon() {
    return (
        <SvgIcon>
            <path d="m19 4h-4v-1a3 3 0 0 0 -6 0v1h-4a5.006 5.006 0 0 0 -5 5v10a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-10a5.006 5.006 0 0 0 -5-5zm-8-1a1 1 0 0 1 2 0v2a1 1 0 0 1 -2 0zm11 16a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3-3v-10a3 3 0 0 1 3-3h4.184a2.982 2.982 0 0 0 5.632 0h4.184a3 3 0 0 1 3 3zm-12-9h-5a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-8a1 1 0 0 0 -1-1zm-1 8h-3v-6h3zm11-3a1 1 0 0 1 -1 1h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1zm0-4a1 1 0 0 1 -1 1h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1zm-2 8a1 1 0 0 1 -1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1z"/>
        </SvgIcon>
    );
}

export function TeacherIcon() {
    return (
        <SvgIcon>
            <path d="M12,10a3,3,0,1,1,3-3A3,3,0,0,1,12,10Zm5,4a3,3,0,0,0-3-3H10a3,3,0,0,0-3,3v2H9V14a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v2h2Zm-4.991,9.665a2.01,2.01,0,0,1-1.338-.509L6.923,20H0V3A3,3,0,0,1,3,0H21a3,3,0,0,1,3,3V20H17.147L13.3,23.18A1.94,1.94,0,0,1,12.009,23.665ZM2,18H7.654l4.325,3.645L16.429,18H22V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3Z"/>
        </SvgIcon>
    );
}

export function TrashIcon() {
    return (
        <SvgIcon>
            <svg id="Capa_2" viewBox="0 0 426.66 529.25"><g id="Layer_1"><path d="M296.82,59.92V12.97c0-7.17-5.81-12.97-12.97-12.97H142.81c-7.16,0-12.97,5.8-12.97,12.97V59.92H0v42.66H21.33V465.25c0,35.34,28.65,64,64,64H341.33c35.35,0,64-28.66,64-64V102.58h21.33V59.92h-129.84Zm-121.43-23.1c0-2.86,2.31-5.17,5.17-5.17h65.54c2.86,0,5.17,2.31,5.17,5.17v23.1h-75.88v-23.1Zm187.27,428.43c0,11.78-9.55,21.33-21.33,21.33H85.33c-11.78,0-21.33-9.55-21.33-21.33V102.58H362.66V465.25Z"/></g></svg>
        </SvgIcon>
    );
}


