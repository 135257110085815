import { useResourceContext, useNotify } from 'react-admin';
const useDuplicateNotify = () => {
  const resource = useResourceContext();
  const notify = useNotify();

  return (error) => {
    if (error.status === 409) {
      notify(`resources.${resource}.duplicated`, 'warning');
    }
  };
};

export default useDuplicateNotify;
