import { Create } from 'react-admin';
import LanguageLevelForm from './LanguageLevelForm';

const LanguageLevelCreate = () => (
  <Create>
    <LanguageLevelForm />
  </Create>
);

export default LanguageLevelCreate;
