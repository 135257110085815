const dateParseRegex = /(\d{2})\/(\d{2})\/(\d{4})/;

export const dateParser = (value, message) => {
  if (value !== null && value.length > 0) {
    const match = dateParseRegex.exec(value);
    if (match === null || match.length === 0) {
      return message;
    }
    const [day, month, year] = value.split('/').map(Number);
    const monthIndex = month - 1;
    if (day > 31 || monthIndex > 11) {
      return message;
    }
    const d = new Date(year, monthIndex, day);
    if (isNaN(d.getTime())) {
      return message;
    }
  }
  return;
};

const timeParseRegex = /(\d{2}):(\d{2})/;

export const timeParser = (value, message) => {
  if (value !== null &&  value.length > 0) {
    const match = timeParseRegex.exec(value);
    if (match === null || match.length === 0) {
      return message;
    }
    const [hours, minutes] = value.split(':').map(Number);
    if (hours > 23 || minutes > 59) {
      return message;
    }
    const d = new Date('1970-01-01T' + value + 'Z');
    if (isNaN(d.getTime())) {
      return message;
    }
  }
  return;
};