import { Edit, useRecordContext, useTranslate, useUpdate } from 'react-admin';
import CampaignForm from './CampaignForm';

const CampaignTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.campaigns.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const FormWrapper = () => {
  const [update] = useUpdate();

  return (
    <CampaignForm notification={'ra.notification.updated'} saveFn={update} />
  );
};

const CampaignEdit = () => {
  return (
    <Edit title={<CampaignTitle />}>
      <FormWrapper />
    </Edit>
  );
};

export default CampaignEdit;
