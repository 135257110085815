import { parseJwt } from 'utils/jwt';
import jwtManager from './jwtManager';

const authProvider = (apiUrl) => {
  jwtManager.setRefreshTokenEndpoint(`${apiUrl}/auth/token`);

  return {
    login: async ({ email, password }) => {
      const request = new Request(`${apiUrl}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include',
      });
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const { token, refreshToken } = await response.json();

      return jwtManager.setToken(token, refreshToken);
    },

    logout: async () => {
      jwtManager.deleteToken();

      return '/login';
    },

    checkAuth: async () => {
      await jwtManager.waitForTokenRefresh();

      return jwtManager.getToken() ? Promise.resolve() : Promise.reject();
    },

    checkError: async (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        jwtManager.deleteToken();
        return Promise.reject();
      }

      return Promise.resolve();
    },

    getPermissions: async () => {
      await jwtManager.waitForTokenRefresh();

      return jwtManager.getToken() ? Promise.resolve() : Promise.reject();
    },

    getIdentity: () => {
      const { sub, firstName, lastName } = parseJwt(
        localStorage.getItem('token')
      );
      return { id: sub, fullName: `${firstName} ${lastName}` };
    },
  };
};

export default authProvider;
