import { Grid } from '@mui/material';
import { AVAILABLE_LANGS, DEFAULT_CAMPAIGN_END_TIME, DEFAULT_CAMPAIGN_START_TIME, DEFAULT_LANG } from 'core/constants';
import { useDuplicateNotify } from 'hooks';
import {
  maxValue,
  minValue,
  NumberInput,
  required,
  Form,
  SaveButton,
  TextInput,
  TranslatableInputs,
  useNotify,
  useRedirect
} from 'react-admin';
import { int } from 'utils/validators';
import CampaignConditions from './form/CampaignConditions';
import CampaignDates from './form/CampaignDates';
import CampaignPromotionalData from './form/CampaignPromotionalData';
import { createDateFromDDMMYYYYHHMM } from 'utils/format';
import { convertFileToBase64 } from 'utils/file';

const CampaignForm = ({ notification, saveFn }) => {

  const redirect = useRedirect();
  const notify = useNotify();
  const notifyDuplicated = useDuplicateNotify();

  const save = async ({
    id,
    nameLocalizations,
    startDate,
    formattedStartDate,
    formattedStartTime,
    endDate,
    formattedEndDate,
    formattedEndTime,
    discount,
    couponCode,
    maxCoupons,
    usedCoupons,
    oncePerUser,
    allowTrialLesson,
    allowWithPreviousPurchase,
    useUserTimezone,
    showBannerPopup,
    headerBannerTitleLocalizations,
    headerBannerSubtitleLocalizations,
    headerBannerBackgroundImage,
    popupTitleLocalizations,
    popupSubtitleLocalizations,
    popupTextLocalizations,
    popupBackgroundImage
  }) => {
    let formattedStartDateAndTime = formattedStartDate;
    if (formattedStartDateAndTime?.length > 0) {
      formattedStartDateAndTime = `${formattedStartDateAndTime} ${formattedStartTime.length > 0 ? formattedStartTime : DEFAULT_CAMPAIGN_START_TIME}`;
    }

    let formattedEndDateAndTime = formattedEndDate;
    if (formattedEndDateAndTime?.length > 0) {
      formattedEndDateAndTime = `${formattedEndDateAndTime} ${formattedEndTime.length > 0 ? formattedEndTime : DEFAULT_CAMPAIGN_END_TIME}`;
    }

    const newStartDate = createDateFromDDMMYYYYHHMM(formattedStartDateAndTime);
    const newEndDate = createDateFromDDMMYYYYHHMM(formattedEndDateAndTime);

    let utcStartDate = null;
    if (newStartDate) {
      utcStartDate = new Date(Date.UTC(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate(), newStartDate.getHours(), newStartDate.getMinutes(), 0));
    }
    
    let utcEndDate = null;
    if (newEndDate) {
      utcEndDate = new Date(Date.UTC(newEndDate.getFullYear(), newEndDate.getMonth(), newEndDate.getDate(), newEndDate.getHours(), newEndDate.getMinutes(), 59));
    }

    const payload = {
      id,
      data: {
        nameLocalizations,
        startDate: utcStartDate,
        endDate: utcEndDate,
        discount,
        couponCode,
        maxCoupons,
        usedCoupons,
        oncePerUser,
        allowTrialLesson,
        allowWithPreviousPurchase,
        useUserTimezone,
        showBannerPopup,
        headerBannerTitleLocalizations,
        headerBannerSubtitleLocalizations,
        headerBannerBackgroundImageTitle: headerBannerBackgroundImage ? headerBannerBackgroundImage.title : null,
        ...(headerBannerBackgroundImage && headerBannerBackgroundImage.rawFile
          ? {
              headerBannerBackgroundImageSrc: await convertFileToBase64(headerBannerBackgroundImage)
                .then(src => src.split(',')[1])
            }
          : { headerBannerBackgroundImageFormerUrl: headerBannerBackgroundImage ? headerBannerBackgroundImage.src : null }
        ),
        popupTitleLocalizations,
        popupSubtitleLocalizations,
        popupTextLocalizations,
        popupBackgroundImageTitle: popupBackgroundImage ? popupBackgroundImage.title : null,
        ...(popupBackgroundImage && popupBackgroundImage.rawFile
          ? {
              popupBackgroundImageSrc: await convertFileToBase64(popupBackgroundImage)
                .then(src => src.split(',')[1])
            }
          : { popupBackgroundImageFormerUrl: popupBackgroundImage ? popupBackgroundImage.src : null }
        )
      }
    };

    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/campaigns');
        notify(notification, {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
      onError: notifyDuplicated,
    };

    saveFn('campaigns', payload, options);
  };

  return (
    <Form onSubmit={save}>
      <Grid container>
        <Grid item xs={12}>
          <TranslatableInputs locales={AVAILABLE_LANGS} defaultLocale={DEFAULT_LANG}>
            <TextInput source="nameLocalizations" validate={[required()]} fullWidth />
          </TranslatableInputs>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <NumberInput
              source="discount"
              validate={[required(), minValue(0), maxValue(100), int()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={`resources.campaigns.fields.couponCode`}
              source="couponCode"
              validate={[required()]}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <NumberInput
              source="maxCoupons"
              validate={[required(), minValue(1), int()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              source="usedCoupons"
              disabled={true}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <CampaignConditions />
        </Grid>
        <Grid container item spacing={2}>
          <CampaignDates />
        </Grid>
        <Grid container>
          
        </Grid>
      </Grid>
      <CampaignPromotionalData />
      
      <SaveButton alwaysEnable />
    </Form>
  );
};

export default CampaignForm;
