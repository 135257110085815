import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherCharacteristics = (id) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherCharacteristics', id], () => {
    if (id)
      return dataProvider
        .getTeacherCharacteristics(id)
        .then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherCharacteristics;
