import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';

const StyledCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 24px;
  }
`;

export default StyledCheckbox;
