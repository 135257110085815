/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { TextInput, useDataProvider, useRecordContext } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useGetTeacherLessonsIntro, useTranslation } from 'hooks';
import FormCard from '../FormCard';

const i18nPath = 'resources.platform_users.tabs.teacherData.aboutLessons';

const AboutLessons = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherLessonsIntro(record?.teacherId);

  const methods = useForm({
    defaultValues: {
      lessonsIntro: '',
    },
  });

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updateTeacherLessonsIntro(record.teacherId, data);
  };

  return (
    <FormCard
      title={useTranslation(i18nPath, 'title')}
      methods={methods}
      onSubmit={handleSubmit}
    >
      <TextInput label={false} source='lessonsIntro' fullWidth multiline />
    </FormCard>
  );
};

export default AboutLessons;
