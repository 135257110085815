import {
  Button,
  Show,
  TopToolbar,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Grid, Typography } from '@mui/material';
import Movement from "./show/Movement";
import { useState } from "react";
import ResolveRefundModal from "./show/ResolveRefundModal";
import ResolveAdjustmentModal from "./show/ResolveAdjustmentModal";

const ShowTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.movements.name', { smart_count: 1 })
  if (!record) {
    return `${label}:`;
  }
  return `${label}: ${record.type.label} : ${record.id}`;
};

const PostShowActions = ({ openRefundModal, openAdjustmentModal}) => {
  const translate = useTranslate();
  return (
    <TopToolbar>
      <Button color="primary" onClick={openRefundModal}>
        <Typography>
          {translate('resources.movements.buttons.refund')}
        </Typography>
      </Button>
      <Button color="primary" onClick={openAdjustmentModal}>
        <Typography>
          {translate('resources.movements.buttons.adjustment')}
        </Typography>
      </Button>
    </TopToolbar>
  );
}

const MovementShow = () => {
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openAdjustmentModal, setOpenAdjustmentModal] = useState(false);
  return (
    <>
      <Show
        title={<ShowTitle />}
        actions={
          <PostShowActions
            openRefundModal={() => {
              setOpenAdjustmentModal(false);
              setOpenRefundModal(true);
            }}
            openAdjustmentModal={() => {
              setOpenRefundModal(false);
              setOpenAdjustmentModal(true);
            }}
          />
        }
      >
        <ResolveRefundModal open={openRefundModal} onClose={() => setOpenRefundModal(false)} />
        <ResolveAdjustmentModal open={openAdjustmentModal} onClose={() => setOpenAdjustmentModal(false)} />
        <Grid container spacing={10}>
          <Movement />
        </Grid>
      </Show>
    </>
  );
};

export default MovementShow;