import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

const ClientTypeList = () => (
  <List>
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <ReferenceArrayField source='sectors' reference='sectors'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <DeleteButton />
    </Datagrid>
  </List>
);

export default ClientTypeList;
