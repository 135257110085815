import { TextInput, useRecordContext, useTranslate } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import { Grid, FormControlLabel } from '@mui/material';
import StyledCheckbox from "./StyledCheckbox";
import { createDateFromDDMMYYYY, formatAndLocalizeUTCDateAndTime } from 'utils/format';
import { useState } from 'react';
import { dateParser, timeParser } from './CampaignDates.validate';
import { DEFAULT_CAMPAIGN_END_TIME, DEFAULT_CAMPAIGN_START_TIME } from 'core/constants';

const CampaignDates = () => {
  const translate = useTranslate();

  const { control } = useFormContext();

  const {
    field: { value: useUserTimezone, onChange: onUseUserTimezoneChange },
  } = useController({ name: 'useUserTimezone', control });

  const setUseUserTimezone = () => {
    onUseUserTimezoneChange(!useUserTimezone);
  };

  const initializeUseUserTimezone = () => {
    onUseUserTimezoneChange(false);
  };

  if (useUserTimezone === undefined) {
    initializeUseUserTimezone();
  }

  const record = useRecordContext();

  const [formattedStartDate, setFormattedStartDate] = useState(record?.startDate ? formatAndLocalizeUTCDateAndTime(record.startDate).date : null);
  const [formattedStartTime, setFormattedStartTime] = useState(record?.startDate ? formatAndLocalizeUTCDateAndTime(record.startDate).time : null);
  const [formattedEndDate, setFormattedEndDate] = useState(record?.endDate ? formatAndLocalizeUTCDateAndTime(record.endDate).date : null);
  const [formattedEndTime, setFormattedEndTime] = useState(record?.endDate ? formatAndLocalizeUTCDateAndTime(record.endDate).time : null);

  const MAX_SPLITTED_DATE_PARTS = 3;
  const MAX_SPLITTED_TIME_PARTS = 2;

  const handleStartDateChange = (event) => {
    // Expected value format: DD/MM/YYYY
    const value = event.target.value;
    const parts = value.split('/');
    if (parts.length === MAX_SPLITTED_DATE_PARTS) {
      const newDate = createDateFromDDMMYYYY(value);
      if (!isNaN(newDate.getTime())) {
        setFormattedStartDate(formatAndLocalizeUTCDateAndTime(newDate.getTime()).date);
      }
    }
  };

  const handleStartTimeChange = (event) => {
    // Expected value format: HH:MM
    const value = event.target.value;
    const parts = value.split(':');
    if (parts.length === MAX_SPLITTED_TIME_PARTS) {
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        const newDate = createDateFromDDMMYYYY(formattedStartDate);
        newDate.setHours(hours, minutes, 0, 0);
        if (!isNaN(newDate.getTime())) {
          setFormattedStartTime(formatAndLocalizeUTCDateAndTime(newDate.getTime()).time);
        }
      }
    }
  };

  const handleEndDateChange = (event) => {
    // Expected value format: DD/MM/YYYY
    const value = event.target.value;
    const parts = value.split('/');
    if (parts.length === MAX_SPLITTED_DATE_PARTS) {
      const newDate = createDateFromDDMMYYYY(value);
      if (!isNaN(newDate.getTime())) {
        setFormattedEndDate(formatAndLocalizeUTCDateAndTime(newDate.getTime()).date);
      }
    }
  };

  const handleEndTimeChange = (event) => {
    // Expected value format: HH:MM
    const value = event.target.value;
    const parts = value.split(':');
    if (parts.length === MAX_SPLITTED_TIME_PARTS) {
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        const newDate = createDateFromDDMMYYYY(formattedEndDate);
        newDate.setHours(hours, minutes, 0, 0);
        if (!isNaN(newDate.getTime())) {
          setFormattedEndTime(formatAndLocalizeUTCDateAndTime(newDate.getTime()).time);
        }
      }
    }
  };

  const validationWrongFormatMessage = translate('resources.campaigns.wrongFormat');

  return (
    <>
      <Grid container item rowSpacing={1}>
        <span className='margin-top-xsmall'>{translate('resources.campaigns.datesUTCMessage')}</span>
      </Grid>
      <Grid container item>
        <Grid item xs={3}>
          <TextInput
            label={`resources.campaigns.fields.startDate`}
            source='formattedStartDate'
            validate={(value) => dateParser(value, validationWrongFormatMessage)}
            defaultValue={formattedStartDate}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label={`resources.campaigns.fields.startTime`}
            source='formattedStartTime'
            placeholder={DEFAULT_CAMPAIGN_START_TIME}
            validate={(value) => timeParser(value, validationWrongFormatMessage)}
            defaultValue={formattedStartTime}
            onChange={handleStartTimeChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label={`resources.campaigns.fields.endDate`}
            source='formattedEndDate'
            validate={(value) => dateParser(value, validationWrongFormatMessage)}
            defaultValue={formattedEndDate}
            onChange={handleEndDateChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label={`resources.campaigns.fields.endTime`}
            source='formattedEndTime'
            placeholder={DEFAULT_CAMPAIGN_END_TIME}
            validate={(value) => timeParser(value, validationWrongFormatMessage)}
            defaultValue={formattedEndTime}
            onChange={handleEndTimeChange}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <StyledCheckbox checked={useUserTimezone} onChange={setUseUserTimezone} />
          }
          label={translate('resources.campaigns.fields.useUserTimezone')}
        />  
      </Grid>
    </>
  );

};

export default CampaignDates;
