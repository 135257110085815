import { curry } from 'utils/func';
import httpClient from '../httpClient';

const apiUrl = process.env.REACT_APP_API_URL;

const wrapJson = ({ json }) => ({ data: json });

const request = (endpoint, options = {}) =>
  httpClient(`${apiUrl}/${endpoint}`, options).then(wrapJson);

const unwrappedRequest = (endpoint, options = {}) =>
  httpClient(`${apiUrl}/${endpoint}`, options);

export const get = (url) => request(url);

export const getXML = (url) => unwrappedRequest(url, new Headers({ 'Content-Type': 'application/xml', }));

export const post = (url, data) =>
  request(url, { method: 'POST', body: JSON.stringify(data) });

export const put = (url, data) =>
  request(url, { method: 'PUT', body: JSON.stringify(data) });

export const getOne = curry((resource, sub, id) =>
  get(`${resource}/${id}/${sub}`)
);

export const putOne = curry((resource, sub, id, data) =>
  put(`${resource}/${id}/${sub}`, data)
);
