import { Create, useCreate } from 'react-admin';
import CompensationForm from './CompensationForm';
import { COMPENSATION } from './constants';

const CompensationCreate = () => {
  const [create] = useCreate();

  return (
    <Create>
      <CompensationForm
        defaultValues={{
          type: COMPENSATION,
        }}
        notification={'ra.notification.created'}
        saveFn={create}
      />
    </Create>
  );
};

export default CompensationCreate;
