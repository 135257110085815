/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  useTranslate,
  useUpdate,
  useNotify,
  useRedirect,
  useGetList,
  useRecordContext,
} from 'react-admin';
import {
  Stack,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { useForm, useController } from 'react-hook-form';

const ResolveForm = ({ closeModal }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const { data: incidentResponseTypes } = useGetList('incident_response_types', {
    sort: { field: 'name', order: 'ASC' },
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      responseText: record.responseText,
      responseType: record.responseType.id,
    },
  });

  const { field: responseTypeField } = useController({ name: 'responseType', control });
  const { field: textField } = useController({ name: 'responseText', control });

  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();
  const onSubmit = ({ responseType, responseText }) => {
    const payload = { id: record.id, data: { responseType, responseText } };

    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/incidents');
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
    };

    update('incidents', payload, options);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography align="center" variant="body1">
        {translate('resources.incidents.form.heading')}
      </Typography>
      <Stack>
        <Select value={responseTypeField.value} onChange={responseTypeField.onChange}>
          {incidentResponseTypes?.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label={translate('resources.incidents.form.text')}
          value={textField.value}
          onChange={textField.onChange}
          multiline
          required
        />
      </Stack>
      <Grid container>
        <Grid item xs={6}>
          <Button onClick={closeModal}>
            {translate('resources.incidents.buttons.cancel')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type="submit">
            {translate('resources.incidents.buttons.resolve')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ResolveForm;
