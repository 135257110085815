/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  SelectInput,
  TextInput,
  required,
  email,
  useDataProvider,
  useGetList,
  useRecordContext,
  ReferenceInput,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import FormCard from '../FormCard';
import Field from '../Field';
import { useGetPlatformUserGeneralData, useTranslation } from 'hooks';

const i18nPath = 'resources.platform_users.tabs.generalData.generalData';

const FirstName = () => (
  <Field name={useTranslation(i18nPath, 'firstName')}>
    <TextInput label={false} source='firstName' validate={[required()]} />
  </Field>
);

const LastName = () => (
  <Field name={useTranslation(i18nPath, 'lastName')}>
    <TextInput label={false} source='lastName' validate={[required()]} />
  </Field>
);

const Gender = () => {
  const { data: choices } = useGetList('genders');
  return (
    <Field name={useTranslation(i18nPath, 'gender')}>
      <SelectInput
        label={false}
        source='gender'
        choices={choices ?? []}
        optionText='label'
        optionValue='id'
        validate={[required()]}
      />
    </Field>
  );
};

const Email = () => (
  <Field name={useTranslation(i18nPath, 'email')}>
    <TextInput label={false} source='email' validate={[required(), email()]} />
  </Field>
);

const Country = () => {
  const { data: choices } = useGetList('countries');
  return (
    <Field name={useTranslation(i18nPath, 'country')}>
      {(!choices)
        ? <></>
        : <SelectInput
          label={false}
          source='country'
          choices={choices ?? []}
          optionText='name'
          optionValue='id'
        />}
    </Field>
  );
};

const Timezone = () => {
  const { data: choices } = useGetList('time_zones');
  return (
    <Field name={useTranslation(i18nPath, 'timezone')}>
      <SelectInput
        label={false}
        source='timeZone'
        choices={choices ?? []}
        optionText='name'
        optionValue='id'
      />
    </Field>
  );
};

const Location = () => (
  <Field name={useTranslation(i18nPath, 'location')}>
    <TextInput label={false} source='location' />
  </Field>
);

const NativeLanguage = () => {
  return (
    <Field name={useTranslation(i18nPath, 'nativeLanguage')}>
      <ReferenceInput source='nativeLanguageId' reference='languages'>
        <SelectInput label={false} validate={[required()]} />
      </ReferenceInput>
    </Field>
  );
};

const GeneralDataForm = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetPlatformUserGeneralData(record?.id);

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      gender: '',
      email: '',
      country: '',
      timeZone: '',
      location: '',
      nativeLanguageId: null,
    },
  });

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updatePlatformUserGeneralData(record.id, data);
  };

  return (
    <FormCard
      title={useTranslation(i18nPath, 'title')}
      methods={methods}
      onSubmit={handleSubmit}
    >
      <FirstName />
      <LastName />
      <Gender />
      <Email />
      <Country />
      <Timezone />
      <Location />
      <NativeLanguage />
    </FormCard>
  );
};

export default GeneralDataForm;
