import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { TextField } from '@mui/material';

const Text = ({ control }) => {
  const translate = useTranslate();
  const { field } = useController({ name: 'text', control });

  return (
    <TextField
      label={translate('resources.compensations.fields.text')}
      multiline
      required
      {...field}
    />
  );
};

export default Text;
