import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetLanguageLevels = () => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getLanguageLevels'], () => {
    return dataProvider
      .getLanguageLevels()
      .then(({ data }) => data.map(item => ({ id: item?.id, name: item?.level })));
  });

  return query;
};

export default useGetLanguageLevels
