import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import WeekDays from './WeekDays';
import AvailableCell from './AvailabilityCell';
import { WEEKDAYS } from 'core/constants';

const formatHour = (i) => {
  const hour = i % 12;
  const period = i < 12 ? 'am' : 'pm';
  return `${hour === 0 ? 12 : hour}:00 ${period}`;
};

const hours = Array.from({ length: 24 }, (_, i) => i);

const TimeTable = ({ week }) => (
  <Table>
    <WeekDays />
    <TableBody>
      {hours.map((hour) => (
        <TableRow key={hour}>
          <TableCell>{formatHour(hour)}</TableCell>
          {WEEKDAYS.map((day) => (
            <AvailableCell
              key={`${day}_${week}_${hour}`}
              day={day}
              week={week}
              hour={hour}
            />
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default TimeTable;
