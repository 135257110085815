import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  DeleteButton,
  useTranslate,
} from 'react-admin';
import { formatDuration } from '../../utils/format';
import { Typography } from '@mui/material';
import { TrashIcon } from '../../icons/CustomIcons';
import { DEFAULT_LANG } from 'core/constants';

const ProductList = () => {
  const getDefaultNameLocalization = (nameLocalizations) => nameLocalizations[DEFAULT_LANG];

  const translate = useTranslate();

  return (
    <List>
      <Datagrid rowClick="edit">
        <FunctionField
          source="nameLocalizations"
          sortable={false}
          label="resources.products.list.name"
          render={({ nameLocalizations }) => getDefaultNameLocalization(nameLocalizations)}
        />
        <TextField source="lessons" />
        <FunctionField
          source="duration"
          label="resources.products.list.duration"
          render={({ duration }) => formatDuration(duration)}
        />
        <FunctionField
          source="price"
          render={({ price, isTeacherPrice }) =>
            isTeacherPrice ? translate('resources.products.list.price') : price
          }
        />
        <Typography align="right">
          <DeleteButton icon={<TrashIcon />} />
        </Typography>
      </Datagrid>
    </List>
  );
};

export default ProductList;
