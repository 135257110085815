import { MenuItemLink, useTranslate } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';

const Link = ({ text, to, icon = <DefaultIcon />, subItem, ...props }) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      {...props}
      to={to}
      primaryText={translate(text)}
      leftIcon={!subItem ? icon : null}
      sx={{ pl: subItem ? 7 : 2 }}
    />
  );
};

export default Link;
