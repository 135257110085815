const defaultMessages = {
  ra: {
    action: {
      add: 'Añadir',
      add_filter: 'Añadir filtro',
      back: 'Ir atrás',
      bulk_actions:
        '1 item seleccionado |||| %{smart_count} items seleccionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpiar valor',
      clone: 'Clonar',
      close: 'Cerrar',
      close_menu: 'Cerrar menú',
      confirm: 'Confirmar',
      create: 'Crear',
      create_item: 'Crear %{item}',
      delete: 'Eliminar',
      edit: 'Editar',
      expand: 'Expandir',
      export: 'Exportar',
      list: 'Listar',
      move_up: 'Mover arriba',
      move_down: 'Mover abajo',
      open_menu: 'Abrir menú',
      refresh: 'Refrescar',
      remove: 'Borrar',
      remove_filter: 'Borrar filtro',
      save: 'Guardar',
      search: 'Buscar',
      select_all: 'Seleccionar todo',
      select_row: 'Seleccionar esta fila',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Deshacer',
      unselect: 'Deseleccionar',
      update: 'Actualizar',
    },
    auth: {
      auth_check_error: 'Por favor inicie sesión para continuar',
      logout: 'Cerrar Sesión',
      password: 'Contraseña',
      sign_in: 'Acceder',
      sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
      user_menu: 'Perfil',
      username: 'Usuario',
    },
    boolean: {
      true: 'Sí',
      false: 'No',
      null: ' ',
    },
    input: {
      file: {
        upload_several:
          'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
        upload_single:
          'Arrastre un archivo para subir o haga clic para seleccionarlo.',
      },
      image: {
        upload_several:
          'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
        upload_single:
          'Arrastre alguna imagen para subir o haga clic para seleccionarla.',
      },
      references: {
        all_missing: 'No se pueden encontrar datos de referencias.',
        many_missing:
          'Al menos una de las referencias asociadas parece no estar disponible.',
        single_missing: 'La referencia asociada no parece estar disponible.',
      },
      password: {
        toggle_visible: 'Ocultar contraseña',
        toggle_hidden: 'Mostrar contraseña',
      },
    },
    message: {
      about: 'Acerca de',
      are_you_sure: '¿Está seguro?',
      bulk_delete_content:
        '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
      bulk_delete_title:
        'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
      bulk_update_content:
        '¿Seguro que quiere actualizar este %{name}? |||| ¿Seguro que quiere actualizar estos %{smart_count} items?',
      bulk_update_title:
        'Actualizar %{name} |||| Actualizar %{smart_count} %{name} items',
      delete_content: '¿Seguro que quiere eliminar este item?',
      delete_title: 'Eliminar %{name} #%{id}',
      details: 'Detalles',
      error:
        'Se produjo un error en el cliente y su solicitud no se pudo completar',
      invalid_form:
        'El formulario no es válido. Por favor verifique si hay errores',
      loading: 'La página se está cargando, espere un momento por favor',
      no: 'No',
      not_found:
        'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
      yes: 'Sí',
      unsaved_changes:
        'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?',
    },
    navigation: {
      next: 'Siguiente',
      no_more_results:
        'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
      no_results: 'No se han encontrado resultados',
      page_out_from_begin: 'No puede ir antes de la página 1',
      page_out_from_end: 'No puede ir después de la última página',
      page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Filas por página:',
      prev: 'Anterior',
      skip_nav: 'Saltar al contenido',
    },
    sort: {
      sort_by: 'Ordenar por %{field} %{order}',
      ASC: 'ascendente',
      DESC: 'descendente',
    },
    notification: {
      bad_item: 'Elemento incorrecto',
      canceled: 'Acción cancelada',
      created: 'Elemento creado',
      data_provider_error:
        'Error del proveedor de datos. Consulte la consola para más detalles.',
      deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
      http_error: 'Error de comunicación con el servidor',
      item_doesnt_exist: 'El elemento no existe',
      logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
      updated:
        'Elemento actualizado |||| %{smart_count} elementos actualizados',
      i18n_error:
        'No se pudieron cargar las traducciones para el idioma especificado',
      not_authorized: 'No tiene autorización para acceder a este recurso.',
    },
    page: {
      create: 'Crear %{name}',
      dashboard: 'Tablero',
      edit: '%{name} #%{id}',
      empty: 'Sin %{name} todavía.',
      error: 'Algo salió mal',
      invite: '¿Quiere agregar una?',
      list: 'Lista de %{name}',
      loading: 'Cargando',
      not_found: 'No encontrado',
      show: '%{name} #%{id}',
    },
    validation: {
      email: 'Debe ser un correo electrónico válido',
      maxLength: 'Debe contener %{max} caracteres o menos',
      maxValue: 'Debe ser %{max} o menos',
      minLength: 'Debe contener %{min} caracteres al menos',
      minValue: 'Debe ser al menos %{min}',
      number: 'Debe ser un número',
      oneOf: 'Debe ser uno de: %{options}',
      regex: 'Debe coincidir con un formato específico (regexp): %{pattern}',
      required: 'Requerido',
    },
  },
};

const messages = {
  ...defaultMessages,
  menu: {
    parameters: {
      header: 'Parametrías',
      class: 'Clases',
      account: 'Cuentas',
      credit: 'Créditos',
      cashing: 'Cobros',
      teacherCharacteristics: 'Características del profesorado',
    },
    campaigns: 'Gestión de campañas',
    products: 'Gestión de productos',
    accounts: 'Gestión de cuentas de profesores y alumnos',
    teacher_applications: 'Gestión de solicitudes de profesores',
    teacher_balance_summary: 'Resumen de saldos de profesores',
    languages: {
      header: 'Idiomas',
      offeredLanguagesAndComplementaries:
        'Gestión de idiomas ofertados/complementarios',
      levels: 'Niveles de idiomas',
    },
    accounting: {
      header: 'Contabilidad y finanzas',
      sectors: 'Agrupaciones y tipos de IVA',
      accounts: 'Cuentas contables',
      clientTypes: 'Tipos de cliente',
    },
    incidents: {
      header: 'Incidencias',
      incidentsAndCompensations: 'Gestión de incidencias y compensaciones',
      incidentTypes: 'Tipos de incidencias',
      incidentResponseTypes: 'Tipos de respuesta',
    },
    usersAndRoles: {
      header: 'Usuarios y roles',
      users: 'Usuarios',
      roles: 'Roles',
    },
    movements: 'Movimientos'
  },
  days: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
  },
  resources: {
    incident_types: {
      list: {
        name: 'Nombre'
      },
      name: 'Tipo de incidencia |||| Tipos de incidencias',
      fields: {
        name: 'Nombre',
        responseTypes: 'Tipos de respuestas',
      },
      redirect: [
        'Si no encuentras una posible respuesta adecuada puedes ',
        'añadir primero un nuevo tipo de respuesta ',
        'y luego continuar creando el tipo de incidencia.',
      ],
      duplicated: 'Ya existe un tipo de incidencia con ese nombre',
    },
    parameters: {
      save: 'Guardar',
      units: {
        price: '$',
        days: 'días',
        percentage: '%',
        applicationDate: 'fecha de aplicación',
      },
    },
    class_parameters: {
      name: 'Parametría de clases',
    },
    account_parameters: {
      name: 'Parametría de cuentas',
    },
    credit_parameters: {
      name: 'Parametría de crédito',
    },
    cashing_parameters: {
      name: 'Parametría de cobros',
    },
    teacher_applications: {
      name: 'Gestión de solicitudes de profesores',
      fields: {
        firstName: 'Nombre',
        lastName: 'Apellidos',
        country: 'País',
        id: 'ID candidatura',
        identityDocument: 'NIF',
        introductoryVideoId: 'Vídeo de presentación',
        birthDate: 'Fecha de nacimiento',
        email: 'Email',
        phoneNumber: 'Teléfono',
        teachingYears: 'Años de experiencia',
        pricePerHour: 'Precio por hora',
        weeklyTimeHours: 'Horas semanales',
        teacherTrainingDescription: 'Formación del profesor',
        createdAt: "Fecha de solicitud",
        updatedAt: "Última modificación",
        status: "Estado",
        nativeLanguages: "Idioma nativo",
        spokenLanguages: "Idiomas hablados",
        downloadSpeed: "Velocidad de descarga",
        ping: "Ping",
        presentation: "Presentación",
        accent: "Acento",
        teacherCharacteristics: "Especialidades",
        teacherPicture: "Foto de perfil",
        uploadSpeed: "Velocidad de subida",
      },
      status: {
        activated: "Activada",
        activation_pending: "Activación pendiente",
        approved: "Aprobada",
        in_progress: "En curso",
        interview_confirmed: "Entrevista confirmada",
        interview_no_confirmed: "Entrevista sin confirmar",
        pending: "Pendiente",
        pre_approved: "Preaprobada, esperando datos",
        rejected: "Rechazada",
        validated: "Validada",
      },
    },
    teacher_balance_summary: {
      name: 'Resumen de saldo de profesores',
      confirmMarkAsSentTitle: 'Marcar como Enviado',
      confirmMarkAsSentContent: '',
      fields: {
        transactionCode: 'Código de transacción',
        date: 'Fecha',
        downloaded: 'Descargado',
        sent: 'Enviado',
      },
      buttons: {
        download: 'Descargar',
        markAsSent: 'Marcar como Enviado',
      },
    },
    platform_users: {
      fields: {
        completeName: 'Nombre',
        nativeLanguage: 'Idioma Nativo',
        studentPendingLessons: 'Clases pendientes del alumno',
        teacherPendingLessons: 'Clases pendientes del profesor',
        studentCount: 'Alumnos',
        status: 'Estado',
        type: 'Tipo Cliente',
        email: 'Email',
      },
      name: 'Cuenta Profesor/Alumno |||| Cuentas de profesores y alumnos',
      tabs: {
        generalData: {
          title: 'Datos generales',
          generalData: {
            title: 'Datos generales',
            firstName: 'Nombre',
            lastName: 'Apellido',
            gender: 'Género',
            email: 'Email',
            country: 'País',
            timezone: 'Zona horaria',
            location: 'Localización',
            nativeLanguage: 'Idioma Nativo',
          },
          pendingLessons: {
            createdAt: 'Fecha creación',
            date: 'Fecha',
            lessonsAsStudent: {
              title: 'Clases como estudiante',
            },
            lessonsAsTeacher: {
              title: 'Clases como profesor',
            },
            reference: 'Referencia',
            status: 'Estado',
            student: 'Estudiante',
            teacher: 'Profesor',
          },
        },
        teacherData: {
          title: 'Datos del profesor',
          status: {
            title: 'Estado',
            active: 'Activo',
            cancelled: 'Cancelada',
            cancelledBy: 'Cancelada por',
          },
          lessons: {
            title: 'Clases',
            minAdvanceNotice: 'Antelación mínima',
            allowTrialLessons: 'Permitir clases de prueba',
            languages: 'Idiomas',
          },
          rates: {
            title: 'Tarifas',
            newStudentRate: 'Tarifa para nuevos alumnos',
            studentRates: 'Tarifas de alumnos',
          },
          languages: {
            title: 'Idiomas',
            language: 'Idioma',
            level: 'Nivel',
            document: 'Documento',
          },
          video: {
            title: 'Video',
            url: 'Url del video',
          },
          availability: {
            title: 'Disponibilidad',
            week: 'Semana',
          },
          presentation: {
            title: 'Presentación',
          },
          curriculum: {
            title: 'Curriculum',
          },
          characteristics: {
            title: 'Características',
          },
          aboutLessons: {
            title: 'Acerca de las clases',
          },
        },
        teacherApplications: {
          title: 'Solicitudes de profesor',
          status: {
            activated: "Activada",
            activation_pending: "Activación pendiente",
            approved: "Aprobada",
            in_progress: "En curso",
            interview_confirmed: "Entrevista confirmada",
            interview_no_confirmed: "Entrevista sin confirmar",
            pending: "Pendiente",
            pre_approved: "Preaprobada, esperando datos",
            rejected: "Rechazada",
            validated: "Validada",
          }
        },
      },
    },
    campaigns: {
      name: 'Campaña |||| Campañas',
      list: {
        name: 'Nombre',
        couponCode: 'Cupón',
        discount: 'Descuento',
        startDate: 'Fecha inicio (UTC)',
        endDate: 'Fecha fin (UTC)',
        maxCoupons: 'Cupones máximos',
        usedCoupons: 'Cupones usados',
        oncePerUser: 'Limitar a una vez por usuario',
        allowTrialLesson: 'Aceptar clase de prueba',
        allowWithPreviousPurchase: 'Aceptar con compras previas',
        useUserTimezone: 'Usar zona horaria del usuario'
      },
      fields: {
        couponCode: 'Cupón',
        discount: 'Descuento (Porcentaje)',
        startDate: 'Fecha inicio (dd/mm/YYYY)',
        startTime: 'Hora inicio (hh:mm)',
        endDate: 'Fecha fin (dd/mm/YYYY)',
        endTime: 'Hora fin (hh:mm)',
        maxCoupons: 'Cupones máximos',
        usedCoupons: 'Cupones usados',
        oncePerUser: 'Limitar a una vez por usuario',
        allowTrialLesson: 'Aceptar clase de prueba',
        allowWithPreviousPurchase: 'Aceptar con compras previas',
        useUserTimezone: 'Usar zona horaria del usuario',
        showBannerPopup: 'Configurar y mostrar banner y popup'
      },
      tabs: {
        headerBanner: 'Banner Cabecera',
        popup: 'Popup'
      },
      datesUTCMessage: 'Las fechas corresponden a la zona horaria UTC',
      duplicated: 'Ya existe una campaña con ese código de cupón',
      wrongFormat: 'Formato incorrecto'
    },
    products: {
      name: 'Producto |||| Productos',
      list: {
        duration: 'Duración',
        name: 'Nombre',
        price: 'Según tarifa profesor',
      },
      fields: {
        name: 'Nombre',
        lessons: 'Clases',
        duration: 'Duración (minutos)',
        isFree: 'Clase gratuita',
        isTrial: 'Clase de prueba',
        discount: 'Descuento',
        isTeacherPrice: 'Según tarifa profesor',
        price: 'Precio',
        commission: 'Comisión alumno',
        credits: 'Créditos',
      },
    },
    language_levels: {
      name: 'Nivel de idioma |||| Niveles de idioma',
      duplicated:
        'Ya existe un nivel de idioma con ese con este nivel e idioma',
      fields: {
        language: 'Idioma',
        level: 'Nivel',
        description: 'Descripción',
      },
    },
    offered_languages: {
      name: 'Gestión de idiomas ofertados',
      fields: {
        description: 'Descripción',
        activeTeachers: 'Total profesores activos',
        activeStudents: 'Total alumnos activos',
      },
      buttons: {
        addNewLanguage: 'Añadir nuevo idioma',
        addComplementary: 'Añadir idioma complementario',
      },
      form: {
        language: 'Idioma',
        code: 'Código',
        shortDescription: 'Descripción corta',
        longDescription: 'Descripción larga',
        min: 'Mínimo número de profesores necesario',
        max: 'Máximo número de profesores necesario',
      },
      filters: {
        languages: 'Idiomas',
        complementaries: 'Complementarios',
      },
      duplicated: 'Ya existe un idioma con ese código',
    },
    language_exams: {
      name: 'Gestión de exámenes',
      fields: {
        name: 'Nombre',
        description: 'Descripción',
        institution: 'Institución',
        abbreviation: 'Abreviación',
        tag: 'Tag',
        landing: 'Landing',
      },
      form: {
        name: 'Nombre',
        description: 'Descripción',
        institution: 'Institución',
        abbreviation: 'Abreviación',
        tag: 'Tag',
        landing: 'Landing',
      },
    },
    complementaries: {
      name: 'Idiomas complementarios',
      teacherCount: 'Nº profesores',
      minTeachers: 'Mín. necesario',
      maxTeachers: 'Máx. necesario',
      locked: 'Bloqueado',
      confirmTitle: 'Eliminar idioma complementario',
    },
    accounting_accounts: {
      name: 'Cuenta contable |||| Cuentas contables',
      fields: {
        number: 'Número',
        name: 'Nombre',
      },
      duplicated: 'Ya existe una cuenta con ese número',
    },
    client_types: {
      name: 'Tipo de cliente |||| Tipos de cliente',
      fields: {
        number: 'Nº',
        name: 'Nombre',
        sectors: 'Agrupaciones',
      },
      duplicated: 'Ya existe un tipo de cliente con ese número',
    },
    incidents: {
      name: 'Incidencia |||| Gestión de incidencias',
      fields: {
        classDate: 'Fecha clase',
        classTime: 'Hora clase',
        date: 'Fecha incidencia',
        givenSolution: 'Solución',
        incident: 'Incidente',
        lesson: 'Clase',
        reason: 'Motivo',
        reference: 'Nº de clase',
        reporter: 'Reportante',
        sentText: 'Comentario del usuario',
        status: {
          name: 'Estado',
          resolved: 'Resuelta',
          unresolved: 'No resuelta',
        },
        student: 'Alumno',
        teacher: 'Profesor',
        time: 'Hora clase',
        unresolved: 'La incidencia aún no ha sido resuelta',
      },
      form: {
        heading: 'Resolver incidencia',
        text: 'Texto',
      },
      buttons: {
        cancel: 'Cancelar',
        resolve: 'Resolver',
      },
    },
    movements: {
      name: 'Movimiento |||| Movimientos',
      fields: {
        createdAt: 'Fecha creación',
        type: 'Tipo',
        actor: 'Actor',
        amountCollectedByTeacher: 'Cantidad recibida por profesor',
        lesson: 'Clase',
        lessonAmount: 'Cantidad de clases',
        lessonDateTime: 'Fecha/hora clase',
        lessonPrice: 'Precio de la clase',
        lessonStudentJoinedAt: 'Hora entrada alumno',
        lessonTeacherJoinedAt: 'Hora entrada profesor',
        productName: 'Producto',
        purchaseCommissionApplied: 'Comisión pagada por el estudiante',
        purchasePrice: 'Cantidad pagada por el estudiante',
        purchaseCampaignDiscount: 'Descuento especial',
        student: 'Estudiante',
        teacher: 'Profesor',
        stripe: 'Stripe',
        teacherServiceFee: 'Tarifa del profesor',
        generatedCredits: 'Créditos generados',
        usedCredits: 'Créditos usados',
        availableCredits: 'Créditos disponibles',
        withdrawnBalance: 'Saldo retirado',
        totalBalance: 'Saldo total',
        retainedBalance: 'Saldo retenido',
        studentAdjustment: 'Ajuste estudiante',
        teacherAdjustment: 'Ajuste profesor'
      },
      form: {
        adjustment: {
          heading: 'Ajustar sesión',
          amountCollectedByTeacher: 'Cantidad recibida por profesor',
          purchasePrice: 'Cantidad pagada por el estudiante',
          amountToAdjustToStudent: 'Cantidad a ajustar al alumno',
          amountToAdjustToTeacher: 'Cantidad a ajustar al profesor'
        },
        refund: {
          heading: 'Reembolsar sesión',
          amountToRefundToStudent: 'Cantidad a reembolsar al alumno',
          amountToSubtractToTeacher: 'Cantidad a restar al profesor'
        }
      },
      buttons: {
        adjustment: 'Ajustar',
        cancel: 'Cancelar',
        refund: 'Reembolsar',
      },
    },
    compensations: {
      name: 'Compensación |||| Compensaciones',
      fields: {
        type: {
          name: 'Tipo',
          compensation: 'Compensación',
          penalization: 'Penalización',
        },
        user: 'Usuario',
        role: 'Rol',
        quantity: 'Cantidad',
        date: 'Fecha',
        text: 'Text',
      },
    },
    incident_response_types: {
      name: 'Tipo de respuesta |||| Tipos de respuestas',
      fields: {
        name: 'Nombre',
      },
      duplicated: 'Ya existe un tipo de respuesta con ese nombre',
    },
    users: {
      name: 'Usuario |||| Usuarios',
      fields: {
        firstName: 'Nombre',
        lastName: 'Apellidos',
      },
    },
    teacher_characteristics: {
      fields: {
        name: 'Nombre',
      },
      name: 'Característica profesorado |||| Características profesorado',
    },
    validation: {
      int: 'Debe ser un número entero',
      decimals: 'No puede contener más de %{max} decimales',
    },
    roles: {
      name: 'Rol |||| Roles',
      fields: {
        name: 'Nombre',
      },
      table: {
        available: 'Permisos disponibles',
        show: 'Ver',
        edit: 'Editar',
        hide: 'Ocultar',
      },
      duplicated: 'Ya existe un rol con ese nombre',
    },
    sectors: {
      fields: {
        commentary: 'Texto',
        countries: 'Paises',
        name: 'Nombre agrupación',
        vat: 'Tipo',
      },
      name: 'Agrupación |||| Agrupaciones y tipos de IVA',
    },
  },

  schema: {
    not_blank: 'No puede estar vacío',
    not_empty: 'No puede estar vacío',
    email: 'Debe ser un email válido',
    not_negative: 'No puede ser negativo',
    image: 'Debe ser una imagen',
    url: 'Debe ser una URL válida',
  }
};

export default messages;
