import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';

const useMarkTeacherBalanceSummaryAsSent = () => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { mutate, isLoading } = useMutation(({ hash }) =>
    dataProvider.markTeacherBalanceSummaryAsSent(hash, { sent: true }), {
    onSuccess: (data) => {
      refresh();
      notify('ra.notification.updated', {
        undoable: false,
        messageArgs: { smart_count: 1 },
      });
    },
    onError: (error) => {
      notify('ra.notification.http_error', 'warning');
    },
  });

  const markAsSent = (hash) => {
    mutate({ hash });
  };

  return [ markAsSent, isLoading ];
}

export default useMarkTeacherBalanceSummaryAsSent;
