import { useForm } from "react-hook-form";
import {
  Form,
  useTranslate,
  useUpdate,
  useNotify,
  useRedirect,
  useRecordContext,
} from 'react-admin';
import {
  Stack,
  Grid,
  Button,
  Typography,
} from '@mui/material';

const ResolveRefundForm = ({ closeModal }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const { handleSubmit } = useForm({
    defaultValues: {
      lessonPrice: record.lessonPrice,
      amountCollectedByTeacher: record.amountCollectedByTeacher
    }
  });

  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = () => {
    const payload = { id: record.id, data: {
      amountToRefundToStudent: record.lessonPrice,
      amountToSubtractToTeacher: record.amountCollectedByTeacher
    }};

    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/movements');
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
    };

    update('movements/refund', payload, options);
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography align="center" variant="body1">
        {translate('resources.movements.form.refund.heading')}
      </Typography>
      <Stack>
        <Typography align="left">
          {translate('resources.movements.form.refund.amountToRefundToStudent')}: {record.lessonPrice}
        </Typography>
        <Typography align="left">
          {translate('resources.movements.form.refund.amountToSubtractToTeacher')}: {record.amountCollectedByTeacher}
        </Typography>
      </Stack>
      <Grid container>
        <Grid item xs={6}>
          <Button onClick={closeModal}>
            {translate('resources.movements.buttons.cancel')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type="submit">
            {translate('resources.movements.buttons.refund')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ResolveRefundForm;