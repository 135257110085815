import { Edit, useRecordContext, useTranslate } from 'react-admin';
import IncidentResponseTypeForm from './IncidentResponseTypeForm';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';

const ProductTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.incident_response_types.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const IncidentResponseTypeEdit = () => {
  const notify = useDuplicateNotify();
  return (
    <Edit title={<ProductTitle />} mutationOptions={{ onError: notify }}>
      <IncidentResponseTypeForm />
    </Edit>
  );
};

export default IncidentResponseTypeEdit;
