import { Grid } from '@mui/material';
import GridItem from './GridItem';
import { ShowCheckbox, EditCheckbox, HideCheckbox } from './checkbox';

const Row = ({ control, permission }) => (
  <Grid container spacing={2} sx={{ maxWidth: '700px' }}>
    <Grid item xs={6}>
      {permission}
    </Grid>
    <GridItem xs={2}>
      <ShowCheckbox name={permission} control={control} />
    </GridItem>
    <GridItem xs={2}>
      <EditCheckbox name={permission} control={control} />
    </GridItem>
    <GridItem xs={2}>
      <HideCheckbox name={permission} control={control} />
    </GridItem>
  </Grid>
);

export default Row;
