import {
  Form,
  SaveButton,
  useGetList,
  useRedirect,
  useNotify,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import User from './form/User';
import Role from './form/Role';
import Type from './form/Type';
import Quantity from './form/Quantity';
import Text from './form/Text';
import { PENALIZATION } from './constants';

const CompensationForm = ({ defaultValues, notification, saveFn }) => {
  const { data: users } = useGetList('platform_users', {
    sort: { field: ({ completeName }) => completeName },
    order: 'ASC',
  });

  const { control, getValues } = useForm({
    defaultValues: {
      ...defaultValues,
      user: defaultValues.user ?? (users && users[0]),
      role: defaultValues.role ?? (users && users[0].privileges[0]),
    },
  });

  const redirect = useRedirect();
  const notify = useNotify();
  const save = ({ id }) => {
    const { user, type, role, quantity, text } = getValues();

    const payload = {
      id,
      data: {
        user,
        penalization: type === PENALIZATION,
        role,
        quantity,
        text,
      },
    };

    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/compensations');
        notify(notification, {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
    };

    saveFn('compensations', payload, options);
  };

  return (
    <Form onSubmit={save}>
      <Stack>
        <User users={users} control={control} />
        <Role control={control} />
        <Type control={control} />
        <Quantity control={control} />
        <Text control={control} />
      </Stack>
      <SaveButton alwaysEnable />
    </Form>
  );
};

export default CompensationForm;
