const jwtManager = () => {
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = '/refresh-token';

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    const waitForTokenRefresh = async () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }

        await isRefreshing;
        isRefreshing = null;

        return true;
    }

    const getRefreshedToken = async () => {
        const refreshToken = window.localStorage.getItem('refreshToken');
        const request = new Request(`${refreshEndpoint}?refresh_token=${refreshToken}`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        const response = await fetch(request);
        if (response.status !== 200) {
            deleteToken();
            global.console.log('Token renewal failure');
            isRefreshing = false;
        } else {
            const { token, refreshToken } = await response.json();
            setToken(token, refreshToken);
            isRefreshing = true;
        }

        return isRefreshing;
        
    };


    const getToken = () => window.localStorage.getItem('token');

    const setToken = (token, refreshToken) => {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('refreshToken', refreshToken);
        return true;
    };

    const deleteToken = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            window.localStorage.removeItem('token');
        }
    });

    return {
        deleteToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    }
};

export default jwtManager();
