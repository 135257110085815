import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherPresentation = (id) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherPresentation', id], () => {
    if (id)
      return dataProvider.getTeacherPresentation(id).then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherPresentation;
