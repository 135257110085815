import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useTranslate,
} from 'react-admin';
import { formatAndLocalizeDateAndTime } from 'utils/format';
import filters from './filters';

const CompensationList = () => {
  const translate = useTranslate();
  return (
    <List filters={filters}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="Nº" sortBy="id" source="number" />
        <FunctionField
          label="resources.compensations.fields.type.name"
          sortBy="penalization"
          render={({ penalization }) =>
            translate(
              `resources.compensations.fields.type.${penalization ? 'penalization' : 'compensation'}`
            )
          }
        />
        <TextField
          label="resources.compensations.fields.user"
          sortBy="platformUser.completeName"
          source="user.completeName"
        />
        <TextField
          label="resources.compensations.fields.role"
          sortBy="platformPrivilege.code"
          source="role.name"
        />
        <FunctionField
          label="resources.compensations.fields.quantity"
          sortBy="quantity"
          render={({ quantity }) => `${quantity}€`}
        />
        <FunctionField
          label="resources.compensations.fields.date"
          sortBy="createdAt"
          render={({ date }) => formatAndLocalizeDateAndTime(date).date}
        />
      </Datagrid>
    </List>
  );
};

export default CompensationList;
