/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  SimpleFormIterator,
  ArrayInput,
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  minValue,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import FormCard from '../FormCard';
import Field from '../Field';
import { useGetTeacherRates, useTranslation } from 'hooks';
import { decimals } from 'utils/validators';

const i18nPath = 'resources.platform_users.tabs.teacherData.rates';

const NewStudentRate = () => (
  <Field name={useTranslation(i18nPath, 'newStudentRate')}>
    <NumberInput
      label={false}
      source='newStudentRate'
      step={0.1}
      validate={[minValue(0), decimals(2)]}
    />
  </Field>
);

const StudentRates = () => (
  <ArrayInput label={`${i18nPath}.studentRates`} source='studentRates'>
    <SimpleFormIterator>
      <ReferenceInput source='student' reference='platform_users'>
        <AutocompleteInput
          label={false}
          optionText='completeName'
          optionValue='id'
          fullWidth
          validate={[required()]}
        />
      </ReferenceInput>
      <NumberInput
        label={false}
        source='rate'
        step={0.1}
        fullWidth
        validate={[required(), minValue(0), decimals(2)]}
      />
    </SimpleFormIterator>
  </ArrayInput>
);

const Rates = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherRates(record?.teacherId);

  const methods = useForm({
    defaultValues: {
      newStudentRate: 0,
      studentRates: [],
    },
  });

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updateTeacherRates(record.teacherId, data);
  };

  return (
    <FormCard
      methods={methods}
      title={useTranslation(i18nPath, 'title')}
      onSubmit={handleSubmit}
    >
      <NewStudentRate />
      <StudentRates rates={data?.studentRates ?? []} />
    </FormCard>
  );
};

export default Rates;
