/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import {
  Form,
  TextInput,
  required,
  SaveButton,
  useRedirect,
  useNotify,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import FormHeader from './form/Header';
import FormRow from './form/Row';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';

const RoleForm = ({ notification, permissions, saveFn }) => {
  const getFormDefaultValues = () => {
    const entries = permissions.map(({ name, option }) => [name, option]);
    return Object.fromEntries(entries);
  };

  const { control, reset, getValues } = useForm({
    defaultValues: useMemo(() => getFormDefaultValues(), [permissions]),
  });

  useEffect(() => {
    reset(getFormDefaultValues());
  }, [permissions]);

  const redirect = useRedirect();
  const notify = useNotify();
  const notifyDuplicated = useDuplicateNotify();
  const save = ({ id, name }) => {
    const formValues = getValues();
    const updatedPermissions = permissions.map(({ id, name }) => ({
      id,
      name,
      option: formValues[name],
    }));

    const payload = {
      id,
      data: { name, permissions: updatedPermissions },
      previousData: { name, permissions },
    };

    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/roles');
        notify(notification, {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
      onError: notifyDuplicated,
    };

    saveFn('roles', payload, options);
  };

  return (
    <Form onSubmit={save}>
      <TextInput source='name' validate={[required()]} />
      <FormHeader />
      {permissions.map(({ id, name }) => (
        <FormRow key={id} control={control} permission={name} />
      ))}
      <SaveButton alwaysEnable />
    </Form>
  );
};

export default RoleForm;
