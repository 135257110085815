import { AVAILABLE_LANGS, DEFAULT_LANG } from "core/constants";
import { Grid } from '@mui/material';

import {
  FileInput,
  ImageField,
  required,
  TextInput,
  TranslatableInputs
} from 'react-admin';


const CampaignHeaderBanner = ({ isFieldsRequired }) => {
  const validateField = isFieldsRequired ? [required()] : [];

  return (
    <>
      <Grid item xs={12}>
        <TranslatableInputs locales={AVAILABLE_LANGS} defaultLocale={DEFAULT_LANG}>
          <TextInput source="headerBannerTitleLocalizations" validate={validateField} fullWidth />
        </TranslatableInputs>
      </Grid>
      <Grid item xs={12}>
        <TranslatableInputs locales={AVAILABLE_LANGS} defaultLocale={DEFAULT_LANG}>
          <TextInput source="headerBannerSubtitleLocalizations" validate={validateField} fullWidth />
        </TranslatableInputs>  
      </Grid>
      <FileInput isRequired={isFieldsRequired} source="headerBannerBackgroundImage">
        <ImageField source="src" title="title" />
      </FileInput>
    </>
  );
}

export default CampaignHeaderBanner;