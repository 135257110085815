import { useForm } from "react-hook-form";
import {
  Form,
  useTranslate,
  useUpdate,
  useNotify,
  useRedirect,
  useRecordContext,
} from 'react-admin';
import {
  Stack,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import { useState } from "react";

const ResolveAdjustmentForm = ({ closeModal }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const [amountToAdjustToStudent, setAmountToAdjustToStudent] = useState(0);
  const [amountToAdjustToTeacher, setAmountToAdjustToTeacher] = useState(0);

  const { handleSubmit } = useForm({
    defaultValues: {
      amountToAdjustToStudent: 0,
      amountToAdjustToTeacher: 0
    }
  });

  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = () => {
    const payload = { id: record.id, data: {
      amountToAdjustToStudent: amountToAdjustToStudent,
      amountToAdjustToTeacher: amountToAdjustToTeacher
    }};

    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/movements');
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
    };

    update('movements/adjustment', payload, options);
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography align="center" variant="body1">
        {translate('resources.movements.form.adjustment.heading')}
      </Typography>
      <Stack>
        <Typography align="left">
          {translate('resources.movements.form.adjustment.purchasePrice')}: {record.lessonPrice}
        </Typography>
        <div className={`field field--time field--inline`}>
          <label htmlFor="amountToAdjustToStudent">{translate('resources.movements.form.adjustment.amountToAdjustToStudent')}</label>
          <input
            type="number"
            step="any"
            pattern='[0-9]+(\.[0-9]+)?'
            id="amountToAdjustToStudent"
            name="amountToAdjustToStudent"
            value={amountToAdjustToStudent}
            onChange={(e) => setAmountToAdjustToStudent(e.target.value)}
          />
        </div>
        <Typography align="left">
          {translate('resources.movements.form.adjustment.amountCollectedByTeacher')}: {record.amountCollectedByTeacher}
        </Typography>
        <div className={`field field--time field--inline`}>
          <label htmlFor="amountToAdjustToTeacher">{translate('resources.movements.form.adjustment.amountToAdjustToTeacher')}</label>
          <input
            type="number"
            step="any"
            pattern='[0-9]+(\.[0-9]+)?'
            id="amountToAdjustToTeacher"
            name="amountToAdjustToTeacher"
            value={amountToAdjustToTeacher}
            onChange={(e) => setAmountToAdjustToTeacher(e.target.value)}
          />
        </div>
      </Stack>
      <Grid container>
        <Grid item xs={6}>
          <Button onClick={closeModal}>
            {translate('resources.movements.buttons.cancel')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type="submit">
            {translate('resources.movements.buttons.adjustment')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ResolveAdjustmentForm;