import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const usePlatformGetUsersById = (ids) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getPlatformUsersById', ids], ({ queryKey }) => {
    const ids = queryKey[1];
    return (
      ids.length > 0 &&
      dataProvider.getPlatformUsersById(ids).then(({ data }) => data)
    );
  });

  return query;
};

export default usePlatformGetUsersById;
