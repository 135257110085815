import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherStatus = (teacherHash) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherStatus', teacherHash], () => {
    if (teacherHash) {
      return dataProvider.getTeacherStatus(teacherHash).then(({ data }) => data);
    }
  });

  return query;
};

export default useGetTeacherStatus;
