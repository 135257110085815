export const formatDuration = (duration) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const display = (value, unit) => (value === 0 ? '' : `${value}${unit}`);

  return `${display(hours, 'h')} ${display(minutes, 'm')}`.trim();
};

export const formatDateAndTime = (datetime) => {
  const [date, time] = datetime.split('T');
  return {
    date: formatDate(date),
    time: formatTime(time),
  };
};

export const formatDate = (date) => {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year.slice(-2)}`;
};

export const formatTime = (time) => {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

export const formatAndLocalizeDateTime = (dateString) => {
  if (!dateString) {
    return;
  }

  const formatted = formatAndLocalizeDateAndTime(dateString);
  return `${formatted.date} - ${formatted.time}`;
}

export const formatAndLocalizeDateAndTime = (datetime) => {
  const date = new Date(datetime);
  
  if (date instanceof Date && !isNaN(date)) {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      date: formatAndLocalizeDate(date, localTimeZone),
      time: formatAndLocalizeTime(date, localTimeZone),
    };
  }

  return {
    date: '',
    time: '',
  };
};

export const formatAndLocalizeDate = (date, timeZone, formatOptions) => {
  const defaultFormatOptions = {
    timeZone: timeZone,
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  if (!formatOptions) {
    formatOptions = defaultFormatOptions;
  }

  if (date instanceof Date && !isNaN(date)) {
    return date.toLocaleDateString('es-ES', formatOptions);
  }
  return '';
};

export const formatAndLocalizeTime = (date, timeZone) => {
  const formatOptions = {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  if (date instanceof Date && !isNaN(date)) {
    return date.toLocaleTimeString('es-ES', formatOptions)
  }
  return '';
};

export const formatAndLocalizeUTCDateTime = (dateString) => {
  if (!dateString) {
    return;
  }

  const formatted = formatAndLocalizeUTCDateAndTime(dateString);
  return `${formatted.date} - ${formatted.time}`;
}

export const formatAndLocalizeUTCDateAndTime = (datetime) => {
  const date = new Date(datetime);
  
  if (date instanceof Date && !isNaN(date)) {
    const localTimeZone = 'UTC';
    const formatOptions = {
      timeZone: localTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return {
      date: formatAndLocalizeDate(date, localTimeZone, formatOptions),
      time: formatAndLocalizeTime(date, localTimeZone, formatOptions),
    };
  }
  return {
    date: '',
    time: '',
  };
};

export const createDateFromDDMMYYYY = (dateString) => {
  if (dateString !== null) {
    const [day, month, year] = dateString.split('/').map(Number);
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, day);
    if (date) {
      return date;  
    }
  }
  return;
}

export const createDateFromDDMMYYYYHHMM = (dateString) => {
  if (dateString !== null) {
    const parts = dateString.split(/[ /:]/).map(Number);
    const day = parts[0];
    const month = parts[1] - 1;
    const year = parts[2];
    const hours = parts[3];
    const minutes = parts[4];
  
    const date = new Date(year, month, day, hours, minutes);
    if (date) {
      return date;  
    }
  }
  return;
}
