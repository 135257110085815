/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslate } from 'react-admin';
import { useController, useWatch } from 'react-hook-form';
import { Typography, FormGroup, FormControlLabel } from '@mui/material';
import { makeControlledCheckbox } from '../../components/checkbox';
import { useEffect } from 'react';

const userHashPrivilege = (user, privilege) => {
  return user.privileges.some(
    (userPrivilege) => privilege.id === userPrivilege.id
  );
};

const RoleCheckbox = ({ user, privilege, control }) => {
  const Checkbox = makeControlledCheckbox(({ value, onChange }) => {
    return {
      checked: value?.id === privilege?.id,
      onChange: () => onChange(privilege),
      disabled: user.privileges.length === 1,
    };
  });

  return (
    <FormControlLabel
      control={<Checkbox name="role" control={control} />}
      label={privilege.name}
    />
  );
};

const Role = ({ control }) => {
  const translate = useTranslate();
  const user = useWatch({ control, name: 'user' });
  const { field } = useController({ name: 'role', control });

  useEffect(() => {
    if (user && !userHashPrivilege(user, field.value))
      field.onChange(user.privileges[0]);
  }, [user]);

  return (
    <>
      <Typography variant="h6">
        {translate('resources.compensations.fields.role')}
      </Typography>
      {user && (
        <FormGroup row>
          {user.privileges
            .sort((a, b) => a.name > b.name)
            .map((privilege) => (
              <RoleCheckbox
                key={privilege.id}
                user={user}
                privilege={privilege}
                control={control}
              />
            ))}
        </FormGroup>
      )}
    </>
  );
};

export default Role;
