import { Create } from 'react-admin';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';
import IncidentTypeForm from './IncidentTypeForm';

const IncidentTypeCreate = () => {
  const notify = useDuplicateNotify();

  return (
    <Create mutationOptions={{ onError: notify }}>
      <IncidentTypeForm />
    </Create>
  );
};

export default IncidentTypeCreate;
