import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { CustomLoginPage } from './auth/LoginPage';
import CompensationCreate from './compensations/CompensationCreate';
import CompensationEdit from './compensations/CompensationEdit';
import CompensationList from './compensations/CompensationList';
import { CustomLayout } from './components/general/Layout';
import AccountingAccountCreate from './dashboard/accountingAccounts/AccountingAccountCreate';
import AccountingAccountList from './dashboard/accountingAccounts/AccountingAccountList';
import ClientTypeCreate from './dashboard/clientTypes/ClientTypeCreate';
import ClientTypeEdit from './dashboard/clientTypes/ClientTypeEdit';
import ClientTypeList from './dashboard/clientTypes/ClientTypeList';
import IncidentList from './dashboard/incidents/IncidentList';
import IncidentShow from './dashboard/incidents/IncidentShow';
import IncidentTypeCreate from './dashboard/incidentTypes/IncidentTypeCreate';
import IncidentTypeEdit from './dashboard/incidentTypes/IncidentTypeEdit';
import IncidentTypeList from './dashboard/incidentTypes/IncidentTypeList';
import LanguageExamCreate from './dashboard/languageExams/LanguageExamCreate';
import LanguageExamEdit from './dashboard/languageExams/LanguageExamEdit';
import LanguageLevelCreate from './dashboard/languageLevels/LanguageLevelCreate';
import LanguageLevelEdit from './dashboard/languageLevels/LanguageLevelEdit';
import OfferedLanguagesManagement from './dashboard/offeredLanguages/OfferedLanguagesManagement';
import PlatformUserTeacherApplicationsList from './dashboard/platformUsers/form/teacherApplication/PlatformUserTeacherApplicationsList';
import PlatformUserEdit from './dashboard/platformUsers/PlatformUserEdit';
import PlatformUserList from './dashboard/platformUsers/PlatformUserList';
import ProductCreate from './dashboard/products/ProductCreate';
import ProductEdit from './dashboard/products/ProductEdit';
import ProductList from './dashboard/products/ProductList';
import IncidentResponseTypeCreate from './dashboard/incidentResponseTypes/IncidentResponseTypeCreate';
import IncidentResponseTypeEdit from './dashboard/incidentResponseTypes/IncidentResponseTypeEdit';
import IncidentResponseTypeList from './dashboard/incidentResponseTypes/IncidentResponseTypeList';
import RoleCreate from './dashboard/roles/RoleCreate';
import RoleEdit from './dashboard/roles/RoleEdit';
import RoleList from './dashboard/roles/RoleList';
import SectorCreate from './dashboard/sectors/SectorCreate';
import SectorEdit from './dashboard/sectors/SectorEdit';
import SectorList from './dashboard/sectors/SectorList';
import TeacherApplicationShow from './dashboard/teacherApplications/TeacherApplicationShow';
import TeacherApplicationList from './dashboard/teacherApplications/TeacherApplicationsList';
import TeacherApplicationEdit from './dashboard/teacherApplications/TeacherApplicationEdit';
import TeacherCharacteristicCreate from './dashboard/teacherCharacteristic/TeacherCharacteristicCreate';
import TeacherCharacteristicEdit from './dashboard/teacherCharacteristic/TeacherCharacteristicEdit';
import TeacherBalanceSummaryList from './dashboard/teacherBalanceSummary/TeacherBalanceSummaryList';
import { UserCreate } from './dashboard/users/UserCreate';
import { UserEdit } from './dashboard/users/UserEdit';
import { UserList } from './dashboard/users/UserList';
import AccountParameters from './parameters/AccountParameters';
import CashingParameters from './parameters/CashingParameters';
import ClassParameters from './parameters/ClassParameters';
import CreditParameters from './parameters/CreditParameters';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import { i18nProvider } from './providers/i18nProvider';
import { theme } from './style/theme';
import MovementList from 'dashboard/movements/MovementList';
import MovementShow from 'dashboard/movements/MovementShow';
import CampaignList from 'dashboard/campaigns/CampaignList';
import CampaignEdit from 'dashboard/campaigns/CampaignEdit';
import CampaignCreate from 'dashboard/campaigns/CampaignCreate';

const apiUrl = process.env.REACT_APP_API_URL;
const auth = authProvider(apiUrl);
const isProduction = process.env.REACT_APP_ENV === 'prod';

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={auth}
    theme={theme}
    i18nProvider={i18nProvider}
    loginPage={CustomLoginPage}
    layout={CustomLayout}
  >
    {!isProduction && (
      <CustomRoutes>
        <Route path='/class_parameters' element={<ClassParameters />} />
        <Route path='/account_parameters' element={<AccountParameters />} />
        <Route path='/credit_parameters' element={<CreditParameters />} />
        <Route path='/cashing_parameters' element={<CashingParameters />} />
      </CustomRoutes>
    )}
    {!isProduction && (
      <Resource
        name='offered_languages'
        list={OfferedLanguagesManagement}
      />
    )}
    <Resource
      name='language_levels'
      edit={LanguageLevelEdit}
      create={LanguageLevelCreate}
    />
    <Resource
      name='language_exams'
      edit={LanguageExamEdit}
      create={LanguageExamCreate}
    />
    '
    <Resource
      name='teacher_characteristics'
      edit={TeacherCharacteristicEdit}
      create={TeacherCharacteristicCreate}
    />
    <Resource
      name='teacher_applications'
      list={TeacherApplicationList}
      show={TeacherApplicationShow}
      edit={TeacherApplicationEdit}
    />
    <Resource
      name='platform_user_teacher_application'
      list={PlatformUserTeacherApplicationsList}
    />
    <Resource
      name='teacher_balance_summary'
      list={TeacherBalanceSummaryList}
    />
    <Resource
      name='movements'
      list={MovementList}
      show={MovementShow}
    />
    <Resource
      name='products'
      list={ProductList}
      edit={ProductEdit}
      create={ProductCreate}
    />
    <Resource
      name='campaigns'
      list={CampaignList}
      edit={CampaignEdit}
      create={CampaignCreate}
    />
    <Resource
      name='platform_users'
      list={PlatformUserList}
      edit={PlatformUserEdit}
    />
    {!isProduction && (
      <Resource
        name='accounting_accounts'
        list={AccountingAccountList}
        create={AccountingAccountCreate}
      />
    )}
    <Resource
      name='client_types'
      list={ClientTypeList}
      create={ClientTypeCreate}
      edit={ClientTypeEdit}
    />
    <Resource
      name='incidents'
      list={IncidentList}
      show={IncidentShow}
    />
    <Resource
      name='compensations'
      list={CompensationList}
      edit={CompensationEdit}
      create={CompensationCreate}
    />
    <Resource
      name='incident_types'
      list={IncidentTypeList}
      edit={IncidentTypeEdit}
      create={IncidentTypeCreate}
    />
    <Resource
      name='incident_response_types'
      list={IncidentResponseTypeList}
      edit={IncidentResponseTypeEdit}
      create={IncidentResponseTypeCreate}
    />
    <Resource
      name='users'
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
    />
    <Resource
      name='roles'
      list={RoleList}
      edit={RoleEdit}
      create={RoleCreate}
    />
    {!isProduction && (
      <Resource
        name='sectors'
        list={SectorList}
        edit={SectorEdit}
        create={SectorCreate}
      />
    )}
  </Admin>
);

export default App;
