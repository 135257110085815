import { fetchUtils } from 'react-admin';
import jwtManager from './jwtManager';

const httpClient = async (url, options) => {
  if (!options) {
    options = {};
  }

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  let token = jwtManager.getToken();
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  try {
    const response = await fetchUtils.fetchJson(url, options);

    return response;
  } catch (e) {
    if (e.status === 401) {
      await jwtManager.getRefreshedToken();
      token = jwtManager.getToken();
      options.headers.set('Authorization', `Bearer ${token}`);

      return fetchUtils.fetchJson(url, options);
    } else {
      throw e;
    }
  }
};

export default httpClient;
