import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionField, ReferenceField, TextField, useTranslate } from 'react-admin';
import { formatAndLocalizeDateAndTime, formatAndLocalizeDateTime } from 'utils/format';
import Detail from "./Detail";
import LessonIncidents from './LessonIncidents';

const LeftGridItem = styled((props) => (
  <Grid item xs={4} {...props}>
    {props.children}
  </Grid>
))`
  text-align: left;
  font-weight: bold;
`;

const RightGridItem = styled((props) => (
  <Grid item xs={4} {...props}>
    {props.children}
  </Grid>
))`
  text-align: right;
  font-weight: bold;
`;

const Row = ({ source, render, label, reference, referenceLabel }) => {
  const translate = useTranslate();
  const fieldComponent = reference
    ? (
      <ReferenceField source={source} reference={reference} link={false}>
        <TextField source={referenceLabel} />
      </ReferenceField>
    )
    : render
      ? <FunctionField render={render} />
      : <TextField source={source} />
    ;

  return (
    <Grid container item xs={10}>
      <LeftGridItem>
        {(label && label !== "") ? translate(label) : translate(`resources.movements.fields.${source}`)}
      </LeftGridItem>
      <RightGridItem>{fieldComponent}</RightGridItem>
    </Grid>
  );
};

const Movement = () => {
  return (
    <>
      <Detail 
        rows={[
          <Row key="lessonReference" source="lessonReference" label="resources.movements.fields.lesson" />,
          <Row key="lessonDateTime" source="lessonDateTime" render={({ lessonDatetime }) => formatAndLocalizeDateTime(lessonDatetime) } />,
          <Row key="teacher" source="teacher.completeName" label="resources.movements.fields.teacher" />,
          <Row key="student" source="student.completeName" label="resources.movements.fields.student" />,
          <Row key="lessonPrice" source="lessonPrice" label="resources.movements.fields.lessonPrice" />,
          <Row key="amountCollectedByTeacher" source="amountCollectedByTeacher" label="resources.movements.fields.amountCollectedByTeacher" />,
          <Row key="lessonTeacherJoinedAt" source="lessonTeacherJoinedAt" render={({ lessonTeacherJoinedAt }) => lessonTeacherJoinedAt ? formatAndLocalizeDateAndTime(lessonTeacherJoinedAt).time : '-' } />,
          <Row key="lessonStudentJoinedAt" source="lessonStudentJoinedAt" render={({ lessonStudentJoinedAt }) => lessonStudentJoinedAt ? formatAndLocalizeDateAndTime(lessonStudentJoinedAt).time : '-'} />,
          <LessonIncidents key="lessonIncidents" />
        ]}
      />
    </>
  );
};

export default Movement;