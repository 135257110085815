import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetParameters = (resource) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getParameters', resource], () =>
    dataProvider.getParameters(resource).then(({ data }) => data)
  );

  return query;
};

export default useGetParameters;
