import { useState } from 'react';
import { DateTime } from 'luxon';

const firstWeek = 0;
const lastWeek = DateTime.local().weeksInWeekYear - 1;
const currentWeek = DateTime.local().weekNumber;

const useWeek = (initial = currentWeek) => {
  const [week, setWeek] = useState(initial);

  const nextWeek = () => setWeek(Math.min(lastWeek, week + 1));
  const prevWeek = () => setWeek(Math.max(firstWeek, week - 1));

  return [week, nextWeek, prevWeek];
};

export default useWeek;
