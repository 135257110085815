import { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { TextField, Autocomplete } from '@mui/material';

const User = ({ users, control }) => {
  const translate = useTranslate();
  const {
    field: { onChange, ...field },
  } = useController({ name: 'user', control });

  useEffect(() => { }, [users]);

  return (
    <Autocomplete
      disablePortal
      options={users ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, value) => onChange(value)}
      getOptionLabel={({ completeName }) => completeName?.trim() ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate('resources.compensations.fields.user')}
        />
      )}
      {...field}
    />
  );
};

export default User;
