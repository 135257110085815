import { useRecordContext } from 'react-admin';
import { useGetPlatformUsersById } from 'hooks/api';
import { compose } from 'utils/func';
import { getLessonId, mapLesson, replaceUserIdWithUserName } from './utils';

export const usePendingLessons = (user, findNameOf) => {
  const record = useRecordContext();
  const pendingLessons = record?.[`${user}Lessons`] ?? [];
  const userIds = pendingLessons.map(getLessonId(findNameOf));

  const { data: users } = useGetPlatformUsersById(userIds);

  return users
    ? pendingLessons.map(
        compose(replaceUserIdWithUserName(users), mapLesson(findNameOf, users))
      )
    : pendingLessons.map(mapLesson(findNameOf));
};
