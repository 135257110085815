import { FunctionField, useTranslate } from 'react-admin';
import Detail from './Detail';

const GivenSolution = () => {
  const translate = useTranslate();
  return (
    <>
      <Detail
        heading={translate('resources.incidents.fields.givenSolution')}
        rows={[
          <FunctionField
            render={({ resolved, responseType }) =>
              resolved ? responseType.name : translate('resources.incidents.fields.unresolved')
            }
          />
        ]}
      />
      <FunctionField
        render={({ resolved, responseText }) =>
          resolved ? responseText : null
        }
      />
    </>
  );
};

export default GivenSolution;
