import { useTranslate } from 'react-admin';
import { TableHead, TableRow, TableCell } from '@mui/material';

const WeekDays = () => {
  const translate = useTranslate();
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>{translate('days.monday')}</TableCell>
        <TableCell>{translate('days.tuesday')}</TableCell>
        <TableCell>{translate('days.wednesday')}</TableCell>
        <TableCell>{translate('days.thursday')}</TableCell>
        <TableCell>{translate('days.friday')}</TableCell>
        <TableCell>{translate('days.saturday')}</TableCell>
        <TableCell>{translate('days.sunday')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default WeekDays;
