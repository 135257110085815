import { List, Datagrid, TextField, DeleteButton } from 'react-admin';
import {Typography} from "@mui/material";
import {TrashIcon} from "../../icons/CustomIcons";

const IncidentResponseTypeList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="name" />
          <Typography align='right'>
              <DeleteButton icon={<TrashIcon/>}/>
          </Typography>
      </Datagrid>
    </List>
  );
};

export default IncidentResponseTypeList;
