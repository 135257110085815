/* eslint-disable react-hooks/exhaustive-deps */
import { Switch, Typography } from '@mui/material';
import { useGetPlatformUserStatus, useLoading, useTranslation } from 'hooks';
import { useDataProvider, useNotify, useRecordContext } from 'react-admin';
import { formatAndLocalizeDateAndTime } from 'utils/format';
import Card from '../Card';
import Field from '../Field';
import SaveButton from '../SaveButton';
import { useStatus } from './hooks';

const i18nPath = 'resources.platform_users.tabs.teacherData.status';

const Cancelled = ({ date, cancelled, switchStatus }) => (
  <Field name={`${useTranslation(i18nPath, 'cancelled')} ${date}`}>
    <Switch checked={cancelled} onChange={switchStatus} />
  </Field>
);

const CancelledBy = ({ cancelledBy }) => {
  const name = useTranslation(i18nPath, 'cancelledBy');

  return (
    cancelledBy && (
      <Field name={name}>
        <Typography variant='p'>{cancelledBy.fullName}</Typography>
      </Field>
    )
  );
};

const Status = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const query = useGetPlatformUserStatus(record?.id);
  const { status, setStatus, switchStatus } = useStatus(query.data);
  const [loading, withLoad] = useLoading();

  const notify = useNotify();
  const handleSubmit = (e) => {
    e.preventDefault();

    withLoad(async () => {
      const { data } = await dataProvider.updatePlatformUserStatus(record.id, {
        cancelledBy: status?.cancelledBy?.id,
      });

      setStatus((prev) => ({ ...prev, cancelledAt: data.cancelledAt }));
      notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    });
  };

  const date = status?.cancelledAt
    ? formatAndLocalizeDateAndTime(status.cancelledAt).date
    : '';

  return (
    <form onSubmit={handleSubmit}>
      <Card
        title={useTranslation(i18nPath, 'title')}
        icon={<SaveButton loading={loading} />}
      >
        <Cancelled
          cancelled={!!status?.cancelledBy}
          date={date}
          switchStatus={switchStatus}
        />
        <CancelledBy cancelledBy={status?.cancelledBy} />
      </Card>
    </form>
  );
};

export default Status;
