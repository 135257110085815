import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)`
  border: 1px solid #e6ebf1;
  padding: 2px;
  max-width: 800px;
  margin-bottom: 20px;
`;

const Header = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  padding: 20px 20px;
  border-bottom: 1px solid #e6ebf1;
  display: flex;
  justify-content: space-between;
`;

const Card = ({ title, icon, children, ...props }) => (
  <StyledBox {...props}>
    <Header>
      <Typography variant='h5' color='#222' fontWeight='bold'>
        {title}
      </Typography>
      {icon}
    </Header>
    {children}
  </StyledBox>
);

export default Card;
