import useTranslation from 'hooks/useTranslation';
import Card from '../../Card';
import LessonCount from './LessonCount';
import PendingLessons from './PendingLessons';
import { usePendingLessons } from './hooks';

const i18nPath = 'resources.platform_users.tabs.generalData.pendingLessons.lessonsAsStudent';

const StudentPendingLessons = () => {
  const lessons = usePendingLessons('student', 'teacher');

  return (
    <Card
      title={useTranslation(i18nPath, 'title')}
      icon={<LessonCount lessons={lessons} />}
    >
      <PendingLessons column='teacher' lessons={lessons} />
    </Card>
  );
};

export default StudentPendingLessons;
