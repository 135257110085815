import {
  ListContextProvider,
  Datagrid,
  Pagination,
  TextField,
  useList
} from 'react-admin';
import { formatAndLocalizeDateAndTime } from 'utils/format';

const i18nPath = 'resources.platform_users.tabs.generalData.pendingLessons';

const LessonPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const PendingLessons = ({ column, lessons = [] }) => {

  const data = lessons.map(lesson => (
    {
      createdAt: formatAndLocalizeDateAndTime(lesson.createdAt).date + ' ' + formatAndLocalizeDateAndTime(lesson.createdAt).time,
      reference: lesson.reference,
      status: lesson.status,
      user: lesson.user,
      date: lesson.date ? 
        formatAndLocalizeDateAndTime(lesson.date).date + ' ' + formatAndLocalizeDateAndTime(lesson.date).time
        : '-'
    }
  ));

  const listContext = useList({ 
    data,
    resource: 'lessons',
    total: data.length,
    perPage: 10,
  });

  if (lessons.length === 0) {
    return <></>;
  }

  return (
    <>
      <ListContextProvider value={listContext}>
        <Datagrid 
          bulkActionButtons={false} size="small"
          sx={{
            '& .RaDatagrid-headerCell': {
              width: '158px',
            },
            '& .RaDatagrid-rowCell': {
              width: '158px',
              minHeight: '60px'
            }
          }}
        >
          <TextField source="createdAt" label={`${i18nPath}.createdAt`} />
          <TextField source="reference" label={`${i18nPath}.reference`} />
          <TextField source="status" label={`${i18nPath}.status`} />
          <TextField source="user" label={`${i18nPath}.${column}`} />
          <TextField source="date" label={`${i18nPath}.date`} />
        </Datagrid>
        <LessonPagination />
      </ListContextProvider>
    </>
    
  );
};

export default PendingLessons;
