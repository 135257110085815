import {Typography} from "@mui/material";
import {AppBar, LocalesMenuButton, Toolbar} from "react-admin";
import {LANGS} from "../../providers/i18nProvider";

export const CustomAppBar = () => (
    <AppBar>
        <Toolbar>
            <img src="/assets/images/provisional-logo-dark.svg" alt="logo" width={90}/>
        </Toolbar>
        <Typography flex='1' variant='h6' id='react-admin-title'/>
        <img src="" alt=""/>
        <LocalesMenuButton
            languages={LANGS.map(({code, label}) => ({
                locale: code,
                name: label,
            }))}
        />
    </AppBar>
);
