import { Edit, useRecordContext, useTranslate, useUpdate } from "react-admin";
import TeacherApplicationForm from "./TeacherApplicationForm";

const TeacherApplicationTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.teacher_applications.name');

  return `${label}: ${record.firstName} ${record.lastName}`;
};

const FormWrapper = () => {
  const [update] = useUpdate();

  return (
    <TeacherApplicationForm notification={'ra.notification.updated'} saveFn={update} />
  );
};

const TeacherApplicationEdit = () => {
  return (
    <Edit title={<TeacherApplicationTitle />}>
      <FormWrapper />
    </Edit>
  );
};
export default TeacherApplicationEdit;