import { undoableEventEmitter, useDataProvider, useNotify } from 'react-admin';
import { useQueryClient, useMutation } from 'react-query';

const useUpdateParameters = (resource) => {
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const { mutate } = useMutation((parameters) =>
    dataProvider.updateParameters(resource, parameters)
  );

  const notify = useNotify();

  const update = (parameters) => {
    notify('ra.notification.updated', {
      undoable: true,
      messageArgs: { smart_count: 1 },
    });
    undoableEventEmitter.once('end', ({ isUndo }) => {
      if (!isUndo) {
        mutate(parameters);
        queryClient.setQueryData(['getParameters', resource], (old) => {
          return parameters.map((param, i) => ({
            ...param,
            key: old[i].key,
          }));
        });
      }
    });
  };

  return update;
};

export default useUpdateParameters;
