import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherCurriculum = (id) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherCurriculum', id], () => {
    if (id)
      return dataProvider.getTeacherCurriculum(id).then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherCurriculum;
