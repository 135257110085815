import { TextField } from '@mui/material';
import { useTranslation } from 'hooks';
import Field from '../../Field';

const i18nPath = 'resources.platform_users.tabs.teacherData.video';

const UrlInput = ({ url, updateUrl }) => {
  return (
    <Field name={useTranslation(i18nPath, 'url')}>
      <TextField
        label={false}
        value={url}
        onChange={(e) => updateUrl(e.target.value)}
      />
    </Field>
  );
};

export default UrlInput;
