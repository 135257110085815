import { Grid, FormControlLabel, Tabs, Tab } from '@mui/material';
import StyledCheckbox from "./StyledCheckbox";
import { useTranslate } from 'react-admin';
import { useController, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import CampaignHeaderBanner from './CampaignHeaderBanner';
import CampaignPopup from './CampaignPopup';

const TabPanel = ({ children, value, index, ...props }) => (
  <div hidden={value !== index} {...props}>
    {children}
  </div>
);

const CampaignPromotionalData = () => {
  const translate = useTranslate();

  const { control } = useFormContext();

  const [tab, setTab] = useState(0);
  const handleTabChange = (_, newTab) => setTab(newTab);

  const {
    field: { value: showBannerPopup, onChange: onShowBannerPopupChange },
  } = useController({ name: 'showBannerPopup', control });

  const setShowBannerPopup = () => {
    onShowBannerPopupChange(!showBannerPopup);
  };

  const initializeShowBannerPopup = () => {
    onShowBannerPopupChange(false);
  };

  if (showBannerPopup === undefined) {
    initializeShowBannerPopup();
  }

  return (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <StyledCheckbox checked={showBannerPopup} onChange={setShowBannerPopup} />
          }
          label={translate('resources.campaigns.fields.showBannerPopup')}
        />  
      </Grid>
      {showBannerPopup && (
        <>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label={translate('resources.campaigns.tabs.headerBanner')}></Tab>
            <Tab label={translate('resources.campaigns.tabs.popup')}></Tab>
          </Tabs>
          <TabPanel value={tab} index={0}>
            <CampaignHeaderBanner isFieldsRequired={showBannerPopup} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <CampaignPopup isFieldsRequired={showBannerPopup} />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default CampaignPromotionalData;
