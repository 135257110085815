import { Create, useCreate, useGetList } from 'react-admin';
import RoleForm from './RoleForm';
import { HIDE } from './constants';

const RoleCreate = () => {
  const [create] = useCreate();
  const { data } = useGetList('permissions', {
    sort: { field: 'name', order: 'ASC' },
  });
  const permissions = data?.map((permission) => ({
    ...permission,
    option: HIDE,
  }));

  return (
    <Create>
      <RoleForm
        notification={'ra.notification.created'}
        permissions={permissions ?? []}
        saveFn={create}
      />
    </Create>
  );
};

export default RoleCreate;
