import { undoableEventEmitter, useDataProvider, useNotify } from 'react-admin';
import { useMutation } from 'react-query';

const useCreateLanguage = () => {
  const dataProvider = useDataProvider();
  const { mutate } = useMutation((lang) => dataProvider.postLanguage(lang));

  const notify = useNotify();
  const create = (lang, { onUndo, ...options }) => {
    notify('ra.notification.created', {
      undoable: true,
      messageArgs: { smart_count: 1 },
    });

    undoableEventEmitter.once('end', ({ isUndo }) => {
      if (!isUndo) {
        mutate(lang, options);
      } else {
        onUndo && onUndo(lang);
      }
    });
  };

  return create;
};

export default useCreateLanguage;
