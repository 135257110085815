import { Edit, useRecordContext, useTranslate, useUpdate } from 'react-admin';
import RoleForm from './RoleForm';

const ProductTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.roles.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const FormWrapper = () => {
  const { permissions } = useRecordContext();
  const [update] = useUpdate();
  return (
    <RoleForm
      notification={'ra.notification.updated'}
      permissions={permissions}
      saveFn={update}
    />
  );
};

const RoleEdit = () => (
  <Edit title={<ProductTitle />}>
    <FormWrapper />
  </Edit>
);

export default RoleEdit;
