import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { Stack } from '@mui/material';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';

const ClientTypeEdit = () => {
  const notify = useDuplicateNotify();

  return (
    <Edit mutationOptions={{ onError: notify }}>
      <SimpleForm>
        <Stack direction='row' spacing={2} alignItems='center'>
          <TextInput source='name' validate={[required()]} />
          <ReferenceArrayInput
            source='sectors'
            reference='sectors'
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectArrayInput
              label='resources.client_types.fields.sectors'
              optionText='name'
            />
          </ReferenceArrayInput>
        </Stack>
      </SimpleForm>
    </Edit>
  );
};

export default ClientTypeEdit;
