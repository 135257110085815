import { Create, useCreate } from 'react-admin';
import CampaignForm from './CampaignForm';

const CampaignCreate = () => {
  const [create] = useCreate();
  return(
    <Create>
      <CampaignForm
        notification={'ra.notification.created'}
        saveFn={create}
      />
    </Create>
  );
};

export default CampaignCreate;