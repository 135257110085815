import { useState } from 'react';

const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const withLoad = (promise) => {
    setLoading(true);
    return promise().then(() => setLoading(false));
  };

  return [loading, withLoad];
};

export default useLoading;
