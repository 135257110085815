import { Typography } from "@mui/material";
import { useMarkTeacherBalanceSummaryAsSent } from 'hooks';
import { getXML } from 'providers/dataProviders/utils';
import { useState } from 'react';
import {
  BooleanField,
  Button,
  Confirm,
  Datagrid,
  FunctionField,
  List,
  TextField,
  useRecordContext
} from 'react-admin';
import { formatAndLocalizeDateAndTime } from 'utils/format';

const i18nPath = 'resources.teacher_balance_summary';

const DownloadButton = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  const handleClick = async () => {
    getXML(`teacher_balance_summary/${record?.id}/xml`)
      .then((response) => response.body)
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `teacher_balance_summary_${record?.id}_${record?.date}.xml`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <Button
      label={`${i18nPath}.buttons.download`}
      onClick={handleClick}
    />
  );
}

const MarkAsSentButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  const [markAsSent, isLoading] = useMarkTeacherBalanceSummaryAsSent();

  if (!record) return null;

  const handleClick = () => setOpen(true);

  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    markAsSent(record?.id);
    setOpen(false);
  };

  return (
    <>
      <Button
        color='primary'
        label={`${i18nPath}.buttons.markAsSent`}
        onClick={handleClick}
        disabled={record?.sent}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`${i18nPath}.confirmMarkAsSentTitle`}
        content={`${i18nPath}.confirmMarkAsSentContent`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
}

const TeacherBalanceSummaryList = () => {
  return (
    <List
      resource='teacher_balance_summary'
      storeKey="teacherBalanceSummary"
      filters={null}
      exporter={false}
    >
      <Datagrid>
        <TextField source="transactionCode" />
        <FunctionField
          source="date"
          sortBy="date"
          render={({ date }) => formatAndLocalizeDateAndTime(date).date}
        />
        <BooleanField source="downloaded" />
        <BooleanField source="sent" />
        <Typography align='right'>
          <DownloadButton />
          <MarkAsSentButton />
        </Typography>
      </Datagrid>
    </List>
  );
};

export default TeacherBalanceSummaryList;
