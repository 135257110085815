import {
    Datagrid,
    List,
    FunctionField,
    DeleteButton,
    BooleanField
  } from 'react-admin';
import { formatAndLocalizeDateTime, formatAndLocalizeUTCDateTime } from '../../utils/format';
import { Typography } from '@mui/material';
import { TrashIcon } from '../../icons/CustomIcons';
import { DEFAULT_LANG } from 'core/constants';

const CampaignList = () => {
  const getDefaultNameLocalization = (nameLocalizations) => nameLocalizations[DEFAULT_LANG];

  return (
    <List>
      <Datagrid rowClick="edit">
        <FunctionField
          source="createdAt"
          sortBy="createdAt"
          render={({ createdAt }) => formatAndLocalizeDateTime(createdAt)}
        />
        <FunctionField
          source="nameLocalizations"
          sortable={false}
          label="resources.campaigns.list.name"
          render={({ nameLocalizations }) => getDefaultNameLocalization(nameLocalizations)}
        />
        <FunctionField
          source='discount'
          label="resources.campaigns.list.discount"
          render={({ discount }) => discount}
        />
        <FunctionField
          source="startDate"
          label="resources.campaigns.list.startDate"
          render={({ startDate }) => formatAndLocalizeUTCDateTime(startDate)}
        />
        <FunctionField
          source="endDate"
          label="resources.campaigns.list.endDate"
          render={({ endDate }) => formatAndLocalizeUTCDateTime(endDate)}
        />
        <BooleanField
          source='useUserTimezone'
          label="resources.campaigns.list.useUserTimezone"
        />
        <FunctionField
          source="couponCode"
          label="resources.campaigns.list.couponCode"
          render={({ couponCode }) => couponCode}
        />
        <FunctionField
          source="maxCoupons"
          label="resources.campaigns.list.maxCoupons"
          render={({ maxCoupons }) => maxCoupons}
        />
        <FunctionField
          source="usedCoupons"
          label="resources.campaigns.list.usedCoupons"
          render={({ usedCoupons }) => usedCoupons}
        />
        <BooleanField
          source='oncePerUser'
          label="resources.campaigns.list.oncePerUser"
        />
        <BooleanField
          source='allowTrialLesson'
          label="resources.campaigns.list.allowTrialLesson"
        />
        <BooleanField
          source='allowWithPreviousPurchase'
          label="resources.campaigns.list.allowWithPreviousPurchase"
        />
        <Typography align="right">
          <DeleteButton icon={<TrashIcon />} />
        </Typography>
      </Datagrid>
    </List>
  );
};

export default CampaignList;