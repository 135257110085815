import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherAvailability = (id) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherAvailability', id], () => {
    if (id)
      return dataProvider.getTeacherAvailability(id).then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherAvailability;
