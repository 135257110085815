import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate
} from "react-admin";

const TeacherCharacteristicTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.teacher_characteristics.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const TeacherCharacteristicEdit = () => (
  <Edit title={<TeacherCharacteristicTitle />}>
    <SimpleForm>
      <TextInput source='name' validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export default TeacherCharacteristicEdit;
