import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherSpokenLanguages = (teacherId) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherSpokenLanguages', teacherId], () => {    
    if (teacherId)
      return dataProvider
        .getTeacherSpokenLanguages(teacherId)
        .then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherSpokenLanguages;
