import {
  AutocompleteInput,
  Datagrid,
  FunctionField,
  List,
  NumberInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useTranslate,
  downloadCSV,
} from 'react-admin';
import { formatAndLocalizeDateTime } from 'utils/format';
import jsonExport from 'jsonexport/dist';

const filters = [
  <ReferenceInput
    source='type'
    reference='movement_types'
    sort={{ field: 'label', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteInput optionText='label' optionValue='id' />
  </ReferenceInput>,
  <ReferenceInput
    source='teacher'
    reference='platform_users'
    sort={{ field: 'completeName', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteInput optionText='completeName' optionValue='id' />
  </ReferenceInput>,
  <ReferenceInput
    source='student'
    reference='platform_users'
    sort={{ field: 'completeName', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteInput optionText='completeName' optionValue='id' />
  </ReferenceInput>,

  <NumberInput source='lesson' alwaysOn />,
];

const MovementList = () => {
  const postRowClick = (id, resource, record) => record.type.id === 'GIVE_LESSON' ? 'show' : 'edit';

  const translate = useTranslate();

  const formatCampaign = (movement) => (movement.campaign != null && movement.purchaseCampaignDiscount != null)
    ? `${movement.campaign.name} (${movement.campaign.discount}%) - ${movement.purchaseCampaignDiscount}`
    : '-';

  const exporter = movements => {
    const createdAt = translate('resources.movements.fields.createdAt');
    const type = translate('resources.movements.fields.type');
    const productName = translate('resources.movements.fields.productName');
    const actor = translate('resources.movements.fields.actor');
    const lesson = translate('resources.movements.fields.lesson');
    const lessonAmount = translate('resources.movements.fields.lessonAmount');
    const lessonDateTime = translate('resources.movements.fields.lessonDateTime');
    const teacher = translate('resources.movements.fields.teacher');
    const student = translate('resources.movements.fields.student');
    const stripe = translate('resources.movements.fields.stripe');
    const purchasePrice = translate('resources.movements.fields.purchasePrice');
    const purchaseCampaignDiscount = translate('resources.movements.fields.purchaseCampaignDiscount');
    const teacherAdjustment = translate('resources.movements.fields.teacherAdjustment');
    const amountCollectedByTeacher = translate('resources.movements.fields.amountCollectedByTeacher');
    const purchaseCommissionApplied = translate('resources.movements.fields.purchaseCommissionApplied');
    const teacherServiceFee = translate('resources.movements.fields.teacherServiceFee');
    const studentAdjustment = translate('resources.movements.fields.studentAdjustment');
    const generatedCredits = translate('resources.movements.fields.generatedCredits');
    const usedCredits = translate('resources.movements.fields.usedCredits');
    const availableCredits = translate('resources.movements.fields.availableCredits');
    const withdrawnBalance = translate('resources.movements.fields.withdrawnBalance');
    const totalBalance = translate('resources.movements.fields.totalBalance');
    const retainedBalance = translate('resources.movements.fields.retainedBalance');

    const movementsForExport = movements.map(movement => {
      const movementsForExport = {
        [createdAt]: formatAndLocalizeDateTime(movement.createdAt),
        [type]: movement.type.label,
        [productName]: movement.productName ?? '',
        [actor]: movement.actor.completeName,
        [lesson]: movement.lessonReference ?? '',
        [lessonAmount]: movement.lessonAmount,
        [lessonDateTime]: formatAndLocalizeDateTime(movement.lessonDatetime),
        [teacher]: movement.teacher.completeName,
        [student]: movement.student.completeName,
        [stripe]: movement.paymentIntentId,
        [purchasePrice]: movement.purchasePrice ?? 0,
        [purchaseCampaignDiscount]: formatCampaign(movement),
        [teacherAdjustment]: movement.teacherAdjustment ?? 0,
        [amountCollectedByTeacher]: movement.amountCollectedByTeacher ?? 0,
        [purchaseCommissionApplied]: movement.purchaseCommissionApplied ?? 0,
        [teacherServiceFee]: movement.teacherServiceFee ?? 0,
        [studentAdjustment]: movement.studentAdjustment ?? 0,
        [generatedCredits]: movement.generatedCredits ?? 0,
        [usedCredits]: movement.usedCredits ?? 0,
        [availableCredits]: movement.availableCredits ?? 0,
        [withdrawnBalance]: movement.withdrawnBalance ?? 0,
        [totalBalance]: movement.totalBalance ?? 0,
        [retainedBalance]: movement.retainedBalance ?? 0
      };
      return movementsForExport;
    });
    jsonExport(movementsForExport, {
      textDelimiter: '"',
      headers: [
        createdAt,
        type,
        productName,
        actor,
        lesson,
        lessonAmount,
        lessonDateTime,
        teacher,
        student,
        stripe,
        purchasePrice,
        purchaseCampaignDiscount,
        teacherAdjustment,
        amountCollectedByTeacher,
        purchaseCommissionApplied,
        teacherServiceFee,
        studentAdjustment,
        generatedCredits,
        usedCredits,
        availableCredits,
        withdrawnBalance,
        totalBalance,
        retainedBalance
      ] // order fields in the export
    }, (err, csv) => {
      downloadCSV(csv, 'movements'); // download as 'movements.csv` file
    });
  };

  return (
    <List filters={filters} exporter={exporter}>
      <Datagrid
        rowClick={ postRowClick }
        sx={{
          display: 'flex',
          '& .RaDatagrid-tableWrapper': {
            width: 'calc(100vw - 260px)',
            overflow: 'auto',
            flex: '1 auto'
          }
        }}>
        <FunctionField
          source="createdAt"
          sortBy="createdAt"
          render={({ createdAt }) => formatAndLocalizeDateTime(createdAt)}
          label="resources.movements.fields.createdAt"
        />
        <FunctionField
          source='type'
          render={record => record.type.label}
          label="resources.movements.fields.type"
        />
        <FunctionField
          source='productName'
          render={record => record.productName}
          label="resources.movements.fields.productName"
        />
        <FunctionField
          source='paymentIntentId'
          render={record => record.paymentIntentId}
          label="resources.movements.fields.stripe"
        />
        <ReferenceField
          source="actor.id"
          reference="platform_users"
          label="resources.movements.fields.actor">
          <TextField source="completeName" />
        </ReferenceField>
        <FunctionField
         source='lessonReference'
         render={record => record.lessonReference ?? '-'}
         label="resources.movements.fields.lesson"
        />
        <NumberField source='lessonAmount' />
        <FunctionField
          source="lessonDatetime"
          sortBy="lessonDatetime"
          render={({ lessonDatetime }) => formatAndLocalizeDateTime(lessonDatetime)}
          label="resources.movements.fields.lessonDateTime"
        />
        <ReferenceField
          source="teacher.id"
          reference="platform_users"
          label="resources.movements.fields.teacher">
          <TextField source="completeName" />
        </ReferenceField>
        <ReferenceField
          source="student.id"
          reference="platform_users"
          label="resources.movements.fields.student">
          <TextField source="completeName" />
        </ReferenceField>
        <FunctionField
          source='purchasePrice'
          render={record => record.purchasePrice ?? '-'}
          label="resources.movements.fields.purchasePrice"
        />
        <FunctionField
          source='purchaseCampaignDiscount'
          render={formatCampaign}
          label="resources.movements.fields.purchaseCampaignDiscount"
        />
        <FunctionField
          source='teacherAdjustment'
          render={record => record.teacherAdjustment ?? '-'}
          label="resources.movements.fields.teacherAdjustment"
        />
        <FunctionField
          source='amountCollectedByTeacher'
          render={record => record.amountCollectedByTeacher ?? '-'}
          label="resources.movements.fields.amountCollectedByTeacher"
        />
        <FunctionField
          source='purchaseCommissionApplied'
          render={record => record.purchaseCommissionApplied ?? '-'}
          label="resources.movements.fields.purchaseCommissionApplied"
        />
        <FunctionField
          source='teacherServiceFee'
          render={record => record.teacherServiceFee ?? '-'}
          label="resources.movements.fields.teacherServiceFee"
        />
        <FunctionField
          source='studentAdjustment'
          render={record => record.studentAdjustment ?? '-'}
          label="resources.movements.fields.studentAdjustment"
        />
        <FunctionField
          source='generatedCredits'
          render={record => record.generatedCredits ?? '-'}
          label="resources.movements.fields.generatedCredits"
        />
        <FunctionField
          source='usedCredits'
          render={record => record.usedCredits ?? '-'}
          label="resources.movements.fields.usedCredits"
        />
        <FunctionField
          source='availableCredits'
          render={record => record.availableCredits ?? '-'}
          label="resources.movements.fields.availableCredits"
        />
        <FunctionField
          source='withdrawnBalance'
          render={record => record.withdrawnBalance ?? '-'}
          label="resources.movements.fields.withdrawnBalance"
        />
        <FunctionField
          source='totalBalance'
          render={record => record.totalBalance ?? '-'}
          label="resources.movements.fields.totalBalance"
        />
        <FunctionField
          source='retainedBalance'
          render={record => record.retainedBalance ?? '-'}
          label="resources.movements.fields.retainedBalance"
        />
      </Datagrid>
    </List>

  );
};

export default MovementList;
