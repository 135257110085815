import { styled } from '@mui/material/styles';
import { Datagrid, TextField, FunctionField, ArrayField, useTranslate, useRecordContext } from "react-admin";
import { useNavigate } from 'react-router-dom';
import { formatAndLocalizeDateTime } from 'utils/format';

const LessonIncidents = styled(() => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const onRowClick = (id) => {
    navigate(`/incidents/${id}/show`);
  };
  const label = translate('menu.incidents.header')
  const record = useRecordContext();
  return (
    <>
      {record.lessonIncidents != null && record.lessonIncidents.length > 0 &&
      <div className='lessonIncidents'>
        <span className='label' style={{ fontWeight: "bold" }}>{label}</span>
        <ArrayField
          source="lessonIncidents"
        >
          <Datagrid
            rowClick={onRowClick}
            bulkActionButtons={false}
            sx={{
              '& .RaDatagrid-headerCell': {
                width: '200px'
              },
              '& .RaDatagrid-rowCell': {
                width: '200px'
              }
            }}
          >
            <TextField label="Nº" source="number" />
            <FunctionField
              source="date"
              label="resources.incidents.fields.date"
              render={({ date }) => formatAndLocalizeDateTime(date)}
            />
            <TextField label="resources.incidents.fields.reporter" source="reporter" />
            <TextField label="resources.incidents.fields.reason" source="type.label" />
            <FunctionField
              label="resources.incidents.fields.status.name"
              sortBy="resolved"
              render={({ resolved }) =>
                translate(
                  resolved
                    ? 'resources.incidents.fields.status.resolved'
                    : 'resources.incidents.fields.status.unresolved'
                  )
            }
            />
          </Datagrid>
        </ArrayField>
      </div>}
    </>
  );
})`
  font-weight: bold;
  text-align: right;
`;

export default LessonIncidents;