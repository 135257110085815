/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { TextInput, useDataProvider, useRecordContext } from 'react-admin';
import { useForm } from 'react-hook-form';
import FormCard from '../FormCard';
import { useGetTeacherPresentation, useTranslation } from 'hooks';

const i18nPath = 'resources.platform_users.tabs.teacherData.presentation';

const Presentation = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherPresentation(record?.teacherId);

  const methods = useForm({
    defaultValues: {
      presentation: '',
    },
  });

  useEffect(() => {
    const { reset } = methods;
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updateTeacherPresentation(record.teacherId, data);
  };

  return (
    <FormCard
      title={useTranslation(i18nPath, 'title')}
      methods={methods}
      onSubmit={handleSubmit}
    >
      <TextInput label={false} source='presentation' fullWidth multiline />
    </FormCard>
  );
};

export default Presentation;
