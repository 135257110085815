import { Create } from 'react-admin';
import IncidentResponseTypeForm from './IncidentResponseTypeForm';
import useDuplicateNotify from '../../hooks/useDuplicateNotify';

const IncidentResponseTypeCreate = () => {
  const notify = useDuplicateNotify();
  return (
    <Create mutationOptions={{ onError: notify }}>
      <IncidentResponseTypeForm />
    </Create>
  );
};

export default IncidentResponseTypeCreate;
