import { ReferenceInput, SelectInput, DateInput } from 'react-admin';

const asc = (field = 'id') => ({ field, order: 'ASC' });

export const numberFilter = (
  <ReferenceInput source="hash" reference="incidents" sort={asc()} alwaysOn>
    <SelectInput label="Nº" source="number" optionText="number" />
  </ReferenceInput>
);

export const lessonFilter = (
  <ReferenceInput
    source="lessonId"
    reference="incidents/lessons"
    sort={asc()}
    alwaysOn
  >
    <SelectInput label="resources.incidents.fields.lesson" optionText="reference" />
  </ReferenceInput>
);

export const dateFilter = <DateInput source="date" alwaysOn />;

export const teacherFilter = (
  <ReferenceInput
    source="teacher"
    reference="incidents/teachers"
    sort={asc('firstName')}
    alwaysOn
  >
    <SelectInput
      label="resources.incidents.fields.teacher"
      optionText="completeName"
    />
  </ReferenceInput>
);

export const studentFilter = (
  <ReferenceInput
    source="student"
    reference="incidents/students"
    sort={asc('firstName')}
    alwaysOn
  >
    <SelectInput
      label="resources.incidents.fields.student"
      optionText="completeName"
    />
  </ReferenceInput>
);

export const statusFilter = (
  <SelectInput
    source="status"
    choices={[
      { id: true, name: 'resources.incidents.fields.status.resolved' },
      {
        id: false,
        name: 'resources.incidents.fields.status.unresolved',
      },
    ]}
    alwaysOn
  />
);

const filters = [
  numberFilter,
  lessonFilter,
  dateFilter,
  teacherFilter,
  studentFilter,
  statusFilter,
];

export default filters;
