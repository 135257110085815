import { List, Datagrid, DeleteButton, FunctionField } from 'react-admin';
import { Typography } from "@mui/material";
import { TrashIcon } from "../../icons/CustomIcons";
import { AVAILABLE_LANGS, DEFAULT_LANG } from 'core/constants';
import { i18nProvider } from 'providers/i18nProvider';

const IncidentTypeList = () => {
  const currentLocale = i18nProvider.getLocale();
  const getDefaultNameLocalization = (nameLocalizations) =>
  AVAILABLE_LANGS.includes(currentLocale) ? nameLocalizations[currentLocale] : nameLocalizations[DEFAULT_LANG];

  return (
    <List>
      <Datagrid rowClick="edit">
        <FunctionField
          source="nameLocalizations"
          label="resources.incident_types.list.name"
          render={({ nameLocalizations }) => getDefaultNameLocalization(nameLocalizations)}
        />
        <Typography align='right'>
          <DeleteButton icon={<TrashIcon/>}/>
        </Typography>
      </Datagrid>
    </List>
  );
};

export default IncidentTypeList;
