import { Create } from 'react-admin';
import ProductForm from './ProductForm';

const ProductCreate = () => (
  <Create>
    <ProductForm />
  </Create>
);

export default ProductCreate;
