import { useTranslate } from 'react-admin';
import { Stack, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LongDescription from './details/LongDescription';

const InlineTypography = styled(Typography)`
  display: inline;
`;

const LanguageDetails = ({ language }) => {
  const translate = useTranslate();

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <div>
          <InlineTypography sx={{ fontWeight: 700 }}>
            {translate('resources.offered_languages.fields.description')}:{' '}
          </InlineTypography>
          <InlineTypography>{language.shortDescription}</InlineTypography>
        </div>
        <Divider />
        <LongDescription language={language} />
      </Stack>
      <Stack spacing={2}>
        <div>
          <InlineTypography sx={{ fontWeight: 700 }}>
            {translate('resources.offered_languages.fields.activeTeachers')}:{' '}
          </InlineTypography>
          <InlineTypography>{language.activeTeacherCount}</InlineTypography>
        </div>
        <Divider />
        <div>
          <InlineTypography sx={{ fontWeight: 700 }}>
            {translate('resources.offered_languages.fields.activeStudents')}:{' '}
          </InlineTypography>
          <InlineTypography>{language.activeStudentCount}</InlineTypography>
        </div>
        <Divider />
      </Stack>
    </Stack>
  );
};

export default LanguageDetails;
