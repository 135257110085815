import { useState } from 'react';
import { Grid } from '@mui/material';
import {
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceArrayInput,
  NumberInput,
  SaveButton,
  Form,
  TextInput,
  AutocompleteInput,
  DateInput,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  useRedirect,
  useNotify,
  useRecordContext
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { NonBlankString, NonNegative, NonEmptyArray, RaImage } from 'schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { currentDateString } from 'utils/date';
import { useDuplicateNotify } from 'hooks';

const SpokenLanguageSchema = z.object({
  languageId: NonBlankString,
  languageLevelId: NonBlankString,
  document: z.string(),
});

const TeacherApplicationSchema = z.object({
  firstName: NonBlankString,
  lastName: NonBlankString,
  phoneNumber: z.string(),
  countryId: NonBlankString,
  identityDocument: NonBlankString,
  birthDate: z.preprocess(str => new Date(str), z.date().max(new Date())),
  accentId: z.string(),
  teacherPicture: RaImage,
  teacherTrainingDescription: NonBlankString,
  teacherCharacteristicIds: NonEmptyArray(NonBlankString),
  nativeLanguageId: NonBlankString,
  spokenLanguages: z.array(SpokenLanguageSchema),
  teachingYears: NonNegative,
  weeklyTimeHours: NonNegative,
  pricePerHour: NonNegative,
  presentation: NonBlankString,
  introductoryVideoId: NonBlankString,
});

const Accent = () => {
  const { watch, resetField } = useFormContext();
  const nativeLanguageId = watch('nativeLanguageId');

  const [previousLanguageId, setPreviousLanguageId] = useState(null);
  if (nativeLanguageId !== previousLanguageId) {
    setPreviousLanguageId(nativeLanguageId);
    resetField('accentId');
  }

  return (
    <ReferenceInput
      source='accentId'
      reference='accents'
      filter={{ languageId: nativeLanguageId }}
      fullWidth
    >
      <AutocompleteInput />
    </ReferenceInput>
  );
};

const TeacherApplicationForm = ({ notification, saveFn }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const notifyDuplicated = useDuplicateNotify();
  
  const handleFormSubmit = async (data) => {

    const id = record.id;
    const payload = {
      id,
      data
    };
    
    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('/teacher_applications');
        notify(notification, {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
      },
      onError: notifyDuplicated,
    };

    saveFn('teacher_applications', payload, options);
  };

  return (
    <Form resolver={zodResolver(TeacherApplicationSchema)} onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextInput source='firstName' fullWidth />
        </Grid>
        <Grid item xs={5}>
          <TextInput source='lastName' fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextInput source='email' disabled fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source='phoneNumber' fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReferenceInput source='countryId' reference='countries' fullWidth>
            <AutocompleteInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput source='identityDocument' fullWidth />
        </Grid>
        <Grid item xs={4}>
          <DateInput
            source='birthDate'
            inputProps={{ max: currentDateString() }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextInput source='teacherTrainingDescription' multiline fullWidth />
        </Grid>
        <Grid item xs={4}>
          <ReferenceArrayInput
            source='teacherCharacteristicIds'
            reference='teacher_characteristics'
            fullWidth
          >
            <AutocompleteArrayInput />
          </ReferenceArrayInput>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <ReferenceInput source='nativeLanguageId' reference='languages' fullWidth>
            <AutocompleteInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <Accent />
        </Grid>
      </Grid>

      <ArrayInput source='spokenLanguages'>
        <SimpleFormIterator>
          <ReferenceInput source='languageId' reference='languages' fullWidth>
            <AutocompleteInput />
          </ReferenceInput>
          <ReferenceInput
            source='languageLevelId'
            reference='language_levels'
            fullWidth
          >
            <AutocompleteInput optionText='level' />
          </ReferenceInput>
          <TextInput source='document' />
        </SimpleFormIterator>
      </ArrayInput>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <NumberInput source='teachingYears' fullWidth />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source='weeklyTimeHours' fullWidth />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source='pricePerHour' fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextInput source='presentation' multiline fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source='introductoryVideoId' fullWidth />
        </Grid>
      </Grid>

      <ImageInput source='teacherPicture'>
        <ImageField source='src' />
      </ImageInput>
      <SaveButton />
    </Form>
  );
};

export default TeacherApplicationForm;
