import { Grid } from '@mui/material';
import { AVAILABLE_LANGS, DEFAULT_LANG } from 'core/constants';
import {
  maxValue,
  minValue,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  TranslatableInputs
} from 'react-admin';
import { int } from 'utils/validators';
import LessonType from './form/LessonType';
import ProductPrice from './form/ProductPrice';
import StyledNumberInput from './form/StyledNumberInput';

const ProductForm = () => {
  return (
    <SimpleForm>
      <Grid container>
        <TranslatableInputs locales={AVAILABLE_LANGS} defaultLocale={DEFAULT_LANG}>
          <TextInput source="nameLocalizations" validate={[required()]} fullWidth />
        </TranslatableInputs>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <NumberInput
              source="lessons"
              validate={[required(), minValue(1), int()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              source="duration"
              validate={[required(), minValue(10), int()]}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <LessonType />
          <Grid item xs={6}>
            <StyledNumberInput
              source="discount"
              validate={[required(), minValue(0), maxValue(100), int()]}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <ProductPrice />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <StyledNumberInput
              source="commission"
              validate={[required(), minValue(0), maxValue(100), int()]}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledNumberInput
              source="credits"
              validate={[required(), minValue(0), int()]}
            />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default ProductForm;
