import { useState } from 'react';
import {
  Show,
  Button,
  TopToolbar,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ResolveModal from './show/ResolveModal';
import Incident from './show/Incident';
import Reason from './show/Reason';
import Reporter from './show/Reporter';
import SentText from './show/SentText';
import GivenSolution from './show/GivenSolution';

const ShowTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.incidents.name', { smart_count: 1 });
  if (!record) {
    return `${label}:`;
  }
  return `${label}: ${record.number}`;
};

const PostShowActions = ({ openModal }) => {
  const translate = useTranslate();
  return (
    <TopToolbar>
      <Button color="primary" onClick={openModal}>
        <Typography>
          {translate('resources.incidents.buttons.resolve')}
        </Typography>
      </Button>
    </TopToolbar>
  );
};

const IncidentShow = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Show
      title={<ShowTitle />}
      actions={<PostShowActions openModal={() => setOpenModal(true)} />}
    >
      <ResolveModal open={openModal} onClose={() => setOpenModal(false)} />
      <Grid container spacing={10}>
        <Incident />
        <Grid container item xs={8} rowSpacing={6}>
          <Reason />
          <Reporter />
          <SentText />
          <GivenSolution />
        </Grid>
      </Grid>
    </Show>
  );
};

export default IncidentShow;
