import { AVAILABLE_LANGS, DEFAULT_LANG } from 'core/constants';
import {
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
  TranslatableInputs,
} from 'react-admin';

const IncidentTypeForm = () => {
  const translate = useTranslate();

  return (
    <SimpleForm>
      <TranslatableInputs locales={AVAILABLE_LANGS} defaultLocale={DEFAULT_LANG}>
          <TextInput source="nameLocalizations" validate={[required()]} fullWidth />
      </TranslatableInputs>
      <ReferenceArrayInput
        source="incidentResponseTypes"
        reference="incident_response_types"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ deleted: false }}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <p>
        {translate('resources.incident_types.redirect.0')}
        <a href="/#/incident_response_types/create">
          {translate('resources.incident_types.redirect.1')}
        </a>
        {translate('resources.incident_types.redirect.2')}
      </p>
    </SimpleForm>
  );
};

export default IncidentTypeForm;
