import { useState } from 'react';
import { useTranslate } from 'react-admin';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import DefaultIcon from '@mui/icons-material/ViewList';

const SubMenu = ({ icon = <DefaultIcon />, name, children }) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(true);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <List>
      <ListItemButton sx={{ m: 0, pl: 2 }} onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: '41px' }}>{icon}</ListItemIcon>
        <ListItemText primary={translate(name)} />
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List componente="div">{children}</List>
      </Collapse>
    </List>
  );
};

export default SubMenu;
