import {
  Datagrid,
  DeleteButton,
  EmailField,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import {Typography} from "@mui/material";
import {TrashIcon} from "../../icons/CustomIcons";

const userFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const UserList = () => (
  <List filters={userFilters}>
    <Datagrid rowClick="edit">
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <Typography align='right'>
        <DeleteButton icon={<TrashIcon/>}/>
      </Typography>
    </Datagrid>
  </List>
);
