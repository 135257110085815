import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const Heading = styled(({ children, ...props }) => (
  <Grid item xs={12} {...props}>
    {children}
  </Grid>
))`
  font-weight: bold;
`;

export default Heading;
