import { Menu } from 'react-admin';
import { ProductIcon, UsersIcon } from '../../icons/CustomIcons';
import SubMenu from './menu/SubMenu';
import Link from './menu/Link';

const isProduction = process.env.REACT_APP_ENV === 'prod';

const MyMenu = ({ onMenuClick, ...props }) => {
  return (
    <Menu {...props}>
      {!isProduction && (
        <SubMenu name='menu.parameters.header'>
          <Link subItem text='menu.parameters.class' to='/class_parameters' />
          <Link subItem text='menu.parameters.account' to='/account_parameters' />
          <Link subItem text='menu.parameters.credit' to='/credit_parameters' />
          <Link subItem text='menu.parameters.cashing' to='/cashing_parameters' />
        </SubMenu>
      )}
      <Link text='menu.products' to='/products' icon={<ProductIcon />} />
      <Link text='menu.campaigns' to='/campaigns' />
      <Link text='menu.accounts' to='/platform_users' />
      <Link text="menu.teacher_applications" to="/teacher_applications" />
      <Link text="menu.teacher_balance_summary" to="/teacher_balance_summary" />
      <Link text="menu.movements" to="/movements" />
      {!isProduction && (
        <Link
          subItem
          text='menu.languages.offeredLanguagesAndComplementaries'
          to='/offered_languages'
          icon={<></>} // TODO add language icon
        />
      )}
      {!isProduction && (
        <SubMenu name='menu.accounting.header'>
          <Link subItem text='menu.accounting.sectors' to='/sectors' />
          <Link
            subItem
            text='menu.accounting.accounts'
            to='/accounting_accounts'
          />
          <Link subItem text='menu.accounting.clientTypes' to='/client_types' />
        </SubMenu>
      )}
      <SubMenu name='menu.incidents.header'>
        <Link
          subItem
          text='menu.incidents.incidentsAndCompensations'
          to='/incidents'
        />
        <Link
          subItem
          text='menu.incidents.incidentTypes'
          to='/incident_types'
        />
        <Link
          subItem
          text='menu.incidents.incidentResponseTypes'
          to='/incident_response_types'
        />
      </SubMenu>
      <SubMenu name='menu.usersAndRoles.header' icon={<UsersIcon />}>
        <Link subItem text='menu.usersAndRoles.users' to='/users' />
        <Link subItem text='menu.usersAndRoles.roles' to='/roles' />
      </SubMenu>
    </Menu>
  );
};

export default MyMenu;
