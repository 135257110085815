export const getLessonId = (user) => (lesson) => lesson[user].id;

export const mapLesson = (user) => (lesson) => {
  const { createdAt, reference, status, startDate: date } = lesson;
  return {
    createdAt,
    reference,
    status,
    date,
    user: getLessonId(user)(lesson),
  };
};

export const replaceUserIdWithUserName = (users) => (lesson) => {
  const user = users.find((u) => u.id === lesson.user);
  return {
    ...lesson,
    user: user.completeName,
  };
};
