import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherLessonsIntro = (id) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherLessonsIntro', id], () => {
    if (id)
      return dataProvider.getTeacherLessonsIntro(id).then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherLessonsIntro;
