import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherLessonInfo = (teacherId) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherLessonInfo', teacherId], () => {
    if (teacherId)
      return dataProvider
        .getTeacherLessonInfo(teacherId)
        .then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherLessonInfo;
