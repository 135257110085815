import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetUserById = (userId) => {
  const dataProvider = useDataProvider();
  const query = useQuery(
    ['getUserById', userId],
    () => userId && dataProvider.getUserById(userId).then(({ data }) => data)
  );

  return query;
};

export default useGetUserById;
