import { Edit, useRecordContext, useTranslate } from 'react-admin';
import { useDuplicateNotify } from 'hooks';
import LanguageExamForm from './LanguageExamForm';

const LanguageExamTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.language_exams.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const LanguageExamEdit = () => {
  const notify = useDuplicateNotify();

  return (
      <Edit title={<LanguageExamTitle />} mutationOptions={{ onError: notify }}>
      <LanguageExamForm />
      </Edit>
  );
};

export default LanguageExamEdit;