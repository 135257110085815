import jsonServerProvider from 'ra-data-json-server';
import {
  languageDataProvider,
  languageLevelDataProvider,
  parameterDataProvider,
  platformUserDataProvider,
  platformUserTeacherApplicationDataProvider,
  teacherApplicationDataProvider,
  teacherDataProvider,
  teacherSpokenLanguagesDataProvider,
  userDataProvider,
  teacherBalanceSummaryDataProvider,
} from './dataProviders';
import httpClient from './httpClient';

const apiUrl = process.env.REACT_APP_API_URL;

const jsonDataProvider = jsonServerProvider(apiUrl, httpClient);

const dataProvider = {
  ...jsonDataProvider,

  ...platformUserDataProvider,

  ...teacherDataProvider,

  ...userDataProvider,

  ...parameterDataProvider,

  ...platformUserTeacherApplicationDataProvider,

  ...teacherApplicationDataProvider,

  ...languageDataProvider,

  ...languageLevelDataProvider,

  ...teacherSpokenLanguagesDataProvider,

  ...teacherBalanceSummaryDataProvider,

  update: (resource, params) => {
    if (resource !== 'teacher_applications' || !Object.hasOwn(params.data, 'teacherPicture')) {
      return jsonDataProvider.update(resource, params);
    }

    const { teacherPicture, ...data } = params.data
    const formData = new FormData();
    formData.append('teacherPicture', teacherPicture.rawFile)
    formData.append('teacherApplication', new Blob([JSON.stringify(data)], { type: 'application/json' }))

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({ data: json }));
  }
};

export default dataProvider;
