import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { TableCell } from '@mui/material';
import { partition } from 'utils/list';
import * as Range from 'utils/range';

const AvailableCell = ({ week, day, hour }) => {
  const { watch, setValue } = useFormContext();
  const availability = watch(`availability.${week}.${day}`) ?? [];
  const registered = availability.some((range) => Range.between(range, hour));
  
  const setAvailability = (value) =>
    setValue(`availability.${week}.${day}`, value);

  const extendConsecutiveRanges = useCallback(
    (consecutiveRanges) => {
      switch (consecutiveRanges.length) {
        case 0:
          return { start: hour, end: hour + 1 };
        case 1:
          return Range.link(consecutiveRanges[0], {
            start: hour,
            end: hour + 1,
          });
        case 2:
          return Range.link.apply(null, consecutiveRanges);
        default:
          throw new Error(
            'Found more than 2 consecutive ranges, probably because of duplicated ranges.'
          );
      }
    },
    [hour]
  );

  const register = () => {
    const [consecutiveRanges, inconsecutiveRanges] = partition(
      availability,
      (range) => Range.consecutiveTo(range, hour)
    );

    setAvailability([
      ...inconsecutiveRanges,
      extendConsecutiveRanges(consecutiveRanges),
    ]);
  };

  const unregister = () => {
    const range = availability.find((range) => Range.between(range, hour));
    if (!range)
      throw new Error('"unregister" called on a non-registered cell.');

    setAvailability([
      ...availability.filter((range) => !Range.between(range, hour)),
      ...Range.remove(range, hour),
    ]);
  };

  return (
    <TableCell
      key={`${week}_${day}_${hour}`}
      sx={{
        backgroundColor: registered ? 'black' : 'white',
        borderLeft: '1px solid #e8e8e8',
        cursor: 'pointer',
        '&:hover': { backgroundColor: registered ? '#666' : '#e8e8e8' },
      }}
      onClick={registered ? unregister : register}
    />
  );
};

export default AvailableCell;
