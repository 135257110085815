import {
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import levels from './levels';

const LanguageLevelForm = () => (
  <SimpleForm>
    <SelectInput
      disabled={true}
      source='level'
      choices={levels}
      sx={{ width: '60%' }}
      validate={[required()]}
    />
    <TextInput source='description' multiline sx={{ width: '60%' }} />
  </SimpleForm>
);

export default LanguageLevelForm;
