import { useNotify } from 'react-admin';
import { useLoading } from 'hooks';
import Form from './Form';
import Card from './Card';
import SaveButton from './SaveButton';

const FormCard = ({ title, methods, onSubmit, children, ...props }) => {
  const [loading, withLoad] = useLoading();

  const notify = useNotify();
  const handleSubmit = methods.handleSubmit(async (data) => {
    await withLoad(async () => {
      await onSubmit(data);
    });

    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
  });

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <Card title={title} icon={<SaveButton loading={loading} />} {...props}>
        {children}
      </Card>
    </Form>
  );
};

export default FormCard;
