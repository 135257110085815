import * as utils from './utils';

const getOne = utils.getOne('platform_users');
const putOne = utils.putOne('platform_users');

const provider = {
  getPlatformUsersById: (ids) => {
    const params = ids.map((id) => `id=${id}`).join('&');
    return utils.get(`platform_users?${params}`);
  },

  getPlatformUserGeneralData: getOne('general_data'),

  updatePlatformUserGeneralData: putOne('general_data'),

  getPlatformUserStatus: getOne('status'),

  updatePlatformUserStatus: putOne('status'),
};

export default provider;
