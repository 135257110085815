import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

const useGetTeacherVideo = (id) => {
  const dataProvider = useDataProvider();
  const query = useQuery(['getTeacherVideo', id], () => {
    if (id) return dataProvider.getTeacherVideo(id).then(({ data }) => data);
  });

  return query;
};

export default useGetTeacherVideo;
