import { Edit, useRecordContext, useTranslate, useUpdate } from 'react-admin';
import CompensationForm from './CompensationForm';
import { COMPENSATION, PENALIZATION } from './constants';

const CompesationTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.compensations.name', { smart_count: 1 });

  return `${label}: ${record.number}`;
};

const FormWrapper = () => {
  const [update] = useUpdate();
  const { user, role, quantity, penalization, text } = useRecordContext();

  return (
    <CompensationForm
      defaultValues={{
        user,
        role,
        quantity,
        type: penalization ? PENALIZATION : COMPENSATION,
        text,
      }}
      notification={'ra.notification.updated'}
      saveFn={update}
    />
  );
};

const CompensationEdit = () => {
  return (
    <Edit title={<CompesationTitle />}>
      <FormWrapper />
    </Edit>
  );
};

export default CompensationEdit;
