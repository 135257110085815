/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  BooleanInput,
  NumberInput,
  SelectArrayInput,
  useDataProvider,
  useRecordContext,
  minValue,
  required,
} from 'react-admin';
import FormCard from '../FormCard';
import Field from '../Field';
import {
  useGetTeacherLessonInfo,
  useGetTeacherNativeLanguages,
  useTranslation,
} from 'hooks';
import { useForm } from 'react-hook-form';
import { int } from 'utils/validators';

const i18nPath = 'resources.platform_users.tabs.teacherData.lessons';

const MinAdvanceNotice = () => (
  <Field name={useTranslation(i18nPath, 'minAdvanceNotice')}>
    <NumberInput
      label={false}
      source='minAdvanceNotice'
      validate={(minValue(0), int())}
    />
  </Field>
);

const AllowTrialLessons = () => (
  <Field name={useTranslation(i18nPath, 'allowTrialLessons')}>
    <BooleanInput label={false} source='allowTrialLessons' />
  </Field>
);

const Languages = ({ teacherId }) => {
  const { data: choices } = useGetTeacherNativeLanguages(teacherId);

  useEffect(() => { }, []);

  return (
    <Field name={useTranslation(i18nPath, 'languages')}>
      <SelectArrayInput
        label={false}
        source='languages'
        choices={choices ?? []}
        optionText='name'
        optionValue='id'
        validate={[required()]}
      />
    </Field>
  );
};

const Lessons = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetTeacherLessonInfo(record?.teacherId);

  const methods = useForm({
    defaultValues: {
      minAdvanceNotice: 0,
      allowTrialLessons: false,
      languages: [],
    },
  });

  const { reset } = methods;

  useEffect(() => {
    reset(data);
  }, [data]);

  const handleSubmit = async (data) => {
    await dataProvider.updateTeacherLessonInfo(record.teacherId, data);
  };

  return (
    <FormCard
      methods={methods}
      title={useTranslation(i18nPath, 'title')}
      onSubmit={handleSubmit}
    >
      <MinAdvanceNotice />
      <AllowTrialLessons />
      <Languages teacherId={record?.teacherId} />
    </FormCard>
  );
};

export default Lessons;
