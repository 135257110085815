import { useNotify, useUpdate } from 'react-admin';

const useUndoableUpdate = () => {
  const [update, result] = useUpdate();
  const notify = useNotify();

  const undoableUpdate = (resource, params, options) => {
    update(resource, params, {
      ...options,
      undoable: true,
      onSuccess: (...params) => {
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });

        if (options?.onSuccess) options.onSuccess(...params);
      },
    });
  };

  return [undoableUpdate, result];
};

export default useUndoableUpdate;
