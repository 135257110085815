import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  DateInput,
  TextInput,
  SelectInput,
  ReferenceInput,
  useTranslate
} from 'react-admin';
import { formatAndLocalizeDateTime } from 'utils/format';

const i18nPath = 'resources.teacher_applications.fields';

const teacherApplicationFilters = [
  <TextInput source='teacherFullName' label={`${i18nPath}.firstName`} alwaysOn />,
  <ReferenceInput
    source='status'
    label={`${i18nPath}.status`}
    reference='teacher_applications/statuses'
    alwaysOn
  >
    <SelectInput optionText='label' />
  </ReferenceInput>,
  <DateInput source="createdAt" alwaysOn />
];

const TeacherApplicationList = () => {
  const translate = useTranslate();

  const formatTeacherApplicationStatus = (status) => {
    return translate(`resources.teacher_applications.status.${status.toLowerCase()}`)
  };

  return (
    <List filters={teacherApplicationFilters}>
      <Datagrid rowClick="edit">
        <TextField source="firstName" label={`${i18nPath}.firstName`} />
        <TextField source="lastName" label={`${i18nPath}.lastName`} />
        <ReferenceField source='nativeLanguageId' reference='languages' link={false} >
          <TextField source='name' />
        </ReferenceField>
        <FunctionField
          label={`${i18nPath}.createdAt`}
          source="createdAt"
          sortBy="createdAt"
          render={({ createdAt }) => formatAndLocalizeDateTime(createdAt)}
        />
        <FunctionField
          label={`${i18nPath}.updatedAt`}
          source="updatedAt"
          sortBy="updatedAt"
          render={({ updatedAt }) => formatAndLocalizeDateTime(updatedAt)}
        />
        <FunctionField
          source="status"
          label={`${i18nPath}.status`}
          render={({ status }) => formatTeacherApplicationStatus(status)}
        />
      </Datagrid>
    </List>
  );
}

export default TeacherApplicationList;
