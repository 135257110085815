import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useTranslate
} from 'react-admin';
import { formatAndLocalizeDateAndTime } from 'utils/format';
import filters from './filters';

const IncidentList = () => {
  const translate = useTranslate();

  return (
    <List filters={filters}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="Nº" sortBy="id" source="number" />
        <TextField
          label="resources.incidents.fields.lesson"
          sortBy="lesson.id"
          source="lesson.reference"
        />
        <FunctionField
          source="date"
          sortBy="createdAt"
          render={({ date }) => formatAndLocalizeDateAndTime(date).date}
        />
        <TextField
          label="resources.incidents.fields.teacher"
          sortBy="lesson.teacher.completeName"
          source="lesson.teacher.fullName"
        />
        <TextField
          label="resources.incidents.fields.student"
          sortBy="lesson.student.completeName"
          source="lesson.student.fullName"
        />
        <FunctionField
          label="resources.incidents.fields.status.name"
          sortBy="resolved"
          render={({ resolved }) =>
            translate(
              resolved
                ? 'resources.incidents.fields.status.resolved'
                : 'resources.incidents.fields.status.unresolved'
            )
          }
        />
      </Datagrid>
    </List>
  );
};

export default IncidentList;
