import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionField, ReferenceField, TextField, useTranslate } from 'react-admin';
import { formatAndLocalizeDateAndTime, formatAndLocalizeDateTime } from 'utils/format';
import Detail from './Detail';

const LeftGridItem = styled((props) => (
  <Grid item xs={6} {...props}>
    {props.children}
  </Grid>
))`
  text-align: left;
  font-weight: bold;
`;

const RightGridItem = styled((props) => (
  <Grid item xs={6} {...props}>
    {props.children}
  </Grid>
))`
  text-align: right;
  font-weight: bold;
`;

const Row = ({ source, render, label, reference, referenceLabel }) => {
  const translate = useTranslate();
  const fieldComponent = reference
    ? (
      <ReferenceField source={source} reference={reference} link={false}>
        <TextField source={referenceLabel} />
      </ReferenceField>
    )
    : render
      ? <FunctionField render={render} />
      : <TextField source={source} />
    ;

  return (
    <Grid container item xs={12}>
      <LeftGridItem>
        {(label && label !== "") ? translate(label) : translate(`resources.incidents.fields.${source}`)}
      </LeftGridItem>
      <RightGridItem>{fieldComponent}</RightGridItem>
    </Grid>
  );
};

const Incident = () => {
  return (
    <Detail
      rows={[
        <Row key="incident" source="number" label="resources.incidents.fields.incident" />,
        <Row key="date" source="date" render={({ date }) => formatAndLocalizeDateTime(date) } />,
        <Row key="student" source="lesson.student.fullName" label="resources.incidents.fields.student" />,
        <Row key="teacher" source="lesson.teacher.fullName" label="resources.incidents.fields.teacher" />,
        <Row key="reference" source="lesson.reference" label="resources.incidents.fields.reference" />,
        <Row
          key="startDate"
          source="lesson.startDate"
          render={({ lesson: { startDate } }) => formatAndLocalizeDateAndTime(startDate).date}
          label="resources.incidents.fields.classDate"
        />,
        <Row
          key="startDateTime"
          source="lesson.startDate"
          render={({ lesson: { startDate } }) => formatAndLocalizeDateAndTime(startDate).time}
          label="resources.incidents.fields.classTime"
        />,
      ]}
      xs={4}
    />
  );
};

export default Incident;
