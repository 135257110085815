import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  useStore,
} from 'react-admin';
import { Grid } from '@mui/material';

const LanguageExamForm = () => {
  const [offeredLanguageHash] = useStore('language_exams.offered_language_hash');
  return (
    <SimpleForm>
      <Grid container xs={8}>
        <Grid item xs={6} padding={1}>
          <TextInput source='name' validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={6} padding={1}>
          <TextInput source='description' fullWidth />
        </Grid>
        <Grid item xs={6} padding={1}>
          <TextInput source='institution' fullWidth />
        </Grid>
        <Grid item xs={6} padding={1}>
          <TextInput source='abbreviation' fullWidth />
        </Grid>
        <Grid item xs={6} padding={1}>
          <TextInput source='tag' fullWidth />
        </Grid>
        <Grid item xs={6} padding={1}>
          <BooleanInput source='landing' fullWidth />
        </Grid>
        <TextInput type='hidden' source='offeredLanguageHash' defaultValue={offeredLanguageHash} label={false} />
      </Grid>
    </SimpleForm>
  );
};

export default LanguageExamForm;