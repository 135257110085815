import { Modal, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ResolveForm from './ResolveForm';

const ResolveModal = styled((props) => (
  <Modal {...props}>
    <Box>
      <ResolveForm closeModal={props.onClose} />
    </Box>
  </Modal>
))`
  .MuiBox-root {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    padding: 20px 80px;
  }
`;

export default ResolveModal;
