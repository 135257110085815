import useTranslation from 'hooks/useTranslation';
import Card from '../../Card';
import LessonCount from './LessonCount';
import PendingLessons from './PendingLessons';
import { usePendingLessons } from './hooks';

const i18nPath = 'resources.platform_users.tabs.generalData.pendingLessons.lessonsAsTeacher';

const TeacherPendingLessons = () => {
  const lessons = usePendingLessons('teacher', 'student');

  return (
    <Card
      title={useTranslation(i18nPath, 'title')}
      icon={<LessonCount lessons={lessons} />}
    >
      <PendingLessons column='student' lessons={lessons} />
    </Card>
  );
};

export default TeacherPendingLessons;
