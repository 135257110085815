import { get, post } from './utils';

const provider = {

  postPlatformUserTeacherApplication: (hash, body) => post(`platform_users/${hash}/teacher_application`, body),

  getPlatformUserTeacherApplication: (hash) => get(`platform_user_teacher_application/${hash}`),

  getTeacherApplications: (hash) => get(`platform_users/${hash}/teacher_applications`),

};

export default provider;
