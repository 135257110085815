import { useTranslate } from 'react-admin';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

const Quantity = ({ control }) => {
  const translate = useTranslate();
  const { field } = useController({ name: 'quantity', control });
  return (
    <TextField
      label={translate('resources.compensations.fields.quantity')}
      type="number"
      required
      InputProps={{ inputProps: { min: 0 } }}
      {...field}
    />
  );
};

export default Quantity;
