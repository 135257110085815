import { defaultTheme } from "react-admin";

const palette = {
  primary: {
    main: "#0000FF",
  },
};

export const theme = {
  ...defaultTheme,
  palette: palette,
  typography: {
    fontFamily: ["cabinet-grotesk", "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    fontSize: "1.6rem",
    h1: {
      fontSize: "3.6rem",
    },
    h2: {
      fontSize: "2.4rem",
    },
    body1: {
      fontSize: "1.6rem",
    },
    body2: {
      fontSize: "1.4rem",
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 50
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#CCC",
            color: "#666",
          },
        },
      },
    },
    /*MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "2rem !important",
        },
      },
    },*/
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          "& .RaSimpleShowLayout-row": {
            marginBottom: "0.8rem",
          },
          "& .RaSimpleShowLayout-row .RaLabeled-label": {
            fontSize: "1.6rem",
            marginBottom: "0",
          },
          "& .RaSimpleShowLayout-row span": {
            fontSize: "1.6rem",
          },
          "& .ra-field-teacherCharacteristicIds": {
            marginBottom: "1.2rem",
          },
          "& .ra-field-teacherCharacteristicIds > p > span": {
            marginBottom: "0.8rem",
            display: "block",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '@media screen and (max-width: 1800px)': {
            '& .RaDatagrid-tableWrapper': {
              // Uncomment in order to use this styles for all datagrid tables
              // maxWidth: 'calc(100vw - 260px)',
              //overflow: 'auto'
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-endAdornment button': {
            width: '3rem',
            padding: '0',
          }
        }
      }
    }
  },
};
