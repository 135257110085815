import {
  List,
  Datagrid,
  ChipField,
  TextField,
  FunctionField,
  ReferenceField,
  TextInput,
  SelectInput,
  ReferenceInput,
  SingleFieldList,
  ArrayField,
} from 'react-admin';

const i18nPath = 'resources.platform_users.fields';

const platformUserFilters = [
  <TextInput source='q' label='Search' alwaysOn />,
  <TextInput source='completeName' label={`${i18nPath}.completeName`} />,
  <TextInput source='email' label={`${i18nPath}.email`} />,
  <ReferenceInput
    source='type'
    label={`${i18nPath}.type`}
    reference='platform_privileges'
  >
    <SelectInput label={`${i18nPath}.type`} optionText='name' />
  </ReferenceInput>,
  <ReferenceInput
    source='status'
    label={`${i18nPath}.status`}
    reference='platform_users/statuses'
  >
    <SelectInput label={`${i18nPath}.status`} optionText='label' />
  </ReferenceInput>,
  <ReferenceInput
    source='nativeLanguageId'
    label={`${i18nPath}.nativeLanguage`}
    reference='languages'
  >
    <SelectInput label={`${i18nPath}.nativeLanguage`} optionText='name' />
  </ReferenceInput>,
];

const isTeacher = (user) =>
  user.privileges.find((privilege) => privilege.code === 'TEACHER');

const isStudent = (user) =>
  user.privileges.find((privilege) => privilege.code === 'STUDENT');

const PlatformUserList = () => (
  <List filters={platformUserFilters}>
    <Datagrid rowClick='edit'>
      <TextField source='completeName' />
      <ArrayField source='privileges'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ArrayField>
      <TextField label={`${i18nPath}.status`} source='status' />
      <FunctionField
        label={`${i18nPath}.studentPendingLessons`}
        render={(user) => (isStudent(user) ? user.studentLessons.length : '-')}
      />
      <FunctionField
        label={`${i18nPath}.teacherPendingLessons`}
        render={(user) => (isTeacher(user) ? user.teacherLessons.length : '-')}
      />
      <FunctionField
        label={`${i18nPath}.studentCount`}
        render={user => isTeacher(user) ? user.studentCount : '-'}
      />
      <ReferenceField
        label={`${i18nPath}.nativeLanguage`}
        source='nativeLanguageId'
        reference='languages'
        link={false}
      >
          <ChipField source='name' />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default PlatformUserList;
