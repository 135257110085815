import { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';

const i18nPath = 'resources.platform_users.tabs.teacherData.availability';

const Week = ({ week, next, prev }) => (
  <Stack direction='row' spacing={2}>
    <Typography variant='h6' onClick={prev} sx={{ cursor: 'pointer' }}>
      {'<'}
    </Typography>
    <Typography variant='h4'>
      {useTranslation(i18nPath, 'week')} {week + 1}
    </Typography>
    <Typography variant='h6' onClick={next} sx={{ cursor: 'pointer' }}>
      {'>'}
    </Typography>
  </Stack>
);

// Prevent from updating when form changes
const arePropsEqual = (prevProps, nextProps) =>
  prevProps.week === nextProps.week;

export default memo(Week, arePropsEqual);
