import { memo } from 'react';
import Lessons from './Lessons';
import Rates from './Rates';
import Languages from './Languages';
import Video from './Video';
import Availability from './Availability';
import Presentation from './Presentation';
import Curriculum from './Curriculum';
import AboutLessons from './AboutLessons';
import Characteristics from './Characteristics';
import TeacherStatus from './TeacherStatus';

const TeacherData = () => (
  <>
    <TeacherStatus />
    <Lessons />
    <Rates />
    <Languages />
    <Video />
    <Availability />
    <Presentation />
    <Curriculum />
    <AboutLessons />
    <Characteristics />
  </>
);

// Prevent update when active tab changes
const propsAreEqual = (prevProps, nextProps) => true;

export default memo(TeacherData, propsAreEqual);
